import { useQuery } from "graphql-hooks";
import { HOME_EVENT_QUERY } from "../../network/queries";
import { 
  SimpleGrid, 
  Title,
  createStyles
} from "@mantine/core";
import { useAuth } from "../../components/AuthProvider";
import Event from "../../components/Event";

const useStyles = createStyles((theme) => ({
  sectionTitle: {
    color: "#EB4B51"
  }
}));

export default function HomeEvents({showTitle}) {
  const { userId } = useAuth();
  const { classes } = useStyles();

  const { data, error } = useQuery(HOME_EVENT_QUERY, {variables: { userId: userId, past: false }});

  if (error) { return "ERROR LOADING EVENTS"; }

  const events = data?.events;

  const showEvents = Boolean(events && events.length);

  return (
    <>
      {showTitle && <Title className={classes.sectionTitle} mb={20}>Upcoming Events</Title>}
      <SimpleGrid cols="2">
        {showEvents && events.map(evt => <Event key={`Event-${evt.id}`} evt={evt} lp={false} />)}
        {!showEvents && (<Title order={3}>You have no upcoming events.</Title>)}
      </SimpleGrid>
    </>
  )
}
