import { useState } from "react";
import {
    Card,
    NumberInput,
    Title,
    Button,
    SimpleGrid,
    Text,
    Group,
    Badge
} from '@mantine/core';
import { useAuth } from '../../components/AuthProvider';
import { IconPercentage, IconCurrencyDollar } from "@tabler/icons";
import { useMutation } from 'graphql-hooks';
import { EVENT_WIZARD_MUTATION, PAYOUT_MUTATION } from '../../network/mutations';
import { success, fail } from '../../lib/notifications';
import { calcTotal, formatCurrency } from "../../lib/helpers";
import Payouts from "./Payouts";

export default function EventAdmin({event, eventTickets, shows, refetch}) {
  const [rate, setRate] = useState(event.rate);
  const [perTicket, setPerTicket] = useState(event.perTicket);
  const [tickets, sales, revenue, fees, txns, capacity, donations] = calcTotal(event, eventTickets, shows);
  const [amount, setAmount] = useState(0);
  const [updateEvent] = useMutation(EVENT_WIZARD_MUTATION);
  const [payout] = useMutation(PAYOUT_MUTATION);
  const { admin } = useAuth();
  const { owner } = event;
  const feeBreakdown = { ps: 0, cc: 0};

  const processFees = () => {
    const revFees = revenue * (rate / 100);
    const ticketFees = sales * perTicket;

    feeBreakdown.ps = revFees + ticketFees;
    feeBreakdown.cc = fees - feeBreakdown.ps;
  }

  processFees();

  const updateSetting = async (field, value) => {
    const map = {
      rate: 'total sales fee',
      per_ticket: 'per ticket fee',
      paid: 'payout'
    };

    if (admin) {
      const results = await updateEvent({variables: {id: event.id, field, value}});
      if (results.data.updateEvent) {
        event[field] = value;
        refetch();
        success('Event Updated', `Updated ${map[field]} successfully.`);
      } else {
        const { error } = results;
        const message = error?.graphQLErrors.length ? error.graphQLErrors[0].message : 'There was an error.';
        fail('Uh Oh!', `Unable to update event - ${message}`);
      }
    } else {
      fail('Nice try!', 'You are not an admin.');
    }
  };

  const handleUpdate = () => {
    //Dirty checking
    if (perTicket !== event.perTicket) {
      updateSetting('per_ticket', `${perTicket}`);
    }

    if (rate !== event.rate) {
      updateSetting('rate', `${rate}`);
    }
  };

  const markPaid = () => {
    if (!event.paid) {
      updateSetting('paid', "true");
    } else {
      fail('Merp', 'This event is already marked paid.');
    }
  }

  const makePayment = async() => {
    if (amount > 0) {
      const userId = event.owner.id;
      const eventId = event.id;

      const results = await payout({variables: { userId, eventId, amount }});

      if (results.data.userPayment.id) {
        success('Logged!', `Your payment of ${formatCurrency(amount)} has been logged.`);
      } else {
        fail('Merp', 'Unable to log payout.');
      }
    }
  }

  const balance = () => {
    const total = revenue + donations - fees - 1.5;
    const payouts = owner.payments.filter(payment => payment.event.id === event.id).reduce((t, p) => p.amount + t, 0);

    return total - payouts;
  };

  return (
    <>
      <SimpleGrid cols={2}>
        <Card mb={10} padding="lg" shadow="sm" withBorder>
          <Title order={2} mb={20}>Cost Breakdown</Title>
          <Group grow>
            <NumberInput
              label="Plainstage revenue fee"
              value={rate}
              onChange={setRate}
              placeholder="Enter percentage"
              min={0}
              max={100}
              precision={2}
              rightSection={<IconPercentage />}
            />
            <NumberInput
              label="Per Ticket Fee"
              value={perTicket}
              placeholder="Enter fee per ticket sold"
              min={0}
              precision={2}
              hideControls
              icon={<IconCurrencyDollar />}
              onChange={setPerTicket}
            />
          </Group>
          <Text mt={10}>Revenue: {formatCurrency(revenue)}</Text>
          <Text>Plainstage revenue fee: {formatCurrency(revenue * (rate / 100))}</Text>
          <Text>Ticket Sales: {sales}</Text>
          <Text>Plainstage per-ticket fee: {formatCurrency(sales * perTicket)}</Text>
          <Text>Credit Card Fees (paid by {event.payCC ? 'event owner' : 'ticket buyer'}): {formatCurrency(feeBreakdown.cc)}</Text>
          <Title order={3}>Total PS Fees: {formatCurrency(feeBreakdown.ps)}</Title>
          <Button color="orange" onClick={handleUpdate}>Update Fees</Button>
        </Card>
        <Card mb={10} padding="lg" shadow="sm" withBorder>
          <Title order={2} mb={20}>User Payouts</Title>
          <Text>To organizer: {formatCurrency(revenue + donations - fees - 1.5)}</Text>
          <Text>Balance owing: {formatCurrency(balance())}</Text>
          <Text>E-Transfer Address: {owner.etransfer || owner.email}</Text>
          {event.paid ? <Badge color="lime" size="lg" variant="filled">Paid</Badge> :
          <>
            <NumberInput
              label="Enter payout amount here"
              min={.01}
              precision={2}
              step={.05}
              value={amount}
              hideControls
              onChange={setAmount}
              placeholder="Enter amount here"
            />
            <Button.Group mt={10}>
              <Button onClick={makePayment}>Log Payout</Button>
              {!event.paid && <Button color="green" onClick={markPaid}>Mark Event Paid</Button>}
            </Button.Group>
          </>}
        </Card>
      </SimpleGrid>
      <Payouts payments={owner.payments.filter((payment) => payment.event.id === event.id)} />
    </>
  )
}