import { useState } from 'react';
import {
    createStyles,
    Paper,
    Title,
    Text,
    TextInput,
    Button,
    Container,
    Group,
    Anchor,
    Center,
    Box,
  } from '@mantine/core';
  import { Link } from 'react-router-dom';
  import { useMutation } from 'graphql-hooks';
  import { FORGOT_PW_MUTATION } from '../network/mutations';
  import { IconArrowLeft } from '@tabler/icons';
  
  const useStyles = createStyles((theme) => ({
    title: {
      fontSize: 26,
      fontWeight: 900,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    },
  
    controls: {
      [theme.fn.smallerThan('xs')]: {
        flexDirection: 'column-reverse',
      },
    },
  
    control: {
      [theme.fn.smallerThan('xs')]: {
        width: '100%',
        textAlign: 'center',
      },
    },
  }));
  
  export function ForgotPassword(params) {
    const { classes } = useStyles();
    const [ email, setEmail ] = useState('');
    const [ success, setSuccess ] = useState(false);
    const [ forgotPw ] = useMutation(FORGOT_PW_MUTATION);
  
    const onSubmit = (evt) => {
        evt.preventDefault();
        if (email === '' || !/@/.test(email)) {
            return false;
        }

        forgotPw({variables: { email }, onSuccess: (data) => {
            setSuccess(data.forgotPw); 
        }});
    }
    const successMsg = <Text>Please check your e-mail for a link to reset your password.</Text>;

    return (
      <Container size={460} my={30}>
        <Title className={classes.title} align="center">
          Forgot your password?
        </Title>
        <Text c="dimmed" fz="sm" ta="center">
          Enter your email to get a reset link
        </Text>
  
        <Paper withBorder shadow="md" p={30} radius="md" mt="xl">
          {success ? successMsg : (
          <>
            <TextInput label="Your email" placeholder="me@mantine.dev" value={email} onChange={evt => setEmail(evt.target.value)} required />
            <Group position="apart" mt="lg" className={classes.controls}>
              <Link to="/login" color="dimmed" size="sm" className={classes.control}>
                <Anchor color="dimmed" size="sm" className={classes.control}>
                  <Center inline>
                    <IconArrowLeft size={12} stroke={1.5} />
                    <Box ml={5}>Back to the login page</Box>
                  </Center>
                </Anchor>
              </Link>
              <Button onClick={onSubmit} className={classes.control}>Reset password</Button>
            </Group>
          </>)}
        </Paper>
      </Container>
    );
  }