import {
  Card,
  Title,
  Text,
  Image,
  SimpleGrid,
  Group,
  Button,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconMail } from "@tabler/icons-react";
import { useQuery } from "graphql-hooks";
import { SHOW_TICKET_QUERY } from "../../network/queries";
import { useParams, Navigate } from "react-router";
import { pickUri } from "../../lib/uris";
import SendTicketModal from "../../components/SendTicketModal";
import PSLoader from "@/components/PSLoader";

export default function ShowTickets() {
  const { id } = useParams();
  const [opened, {open, close}] = useDisclosure(false);
  const url = pickUri().replace('gql', 'qrcodes');
  const { loading, error, data } = useQuery(SHOW_TICKET_QUERY, {
    variables: { showId: id }
  });

  if (loading) return <PSLoader />;
  if (error) return <Navigate to="/app/tickets" />;

  const { showTickets: tickets, me, show } = data;
  const { event, venue } = show;
  show.startTime = show.startTime
    .split(" ")
    .slice(0, 7)
    .join(" ")
    .replace(" at ", " @ ");

  return (
    <>
      <Card withBorder shadow="sm" padding="lg">
        <Card.Section>
          <Image src={event.image} height={200} alt={event.title} />
        </Card.Section>
        <Card.Section inheritPadding>
          <SimpleGrid cols={2}>
            <div style={{marginTop: '0.625rem'}}>
              <Title order={2} mb={10}>{event.title}</Title>
              {show.subtitle && <Text>{show.subtitle}</Text>}
              <Text>{show.startTime}</Text>
            </div>
            <div style={{marginTop: '0.625rem'}}>
              <Title order={2} mb={10}>{venue?.name}</Title>
              <Text>{venue.street_address}</Text>
              {venue.secondary && <Text>{venue.secondary}</Text>}
              <Text>
                {venue.city}, {venue.stprv} {venue.postal}
              </Text>
            </div>
          </SimpleGrid>
        </Card.Section>
        <Card.Section inheritPadding>
          <Group>
            <Title order={2} my={20} mr={20}>Please find your tickets below.</Title>
            <Button color="orange" leftIcon={<IconMail />} onClick={open}>Send to my Email</Button>
          </Group>
          {tickets.map(ticket => (
            <Image
              key={`ticket${ticket.id}`}
              src={`${url}/${ticket.image}`}
              width={300}
            />
          ))}
        </Card.Section>
      </Card>
      <SendTicketModal opened={opened} e={me.email} close={close} id={me.id} showId={show.id} />
    </>
  );
}
