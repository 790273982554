import { useState } from "react";
import {
    SimpleGrid,
    Title,
    Text,
    Card,
    Stack,
    Group,
    Button,
    Switch,
    Tabs
} from '@mantine/core';
import { IconCirclePlus, IconStairs, IconTags } from '@tabler/icons-react';
import Tier from './Tier';
import PromoCode from './PromoCode';
import { useMutation } from "graphql-hooks";
import { DELETE_TIER_MUTATION, DELETE_PROMO_MUTATION } from '../../network/mutations';

export default function Pricing({ evt, tiers, shows, handler, refetch }) {
  const [payCC, setPayCC] = useState(evt.payCC);
  const [payPS, setPayPS] = useState(evt.payPS);
  const [donation, setDonation] = useState(evt.donation);
  const [activeTab, setActiveTab] = useState('tiers');
  const [tierList, setTierList] = useState(tiers);
  const [promoCodes, setPromoCodes] = useState(shows.flatMap(show => show.promo.map(code => { code.show_id = show.id; return code; })));
  const [deleteTier] = useMutation(DELETE_TIER_MUTATION);
  const [deleteCode] = useMutation(DELETE_PROMO_MUTATION);

  const addTier = () => {
    const newTier = {
      id: 0,
      name: '',
      price: 0,
      currency: 'CAD',
      byDonation: false,
      show: 0,
      max: 20
    };

    setTierList(tierList => [...tierList, newTier]);
  };

  const delTier = (id) => {
    if (id === 0) {
      const list = tierList.filter(tier => tier.id !== 0);
      setTierList(list);
    } else {
      deleteTier({variables: {id}, onSuccess: refetch});
    }
  };

  const addPromo = () => {
    const newPromo = {
      id: 0,
      name: '',
      price: 0
    };

    setPromoCodes(promoCodes => [...promoCodes, newPromo]);
  };

  const delCode = (id) => {
    if (id === 0) {
      const list = promoCodes.filter(promo => promo.id !== 0);
      setPromoCodes(list);
    } else {
      deleteCode({variables: {id}, onSuccess: refetch});
    }
  };

  return (
    <>
      <SimpleGrid cols={2}>
        <Card mb={20} withBorder shadow="sm" padding="lg">
          <Title order={2} mb={10}>Fee Settings</Title>
          <Stack>
            <Switch
              label="Pay credit card fees"
              description={payCC ? "You are paying credit card fees out of your total payout" : "Ticket purchasers will have credit card fees included in their cost."}
              checked={payCC}
              color="orange"
              onChange={(e) => { handler('pay_cc', e.currentTarget.checked.toString()); setPayCC(e.currentTarget.checked);}} 
            />
            <Switch
              label="Pay Plainstage fees"
              description={payPS ? "You are paying Plainstage fees out of your total payout" : "Ticket purchasers will have your Plainstage fees included in their cost."}
              checked={payPS}
              color="orange"
              onChange={(e) => { handler('pay_ps', e.currentTarget.checked.toString()); setPayPS(e.currentTarget.checked);}} 
            />
          </Stack>
        </Card>
        <Card mb={20} withBorder shadow="sm" padding="lg">
          <Title order={2} mb={10}>Accept Donations?</Title>
          <Switch
            label="Would you like to accept donations to your organization on the event checkout page?"
            description={`You are${donation ? ' ' : ' not '}accepting donations.`}
            checked={donation}
            color="orange"
            onChange={(e) => { handler('donation', e.currentTarget.checked.toString()); setDonation(e.currentTarget.checked);}}
          />  
        </Card>
      </SimpleGrid>
      <Tabs color="orange" defaultValue="dashboard" value={activeTab} onTabChange={setActiveTab}>
        <Tabs.List grow>
          <Tabs.Tab value="tiers" icon={<IconStairs size="0.8rem" />}>Ticket Tiers</Tabs.Tab>
          <Tabs.Tab value="promos" icon={<IconTags size="0.8rem" />}>Promo Codes</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="tiers" pt="xs">
          <Card mb={20} withBorder shadow="sm" padding="lg">
            <Group position="apart">
              <Title mb={20} order={3}>Configure Ticket Tiers</Title>
              <Button color="orange" leftIcon={<IconCirclePlus />} onClick={addTier}>Add Tier</Button>
            </Group>
            <Text mb={10}>You can have multiple tiers of pricing for your tickets.</Text>
            <SimpleGrid cols={3}>
              {tierList.map(tier => <Tier key={`tier${tier.id}`} tier={tier} shows={shows} eventId={evt.id} refetch={refetch} delTier={delTier} />)}
            </SimpleGrid>
          </Card> 
        </Tabs.Panel>
        <Tabs.Panel value="promos" pt="xs">
          <Card mb={20} withBorder shadow="sm" padding="lg">
            <Group position="apart">
              <Title mb={20} order={3}>Configure Promo Codes</Title>
              <Button color="orange" leftIcon={<IconCirclePlus />} onClick={addPromo}>Add Promo Code</Button>
            </Group>
            <Text mb={10}>Promo codes are a great promotional tool. Ticket buyers can use them on the event page when purchasing tickets.</Text>
            <SimpleGrid cols={3}>
              {(promoCodes.length === 0) &&  <Text>You have no promo codes. Perhaps you should add one?</Text>}
              {promoCodes.map(code => <PromoCode key={`promo${code.id}`} promo={code} shows={shows} eventId={evt.id} refetch={refetch} delCode={delCode} />)}
            </SimpleGrid>
          </Card> 
        </Tabs.Panel>
      </Tabs>
    </>
  )
}