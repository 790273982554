import { useState, useEffect } from 'react';
import {
  Group,
  Paper,
  Image,
  Title,
  Text,
  Button,
  ActionIcon,
  Center,
} from '@mantine/core';
import { useManualQuery } from 'graphql-hooks';
import { DONATION_MUTATION } from '@/network/mutations';
import PSLoader from '@/components/PSLoader';
import { IconCheck } from '@tabler/icons-react';

export default function DonationResults({stripe, evt, display, image}) {
  const [makeDonation] = useManualQuery(DONATION_MUTATION);
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(true);
  const ls = window.localStorage;

  useEffect(() => {
    const name = ls.getItem('name');
    const email = ls.getItem('email');
    const donation = +ls.getItem('donation');

    const successHandler = ({data}) => {
      //Successfully made ticket, clear LS so we don't duplicate if user refreshes
      ['name', 'email', 'tickets', 'promo', 'donation'].forEach(field => ls.removeItem(field));

      setEmail(email);
      setLoading(false);
      display({final: true});
    };

    if (name && email && donation) {
      makeDonation({
        variables: { name, email, donation, stripe: stripe?.id, eventId: evt.id}, 
        onSuccess: successHandler
      });
    } else {
      setLoading(false);
    }
  }, [makeDonation, ls, stripe, display]);

  if (loading) return <PSLoader />;

  return (
    <Paper radius={0} p={30}>
      <Group grow>
        <Group>
          <ActionIcon radius="xl" variant="filled" color="green" size="lg">
            <IconCheck size="1.25rem"/>
          </ActionIcon>
          <Title order={2} my={10}>Success, payment received!</Title>
        </Group>
        {image && <Image src={image} alt={evt.title} />}
      </Group>
      <Title order={3} mb={2}>You've donated to</Title>
      <Title order={1} color="#FF5a60" mb={20}>{evt.title}</Title>
      <Title order={3} mb={2}>Confirmation sent to:</Title>
      <Text mb={20}>{email}</Text>
      <Text color="dimmed" mt={20} mb={10}>For questions regarding your contribution, please contact the event organizer or support@plainstage.com.</Text>
      <Center>
        <Button size="xl" color="#FF5a60" onClick={() => window.location.reload()}>
          Back to Event
        </Button>
      </Center>
    </Paper>
  );
}
