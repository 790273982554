import {
    createStyles, 
    Container,
    Image,
    Card,
    Text,
    Title,
    Grid,
} from '@mantine/core';
import { pickUrl } from '../../lib/uris';

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingBottom: theme.spacing.xl * 2,
    zIndex: 1,
    position: 'relative',

    [theme.fn.smallerThan('sm')]: {
      paddingBottom: theme.spacing.xl * 3,
    },
  },
}));

export default function WhyPS() {
  const { classes } = useStyles();
  const url = pickUrl();
    
  return (
      <Container className={classes.container} my={30}>
          <Grid grow>
            <Grid.Col span={12}>
                <Title>Why Plainstage?</Title>
            </Grid.Col>
            <Grid.Col span={12} md={6} lg={4}>
                <Card padding="lg" style={{height: '100%'}}>
                    <Card.Section>
                        <Image
                        style={{width: '55%', margin: '0 auto'}}
                        src={`${url}/img/why1_v2.svg`}
                        alt="Low fees"
                        />
                    </Card.Section>

                    <Text mt={20} weight={700} fz="sm" align="center">Absurdly low fees.</Text>
                    <Text align="center" fz="sm">We don't have offices so you can keep more money.</Text>
                </Card>
            </Grid.Col>
            <Grid.Col span={12} md={6} lg={4}>
                <Card padding="lg" style={{height: '100%'}}>
                    <Card.Section>
                        <Image
                        style={{width: '55%', margin: '0 auto'}}
                        src={`${url}/img/why3_v2.svg`}
                        alt="Actually useful options"
                        />
                    </Card.Section>

                    <Text mt={20} weight={700} fz="sm" align="center">Options you actually need</Text>
                    <Text align="center" fz="sm">Flexible ticketing tiers created with the emerging producer in mind.</Text>
                </Card>
            </Grid.Col>
            <Grid.Col span={12} md={6} lg={4}>
                <Card padding="lg" style={{height: '100%'}}>
                    <Card.Section>
                        <Image
                        style={{width: '55%', margin: '0 auto'}}
                        src={`${url}/img/why2_v2.svg`}
                        alt="Human support"
                        />
                    </Card.Section>
                    <Text mt={20} weight={700} fz="sm" align="center">Human Support</Text>
                    <Text align="center" fz="sm">We're humans who live on coffee. We're one email away from helping you.</Text>
                </Card>
            </Grid.Col>
          </Grid>
      </Container>
  )
}
