import { 
  createStyles, 
  Container, 
  Title, 
  Text, 
  Button 
} from "@mantine/core";
import { Link } from "react-router-dom";
import {
  IconCalendarEvent,
  IconPencilPlus,
  IconCoin,
  IconFileSettings,
} from "@tabler/icons";
import { lpStyles } from "../../lib/styles";

const useStyles = createStyles(lpStyles);

const lpHelp = {
  create: {
    link: "/create-event",
    icon: <IconPencilPlus />,
    label: "Create an Event",
  },
  attend: {
    link: "/events",
    icon: <IconCalendarEvent />,
    label: "See What's On",
  },
  status: {
    link: "/app/events",
    icon: <IconFileSettings />,
    label: "Check on my events",
  },
};

export default function LPHero() {
  const { classes } = useStyles();

  const scrollToPricing = () => {
    const el = document.querySelector('#pricing');
    const pos = el.getBoundingClientRect();
    window.scrollTo({top: pos.y, left: 0, behavior: 'smooth'});
  };

  return (
    <div>
      <div className={classes.hero}>
        <Container className={classes.container} pb={20}>
          <Title mt={50} className={classes.title}>
            Ticketing. Plain and Simple.
          </Title>

          <Text mb={0} mt={25} style={{ color: "white", fontSize: "1.5em" }}>
            I want to...
          </Text>
          {Object.keys(lpHelp).map(key => {
            const data = lpHelp[key];
            return (
              <Link to={data.link} key={`lpcategory${key}`}>
                <Button
                  leftIcon={data.icon}
                  variant="filled"
                  size="sm"
                  radius="lg"
                  className={classes.control}
                >
                  {data.label}
                </Button>
              </Link>
            );
          })}
          <Button 
            leftIcon={<IconCoin />}
            variant="filled"
            size="sm"
            radius="lg"
            className={classes.control}
            onClick={scrollToPricing}
          >
            Learn more about pricing
          </Button> 
        </Container>
      </div>
    </div>
  );
}
