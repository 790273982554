import {
  Container,
  Title
} from '@mantine/core';
import EmailPreferences from '../modules/settings/EmailPreferences';
import UserPreferences from '../modules/settings/UserPreferences';
import BillingPreferences from '../modules/settings/BillingPreferences';
import { useMutation } from 'graphql-hooks';
import { UPDATE_PROFILE_MUTATION } from '../network/mutations';
import { success, fail } from '../lib/notifications';

export default function Settings({me}) {
  const [updateProfile] = useMutation(UPDATE_PROFILE_MUTATION);

  const handleUpdateProfile = (field, value, type) => {
    const validTypes = ['user', 'settings'];
    const map = {
      first_name: 'first name',
      last_name: 'last name',
      email: 'e-mail address',
      timezone_id: 'timezone',
      currency: 'currency',
      etransfer: 'e-transfer address',
      reminders: 'e-mail reminders',
      marketing: 'marketing communication preferences',
    }

    if (validTypes.includes(type)) {
      updateProfile({variables: {id: me.id, field, value, type}, onSuccess: (res) => {
        me[field] = value;
        success('Profile updated', `Updated ${map[field]} successfully.`);
      }})
    } else {
      fail('Uh Oh!', 'Unable to update profile.');
    }
  };

  return (
    <Container>
      <Title mb={20}>Settings</Title>
      <UserPreferences user={me} handler={handleUpdateProfile} />
      <EmailPreferences user={me} handler={handleUpdateProfile} />
      <BillingPreferences user={me} handler={handleUpdateProfile} />
    </Container>
  )
}