import { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Button, Text } from '@mantine/core';
import { pickUrl } from '@/lib/uris';
import TicketResults from '@/screens/TicketResults';
import DonationResults from '@/screens/DonationResults';

export default function PaymentForm({display, evt}) {
  const [errorMessage, setErrorMessage] = useState(null);
  const [success, setSuccess] = useState(false);
  const [stripeData, setStripeData] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const results = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${pickUrl()}/tickets`,
      },
      redirect: 'if_required'
    });

    if (results.error) {
      // This point will only be reached if there is an immediate error when confirming the payment.
      setErrorMessage(results.error.message);
    } else { 
      const pi = results.paymentIntent;

      if (pi.status === 'requires_action') {
        if (results.next_action?.redirect_to_url) {
          window.open(results.next_action?.redirect_to_url, '_blank');
        } else {
          setErrorMessage('Further action is needed. Please contact your payment provider.');
        }
      }

      if (pi.status === 'succeeded') {
        setStripeData(pi);
        setSuccess(true);
      }
    }
  };

  if (success && stripeData) return evt.fundraising ? <DonationResults stripe={stripeData} display={display} evt={evt} /> : <TicketResults stripe={stripeData} display={display} />;

  return (
    <form onSubmit={handleSubmit}>
      {errorMessage && <Text color="red" fw={700} my={10} ta="center">{errorMessage}</Text>}
      <PaymentElement />
      <div style={{marginTop: "1em"}}>
        <Button disabled={!stripe} size="md" type="submit"> Complete Purchase </Button>
      </div>
    </form>
  ); 
};
