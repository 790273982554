import { useReducer, useEffect } from 'react';
import {
  TextInput,
  Text,
  Button,
  Stack
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { DataTable } from 'mantine-datatable';
import { IconSearch } from '@tabler/icons';
import { Link } from 'react-router-dom';
import { convertDate } from '@/lib/helpers';
import { IconCheck, IconX } from '@tabler/icons-react';

const PAGE_SIZE = 10;
export default function Events({events}) {
  const [records, setRecords] = useReducer((prev, next) => {
    const newRecords = { ...prev, ...next };

    const sortBy = (data, field, dir) => {
      if (data && data.length) {
        const results = data.sort((a, b) => {
          if(a[field] < b[field]) {
            return -1;
          }

          if (a[field] > b[field]) {
            return 1;
          }

          return 0;
        });

        return dir === 'desc' ? results.reverse() : results;
      } else {
        return [];
      }
    };

    const from = (newRecords.page - 1) * PAGE_SIZE;
    const to = from + PAGE_SIZE;

    const sorted = newRecords.rows ? sortBy(newRecords.events, newRecords.sortStatus.columnAccessor, newRecords.sortStatus.direction) : []; 

    newRecords.rows = sorted.length ? sorted.slice(from, to) : [];

    return newRecords;
  }, { 
    rows: events, 
    events: events, 
    titleQuery: '', 
    dateQuery: '', 
    ownerQuery: '',
    page: 1, 
    sortStatus: { 
      columnAccessor: 'title', 
      direction: 'asc' 
    } 
  });
  const [debouncedTitleQuery] = useDebouncedValue(records.titleQuery, 300);
  const [debouncedDateQuery] = useDebouncedValue(records.dateQuery, 300);
  const [debouncedOwnerQuery] = useDebouncedValue(records.ownerQuery, 300);

  useEffect(() => {
    if (records.events && debouncedTitleQuery !== '') {
      const val = debouncedTitleQuery.trim().toLowerCase();
      const filtered = records.events.filter(({ title }) => title.toLowerCase().includes(val));
      setRecords({rows: filtered});
    } 

    if (records.events && debouncedDateQuery !== '') {
      const val = debouncedDateQuery.trim().toLowerCase();
      const filtered = records.events.filter(({ startDate }) => startDate.includes(val));
      setRecords({rows: filtered});
    }

    if (records.events && debouncedOwnerQuery !== '') {
      const val = debouncedOwnerQuery.trim().toLowerCase();
      const filtered = records.events.filter(({ owner }) => `${owner.name} ${owner.email}`.includes(val));
      setRecords({rows: filtered});
    }

    if (debouncedTitleQuery === '' && debouncedDateQuery === '' && debouncedOwnerQuery === '') {
      setRecords({rows: records.events});
    }
  }, [setRecords, debouncedTitleQuery, debouncedDateQuery, debouncedOwnerQuery, records.events, records.published]);

  return (
    <DataTable
      withBorder
      minHeight={200}
      noRecordsText="No events matching your query"
      borderRadius="sm"
      withColumnBorders
      striped
      highlightOnHover
      records={records.rows}
      sortStatus={records.sortStatus}
      totalRecords={records.events?.length}
      onPageChange={(page) => setRecords({page})}
      page={records.page}
      recordsPerPage={PAGE_SIZE}
      onSortStatusChange={(status) => setRecords({sortStatus: status })}
      rowExpansion={{
        allowMultiple: true,
        content: ({ record }) => {
          const { shows } = record;
          if (shows.length) {
            const [total, capacity] = shows.reduce((t, show) => { t[0] += show.count; t[1] += show.cap; return t;}, [0, 0]);
            return (
              <Stack m={12}>
                <Text mb={10}><strong>Total Sales:</strong> {total} / {capacity}</Text>
                {shows.map(show => {
                  const st = show.subtitle || convertDate(show.startTime)

                  return <Text key={`show{show.id}`}>{st} - {show.count}</Text>
                })}
              </Stack>
            )
          } else {
            return <Text m={10}>No shows for this event.</Text>
          }
        }
      }}
      columns={[
        { 
          accessor: 'title',
          filter: (
            <>
              <TextInput
                label="Events by Title"
                description="Show events whose titles include the specified text"
                placeholder="Search events..."
                icon={<IconSearch size={16} />}
                value={records.titleQuery}
                onChange={(e) => setRecords({titleQuery: e.currentTarget.value })}
                mb={10}
              />
              <Button color="orange" onClick={() => setRecords({titleQuery: ''})}>Clear</Button>
            </>
          ),
          sortable: true,
          filtering: records.titleQuery !== '',
        },
        { 
          accessor: 'startDate',
          title: 'First Show',
          filter: (
            <>
              <TextInput
                label="Events by Start Time"
                description="Show events whose start time includes the specified text"
                placeholder="Search events..."
                icon={<IconSearch size={16} />}
                value={records.dateQuery}
                onChange={(e) => setRecords({ dateQuery: e.currentTarget.value })}
                mb={10}
              />
              <Button color="orange" onClick={() => setRecords({ dateQuery: '' })}>Clear</Button>
            </>
          ),
          sortable: true,
          filtering: records.dateQuery !== '',
          render: ({ startDate }) => <Text>{startDate}</Text>
        },
        {
          accessor: 'owner',
          title: 'Owner',
          sortable: true,
          render: ({ owner }) => <Text>{`${owner.name} (${owner.email})`}</Text>,
          filter: (
            <>
              <TextInput
                label="Events by Owner"
                description="Show events whose owner (name, email) includes the specified text"
                placeholder="Search events..."
                icon={<IconSearch size={16} />}
                value={records.ownerQuery}
                onChange={(e) => setRecords({ ownerQuery: e.currentTarget.value })}
                mb={10}
              />
              <Button color="orange" onClick={() => setRecords({ ownerQuery: '' })}>Clear</Button>
            </>
          ),
          filtering: records.ownerQuery !== '',
        },
        {
          accessor: 'published',
          title: 'Published',
          sortable: true,
          render: ({ published }) => published ? <IconCheck /> : <IconX />
        },
        { 
          accessor: 'actions',
          title: 'Actions',
          render: ({ id }) => {
            const editLink = `/app/events/${id}`;
            return (
              <Link to={editLink}>
                <Button color="orange">Edit</Button>
              </Link>
            ) 
          }
        },
      ]}
    /> 
  )
}