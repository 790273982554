import { useState } from 'react';
import {
  Container,
  Title,
  Tabs,
} from '@mantine/core';
import { useParams } from 'react-router';
import { IconAdjustments, IconKey, IconTicket, IconUserDollar } from '@tabler/icons-react';
import EmailPreferences from './EmailPreferences';
import UserPreferences from './UserPreferences';
import BillingPreferences from './BillingPreferences';
import Logins from './Logins';
import Tickets from './Tickets';
import Payouts from './Payouts';
import { useMutation, useQuery } from 'graphql-hooks';
import { ADMIN_USER_QUERY } from '@/network/queries';
import { UPDATE_PROFILE_MUTATION } from '@/network/mutations';
import { success, fail } from '@/lib/notifications';
import { useAuth } from '@/components/AuthProvider';
import PSLoader from '@/components/PSLoader';

export default function UserSettings() {
  const [activeTab, setActiveTab] = useState("settings");
  const { id } = useParams();
  const { admin } = useAuth();

  if (!admin) window.location = '/app/dashboard';

  const { loading, error, data, refetch } = useQuery(ADMIN_USER_QUERY, {variables: { id }});
  const [updateProfile] = useMutation(UPDATE_PROFILE_MUTATION);

  if (loading) return <PSLoader />;

  if (error) return "There was a problem loading the user's account information. Please try again later, or email support@plainstage.com.";

  const { user } = data;

  const handleUpdateProfile = (field, value, type) => {
    const validTypes = ['user', 'settings'];
    const map = {
      first_name: 'first name',
      last_name: 'last name',
      email: 'e-mail address',
      timezone_id: 'timezone',
      admin: 'admin',
      enabled: 'enabled',
      currency: 'currency',
      reminders: 'e-mail reminders',
      marketing: 'marketing communication preferences',
    }

    if (validTypes.includes(type)) {
      updateProfile({variables: {id: user.id, field, value, type}, onSuccess: (res) => {
        user[field] = value;
        success('Profile updated', `Updated ${map[field]} successfully.`);
      }})
    } else {
      fail('Uh Oh!', 'Unable to update profile.');
    }
  };

  return (
    <Tabs color="orange" defaultValue="settings" value={activeTab} onTabChange={setActiveTab}>
      <Tabs.List grow>
        <Tabs.Tab value="settings" icon={<IconAdjustments size="0.8rem" />}>Settings</Tabs.Tab>
        <Tabs.Tab value="logins" icon={<IconKey size="0.8rem" />}>Logins</Tabs.Tab>
        <Tabs.Tab value="tickets" icon={<IconTicket size="0.8rem" />}>Tickets</Tabs.Tab>
        <Tabs.Tab value="payouts" icon={<IconUserDollar size="0.8rem" />}>Payouts</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="settings" pt="xs">
        <Container>
          <Title mb={20}>Settings for {user.name} ({user.email})</Title>
          <UserPreferences user={user} refetch={refetch} handler={handleUpdateProfile} />
          <EmailPreferences user={user} refetch={refetch} handler={handleUpdateProfile} />
          <BillingPreferences user={user} refetch={refetch} handler={handleUpdateProfile} />
        </Container>
      </Tabs.Panel>

      <Tabs.Panel value="logins" pt="xs">
        <Logins data={user.logins} />
      </Tabs.Panel>

      <Tabs.Panel value="tickets" pt="xs">
        <Tickets email={user.email} userId={user.id} tickets={user.tickets} refetch={refetch} />
      </Tabs.Panel>

      <Tabs.Panel value="payouts" pt="xs">
        <Payouts payments={user.payments} currency={user.currency} />
      </Tabs.Panel>
    </Tabs>
  )
}