import { 
  SimpleGrid, 
  Container, 
  Title, 
  Text, 
  createStyles 
} from '@mantine/core';
import { useQuery } from "graphql-hooks";
import Event from '../components/Event';
import { LP_EVENTS_QUERY } from "../network/queries";
import { Link } from 'react-router-dom';
import PSLoader from '@/components/PSLoader';

const useStyles = createStyles((theme) => ({
    title: {
      fontSize: 34,
      fontWeight: 900,
      [theme.fn.smallerThan('sm')]: {
        fontSize: 24,
      },
    },
  
    description: {
      maxWidth: 600,
      margin: 'auto',
  
      '&::after': {
        content: '""',
        display: 'block',
        backgroundColor: '#fff',
        width: 45,
        height: 2,
        marginTop: theme.spacing.sm,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
}));

export default function LPEvents() {
    const { loading, error, data } = useQuery(LP_EVENTS_QUERY, { ssr: false });
    const { classes } = useStyles();
    if (loading) return <PSLoader />;

    if (error) {
        console.error(error);
        return (<div>There was an error looking up events. Please try again later.{JSON.stringify(error)}</div>)
    }

    const eventMap = [];
    const ids = [];

    data.events.forEach(evt => {
      if (!ids.includes(evt.id)) {
        eventMap.push(evt);
        ids.push(evt.id);
      } 
    });

    const eventCards = eventMap.map(evt => <Event key={`lpevent${evt.id}`} evt={evt} lp={true} />)
    const showEvents = eventCards.length > 0;
        
    return (
        <Container size="lg" py="xl">
            <Title order={2} className={classes.title} mt="sm">Upcoming Events</Title>

            {showEvents && (
                <SimpleGrid cols={3} spacing="xl" mt={50} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                    {eventCards}
                </SimpleGrid>
            )}
            {!showEvents && (
                <Container size="lg" py="xl">
                  <Title className={classes.title}>There are no events to show.</Title> 
                  <Text>Perhaps you should <Link to={'/create-event'}>create one?</Link></Text>
                </Container>
            )}

        </Container>
    )
}