import { pickUri } from "./uris";

const brandOrange = "#ed7966";

export const lpStyles = (theme) => ({
    hero: {
      position: 'relative',
      backgroundImage: `url(/img/lp/heroes/${Math.floor(Math.random()*6)+1}.jpg)`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      paddingBottom: theme.spacing.xl * 2,
      zIndex: 1,
      position: 'relative',
  
      [theme.fn.smallerThan('sm')]: {
        paddingBottom: theme.spacing.xl * 3,
      },
    },
    subcontainer: {
    },
    subtitle: {
      color: theme.black,
      fontSize: '2em',
      fontWeight: 700,
      lineHeight: 1.1,
      marginTop: '2em',
  
      [theme.fn.smallerThan('sm')]: {
        fontSize: 40,
        lineHeight: 1.2,
      },
  
      [theme.fn.smallerThan('xs')]: {
        fontSize: 28,
        lineHeight: 1.3,
      },
    },
    title: {
      color: theme.white,
      fontSize: '2.7em',
      fontWeight: 700,
      lineHeight: 1.1,
  
      [theme.fn.smallerThan('sm')]: {
        fontSize: 40,
        lineHeight: 1.2,
      },
  
      [theme.fn.smallerThan('xs')]: {
        fontSize: 28,
        lineHeight: 1.3,
      },
    },
    control: {
      // marginTop: theme.spacing.xl * 1.5,
      marginBottom: '10px',
      [theme.fn.smallerThan('sm')]: {
        // width: '100%',
      },
      color: theme.pink
    },
  });

  export const epStyles = (theme) => ({
    inner: {
      display: "flex",
      justifyContent: "space-between",
      paddingTop: theme.spacing.xl * 4,
      paddingBottom: theme.spacing.xl * 3,
    },
    content: {
      maxWidth: 480,
      marginRight: theme.spacing.xl * 3,
  
      [theme.fn.smallerThan("md")]: {
        maxWidth: "100%",
        marginRight: 0,
      },
    },
    title: {
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      fontSize: 54,
      lineHeight: 1.2,
      fontWeight: 900,
  
      [theme.fn.smallerThan("xs")]: {
        fontSize: 28,
      },
    },
    control: {
      backgroundColor: brandOrange,
      [theme.fn.smallerThan("xs")]: {
        flex: 1,
      },
    },
    image: {
      flex: 1,
  
      [theme.fn.smallerThan("md")]: {
        display: "none",
      },
    },
    highlight: {
      position: "relative",
      backgroundColor: theme.fn.variant({
        variant: "light",
        color: theme.primaryColor,
      }).background,
      borderRadius: theme.radius.sm,
      padding: "4px 12px",
    },
  }); 

  export const outerStyles = (theme) => ({
    wrapper: {
      minHeight: 900,
      backgroundSize: 'cover',
      backgroundImage:
        `url(${pickUri().replace('gql', '')}images/ps-stock.jpg)`,
    },
    button: {
      backgroundColor: theme.pink, 
      color: theme.colors.white
    },
    text: {
      textDecoration: 'none',
      color: theme.pink
    },
    form: {
      borderRight: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[3]
      }`,
      minHeight: 900,
      maxWidth: 450,
      paddingTop: 80,
  
      [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
        maxWidth: '100%',
      },
    },
  });