import { useState } from "react";
import { 
  Grid, 
  Title,
  Card,
  TextInput,
  Image,
  Group,
  Checkbox,
  Text,
  Button,
  Switch,
  CopyButton,
  Modal,
  rem,
  useMantineTheme
} from "@mantine/core";
import { useManualQuery } from "graphql-hooks";
import { SLUG_CHECK_QUERY } from "@/network/queries";
import { Carousel } from '@mantine/carousel';
import { useDisclosure } from "@mantine/hooks";
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { IconUpload, IconX, IconPhoto, IconExternalLink, IconLink, IconBook, IconCopy, IconIdBadge } from '@tabler/icons-react';
import { success, fail, notice } from "../../lib/notifications";
import { pickUrl, pickUri } from "../../lib/uris";
import { useAuth } from "../../components/AuthProvider";
import Event from '../../components/Event';
import { RichTextEditor, Link } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';

export default function EventPage({evt, handler, refetch}) {
  const { token } = useAuth();
  const [opened, {open, close}] = useDisclosure(false);
  const [cardOpened, {open: cardOpen, close: cardClose}] = useDisclosure(false);
  const [showContact, setShowContact] = useState(evt.showContact);
  const [useOrganizer, setUseOrganizer] = useState(true);
  const [slide, setSlide] = useState(0);
  const [contact, setContact] = useState(evt.contactInfo);
  const [hostedBy, setHostedBy] = useState(evt.hostedBy || evt.owner.name);
  const [slug, setSlug] = useState(evt.slug);
  const [isPublished, setIsPublished] = useState(evt.published);
  const [slugCheck] = useManualQuery(SLUG_CHECK_QUERY);
  const theme = useMantineTheme();
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
    ],
    content: evt.about,
  });

  const handleSlug = async() => {
    const query = await slugCheck({variables: { slug, organizer: true }});

    if (query.data.slugCheck) {
      const newSlug = `${slug}-1`;
      setSlug(newSlug);
      notice('So you know...', 'There is another event with that URL, so we have modified yours to avoid a conflict.');
      handler('slug', newSlug);
    } else {
      handler('slug', slug);
    }
  };

  const handleUpload = async (files) => {
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${token}`);
    const url = new URL(`${pickUri().replace('/gql', '')}/upload/${slide === 0 ? 'eventImage' : 'eventCardImage'}/${evt.id}`);
    const formData = new FormData();
    formData.append('file', files[0]);

    const fetchOptions = {
      method: "POST",
      body: formData,
      // mode: 'no-cors',
      headers
    };

    const results = await fetch(url, fetchOptions);

    if (results.ok) {
      evt.image = files[0].name;
      refetch();
      close();
      success('Uploaded', 'Event image uploaded');
    } else {
      fail('Hmmm...', 'Failed to upload image.');
    }
  };

  return (
    <>
    <Grid grow>
      <Grid.Col span={12}>
        <Card shadow="sm" padding="lg" withBorder>
          <Carousel
            mx="auto"
            height={250}
            onSlideChange={setSlide}
            styles={{
              control: {
                '&[data-inactive]': {
                  opacity: 0,
                  cursor: 'default',
                },
              },
            }}
          >
            <Carousel.Slide>
              <Title order={3} mb={10}>Event Page Image</Title>
              <Text mb={10}>This is the image that will be shown on your event page.</Text>
              <Image src={evt.image} width={'100%'} height="250" />
            </Carousel.Slide>
            <Carousel.Slide>
              <Title order={3} mb={10}>Event Card Image</Title>
              <Text mb={10}>This is the image that will be shown in the card view (such as the home page and in event search).</Text>
              <Image src={evt.cardImage} height="250" />
            </Carousel.Slide>
          </Carousel>
          <Button mt={10} color="orange" onClick={open}>Change Image</Button>

        </Card>
      </Grid.Col>
      <Grid.Col span={2}>
        <Card shadow="sm" padding="lg" withBorder style={{height: '100%'}}>
          <Title order={2} mb={10}>Publish Event</Title>
          <Text mb={10}>Make your event page live.</Text>
          <Switch 
            size="xl"
            onLabel="Published"
            offLabel="Draft"
            color="orange"
            checked={isPublished}
            onChange={(event) => { setIsPublished(event.currentTarget.checked); handler('published', event.currentTarget.checked.toString());}}
            my={20}
          />
        </Card>
      </Grid.Col>
      <Grid.Col span={4}>
        <Card shadow="sm" padding="lg" withBorder style={{height: '100%'}}>
          <Title order={2} mb={10}>Event Page URL</Title>
          <Text color="dimmed" size="sm">https://plainstage.com/events/...</Text>
          <TextInput value={slug} onChange={(e) => setSlug(e.target.value)} mb={10} />
          <Button.Group>
            <Button color="orange" mr={2} leftIcon={<IconLink />} onClick={() => handleSlug()}>Update URL</Button>
            <Button color="orange" mx={2} leftIcon={<IconIdBadge />} onClick={cardOpen}>Preview Event Card</Button>
            <Button color="blue" mx={2} leftIcon={<IconExternalLink />} onClick={() => window.open(`${pickUrl()}/events/${slug}`, '_blank')}>Visit Page</Button>
            <CopyButton mx={2} value={`https://plainstage.com/events/${slug}`}>
              {({ copied, copy }) => (
                <Button leftIcon={<IconCopy />} color={copied ? 'teal' : 'blue'} onClick={copy}>
                  {copied ? 'Copied ' : 'Copy '} URL
                </Button>
              )}
            </CopyButton>
          </Button.Group>
        </Card>
      </Grid.Col>
      <Grid.Col span={6}>
        <Card withBorder shadow="sm" padding="lg">
          <Title order={2} mb={10}>Event Contact</Title>
          <Group>
            <Checkbox 
              checked={showContact} 
              onChange={(e) => { handler('show_contact', e.target.checked.toString()); setShowContact(e.target.checked); }} 
            />
            <Text>Show contact info on event page</Text>
          </Group>
          <Group>
            <Checkbox 
              checked={useOrganizer} 
              disabled={!showContact} 
              onChange={(e) => { handler('contact_info', e.target.checked ? '' : contact); setUseOrganizer(e.target.checked); }} 
            />
            <Text>Use Organizer Contact Info</Text>
          </Group>
          <TextInput 
            mt={15} 
            label="This will be the e-mail or phone number visible on the event page." 
            value={contact} 
            disabled={!showContact && !useOrganizer} 
            placeholder="Enter contact info (phone / email)" 
            onChange={(e) => setContact(e.target.value)} 
            onBlur={() => handler('contact_info', contact)} 
          />
          <TextInput 
            mt={15} 
            label="This will be the name listed as 'Hosted By' on the event page." 
            value={hostedBy} 
            disabled={!showContact} 
            placeholder="Enter organizer name" 
            onChange={(e) => setHostedBy(e.target.value)} 
            onBlur={() => handler('hosted_by', hostedBy)} 
          />
        </Card>
      </Grid.Col>
      <Grid.Col span={12}>
        <Card shadow="sm" withBorder padding="lg">
          <Title order={2} mb={10}>Event Description</Title>
          <RichTextEditor editor={editor}>
            <RichTextEditor.Toolbar sticky stickyOffset={60}>
              <RichTextEditor.ControlsGroup>
                <RichTextEditor.Bold />
                <RichTextEditor.Italic />
                <RichTextEditor.Underline />
                <RichTextEditor.Strikethrough />
                <RichTextEditor.ClearFormatting />
                <RichTextEditor.Highlight />
                <RichTextEditor.Code />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.H1 />
                <RichTextEditor.H2 />
                <RichTextEditor.H3 />
                <RichTextEditor.H4 />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.Blockquote />
                <RichTextEditor.Hr />
                <RichTextEditor.BulletList />
                <RichTextEditor.OrderedList />
                <RichTextEditor.Subscript />
                <RichTextEditor.Superscript />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.Link />
                <RichTextEditor.Unlink />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.AlignLeft />
                <RichTextEditor.AlignCenter />
                <RichTextEditor.AlignJustify />
                <RichTextEditor.AlignRight />
              </RichTextEditor.ControlsGroup>
            </RichTextEditor.Toolbar>

            <RichTextEditor.Content />
          </RichTextEditor>
          <Button color="orange" leftIcon={<IconBook />} onClick={() => handler('about', editor.getHTML())} mt={20}>Update Description</Button>
        </Card>
      </Grid.Col>
    </Grid>
    <Modal opened={opened} onClose={close} centered title={`New Event ${slide === 0 ? 'Page' : 'Card'} Image`}>
      <Dropzone
          onDrop={handleUpload}
          onReject={(files) => fail('Uh Oh!', 'Could not upload that file.')}
          maxSize={(3 * 1024 ** 2)}
          maxFiles={1}
          accept={IMAGE_MIME_TYPE}
        >
        <Group position="center" spacing="xl" style={{ minHeight: rem(220), pointerEvents: 'none' }}>
          <Dropzone.Accept>
            <IconUpload
              size="3.2rem"
              stroke={1.5}
              color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
            />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <IconX
              size="3.2rem"
              stroke={1.5}
              color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
            />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <IconPhoto size="3.2rem" stroke={1.5} />
          </Dropzone.Idle>
          <div>
            <Text size="xl" inline>
              Drag an image here or click to select files
            </Text>
            <Text size="sm" color="dimmed" inline mt={7}>
              Attach an image file for your event (should not exceed 5mb)
            </Text>
            <Text size="sm" color="dimmed" inline mt={7}>
              Ideal dimensions: 851px x 315px (social media cover photo)
            </Text>
          </div>
        </Group>  
      </Dropzone>
    </Modal>  
    <Modal opened={cardOpened} onClose={cardClose} centered title="Preview Event Card">
      <Event evt={evt} lp={true} />
    </Modal>
  </>
)}
