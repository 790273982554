import {
  SimpleGrid,
  Badge,
  Box,
  Title,
  Text,
  Image,
  Anchor,
  List,
  Button
} from '@mantine/core';

export default function AboutScanner({ close }) {
  return (
    <SimpleGrid cols={2}>
      <Image src="https://api.plainstage.com/images/checkin_qrcode.png" />
      <Box p="md">
        <Title order={3} mb={10}>Ticket Scanner</Title>
        <Text>Want to use a mobile scanner to scan tickets at the door? Great! Just go to <Anchor href="https://checkin.plainstage.com" target="_blank">checkin.plainstage.com</Anchor> on your device of choice. You can also use your phone to scan the QR Code to the left, and it will take you there.</Text>
        <Text>Sign in, select the event and show for which you'd like to do checkin, and give the site permission to use the camera on your device.</Text>
        <Text>Then all you have to do is point the camera at a Plainstage QR Code, and the screen will flash one of three colours:</Text>
        <List my={10} listStyleType='none'>
          <List.Item mb={5}><Badge size="lg" color="green" variant="filled">Green</Badge> - Ticket is valid for show. First check in.</List.Item>
          <List.Item mb={5}><Badge size="lg" color="yellow" variant="filled">Yellow</Badge> - Ticket is valid for show. User has gone out and is coming back in.</List.Item>
          <List.Item><Badge size="lg" color="red" variant="filled">Red</Badge> - Ticket is NOT valid for show.</List.Item>
        </List>
        <Text>That's it, it's that simple.</Text>
        <Button color="green" onClick={close}>Got It</Button>
      </Box>
    </SimpleGrid>
  );
}