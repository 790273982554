import { useState } from "react";
import {
  Tabs
} from '@mantine/core';
import Settings from "@/screens/Settings";
import { IconSettingsCog, IconSettingsDollar } from "@tabler/icons-react";
import { useQuery } from "graphql-hooks";
import { ME_QUERY } from '@/network/queries';
import Payouts from "./Payouts";
import PSLoader from "@/components/PSLoader";

export default function UserMain() {
  const [activeTab, setActiveTab] = useState('settings');
  const { loading, error, data } = useQuery(ME_QUERY);

  if (loading) return <PSLoader />;

  if (error) return "There was a problem loading your user account information. Please try again later, or email support@plainstage.com.";

  const { me } = data;

  return (
    <Tabs color="orange" defaultValue="dashboard" value={activeTab} onTabChange={setActiveTab}>
      <Tabs.List grow>
        <Tabs.Tab value="settings" icon={<IconSettingsCog size="0.8rem" />}>Account Settings</Tabs.Tab>
        <Tabs.Tab value="payments" icon={<IconSettingsDollar size="0.8rem" />}>Payouts</Tabs.Tab>
      </Tabs.List>
      <Tabs.Panel value="settings" pt="xs">
        <Settings me={me} />
      </Tabs.Panel>
      <Tabs.Panel value="payments" pt="xs">
        <Payouts payments={me.payments} currency={me.currency} />
      </Tabs.Panel>
    </Tabs>
  )
}
