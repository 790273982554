import { useReducer } from 'react';
import {
  Modal,
  Center,
  Grid,
  Stack,
  Button,
  Image,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useManualQuery } from "graphql-hooks";
import { VALIDATE_CODE_QUERY } from "@/network/queries";
import Show from "./Show";
import Tier from "./Tier";
import { BuyTicket } from "./BuyTicket";
import { IconChevronLeft } from '@tabler/icons-react';
import OrderSummary from './OrderSummary';
import CheckoutForm from './CheckoutForm';
import TicketResults from '@/screens/TicketResults';

const brandOrange = "#ed7966";

export default function CheckoutModal({ evt, opened, close }) {
  const isMobile = useMediaQuery("(max-width: 50em)");
  const [displayOpts, setDisplayOpts] = useReducer((prev, next) => {
    const newStatus = {...prev, ...next};

    return newStatus;
  }, {stripe: false, tiers: false, checkout: false, checkoutBtn: false, promo: false, final: false});
  const [ticketData, setTicketData] = useReducer((prev, next) => {
    const newStatus = {...prev, ...next};
    let count = 0;

    if (next.tickets) {
      newStatus.tickets = prev.tickets;
      newStatus.tickets[next.tickets.id] = next.tickets;
    }

    if (Object.keys(newStatus.tickets).length) {
      const total = Object.values(newStatus.tickets).reduce((t, tier) => { 
        const inner = tier.tickets.reduce((sum, ticket) => {
          count += 1;
          const cost = (!ticket.donation && newStatus.promoPrice > -0.01) ? newStatus.promoPrice : ticket.price; 
          sum.subtotal += cost;
          sum.fees += cost === 0 ? 0 : ticket.fees; 
          return sum; 
        }, {subtotal: 0, fees: 0});

        t.subtotal += inner.subtotal;
        t.fees += inner.fees;

        return t;
      }, {subtotal: 0, fees: 0});

      newStatus.fees = total.fees;
      newStatus.subtotal = total.subtotal;
      newStatus.total = total.subtotal + total.fees + newStatus.donation;
      setDisplayOpts({checkoutBtn: Boolean(count)});
    }

    return newStatus;
  }, { name: '', email: '', donation: 0, promo: '', promoPrice: -0.01, show: 0, tickets: {}, fees: 0, subtotal: 0, total: 0});

  const storeData = () => {
    const ls = window.localStorage;
    const { name, email, donation, tickets, promo } = ticketData;
    const tix = JSON.stringify(Object.values(tickets).flatMap(outer => outer.tickets));

    ls.setItem('name', name);
    ls.setItem('email', email);
    ls.setItem('donation', donation);
    ls.setItem('promo', promo);
    ls.setItem('tickets', tix);
    setDisplayOpts({stripe: true});
  };

  const [checkCode] = useManualQuery(VALIDATE_CODE_QUERY);

  const showTiers = evt.shows.reduce((tierList, show) => { 
    //Populate tiers with show ID without having to query it
    tierList[show.id] = show.tiers.map(tier => {
      tier.show_id = show.id;
      return tier;
    }); 

    return tierList; 
  }, {});

  if (ticketData.show === 0 && evt.shows.length === 1) {
    setTicketData({
      show: evt.shows[0].id,
    });
    setDisplayOpts({ tiers: true });
  }
  
  const validateCode = () => {
    const c = ticketData.promo;
    const id = ticketData.show;

    if (c !== "") {
      checkCode({
        variables: { code: c, showId: id },
        onSuccess: results => {
          const promoPrice = Math.round(results.data.validateCode.price / 100);
          setDisplayOpts({promo: true});
          setTicketData({promoPrice});
        },
      });
    }
  };

  const setShow = (id) => {
    setTicketData({ show: id });
    setDisplayOpts({ tiers: Boolean(id) });
  };

  return (
    <Modal opened={opened} onClose={close} title={<strong>Buy tickets for {evt.title}</strong>} size="75%" fullScreen={isMobile}>
      <Grid>
        <Grid.Col md={8} span={12}>
          {isMobile && <Button onClick={close} mb={20} size="sm" compact color="gray">&times; Close Checkout</Button>}
          {displayOpts.checkout ? 
            displayOpts.stripe ? 
              (ticketData.total > 0) ? <BuyTicket evt={evt} ticketData={ticketData} display={setDisplayOpts} />
              : <TicketResults free={true} display={setDisplayOpts} />
                :
                  <CheckoutForm evt={evt} ticketData={ticketData} setTicketData={setTicketData} setDisplayOpts={setDisplayOpts} storeData={storeData} validateCode={validateCode} isMobile={isMobile} />
                  :
                    <Stack>
                      {displayOpts.tiers ? <>
                        <Button compact sx={{width: '25%'}} color="orange" onClick={() => setShow(0)} leftIcon={<IconChevronLeft />}>Back to Shows</Button>
                        {showTiers[ticketData.show].map((tier) => <Tier key={`tier${tier.id}`} evt={evt} tier={tier} isMobile={isMobile} handler={setTicketData} closed={evt.shows.find(show => show.id === ticketData.show).closed} />)}
                      </> :
                        evt.shows.map((show) => <Show key={`lpShow${show.id}`} evt={evt} show={show} isMobile={isMobile} showTiers={setShow} /> )
                      }
                      {displayOpts.checkoutBtn && <Center><Button size="xl" color={brandOrange} onClick={() => setDisplayOpts({checkout: true, checkoutBtn: false})}>Proceed</Button></Center>}
                    </Stack>
        }
      </Grid.Col>
      <Grid.Col md={4} span={12}>
        <Stack>
          {!isMobile && <Image mt={displayOpts.final ? '7.5em' : 0} src={evt.cardImage ? evt.cardImage : evt.image} />}
          {!displayOpts.final && <OrderSummary ticketData={ticketData} displayOpts={displayOpts} evt={evt} />}
        </Stack>
      </Grid.Col>
    </Grid>
  </Modal>
  )
}