import { useState, useCallback } from "react";
import {
    createStyles, 
    Card,
    Box,
    Button,
    Checkbox,
    Stack,
    Group,
    Select,
    TextInput,
    NumberInput,
    rem,
} from '@mantine/core';
import dayjs from "dayjs";
import { success, fail, notice } from '../../lib/notifications';
import { DateInput } from "@mantine/dates";
import { useMutation } from "graphql-hooks";
import { CREATE_PROMO_MUTATION, UPDATE_PROMO_MUTATION } from '../../network/mutations';
import { convertDate } from "../../lib/helpers";

const useStyles = createStyles((theme) => ({
  root: {
    position: 'relative',
  },
  input: {
    height: rem(54),
    paddingTop: rem(18),
    marginBottom: rem(10)
  },
  label: {
    position: 'absolute',
    pointerEvents: 'none',
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: `calc(${theme.spacing.sm} / 2)`,
    zIndex: 1,
  },
}));

export default function PromoCode({ promo, shows, refetch, eventId, delCode }) {
  const { classes } = useStyles();
  const [code, setCode] = useState(promo.code);
  const [price, setPrice] = useState(promo.price/100);
  const [show, setShow] = useState(promo.show_id);
  const [max, setMax] = useState(promo.max);
  const [scheduled, setScheduled] = useState(Boolean(promo.startDate) || Boolean(promo.endDate));
  const [startDate, setStartDate] = useState(promo.startDate ? convertDate(promo.startDate) : dayjs().format('YYYY-MM-DD')); 
  const [endDate, setEndDate] = useState(promo.endDate ? convertDate(promo.endDate) : null);
  const [modifyPromo] = useMutation(UPDATE_PROMO_MUTATION);
  const [createPromo] = useMutation(CREATE_PROMO_MUTATION);

  const handleCreatePromo = async () => {
    const fields = { code, price: price, showId: show, cap: max, eventId, start_date: startDate, end_date: endDate };
    const title = 'Uh Oh!';

    if (code === '') {
      const message = 'Promo Code cannot be blank.';
      fail(title, message);
      return false;
    }

    if (max === 0) {
      const message = `You must offer at least 1 ticket with this promo code.`;
      fail(title, message);

      return false;
    }

    if (!max) {
      const message = `You must set maximum uses for your promo code.`;
      fail(title, message);

      return false;
    }

    const results = await createPromo({variables: fields});

    if (results.data.createPromo) {
      promo.id = results.data.createPromo.id;
      success('Success!', `Promo code ${code} created successfully`);
      refetch();
    } else {
      const { error } = results;
      const msg = error?.graphQLErrors.length ? error.graphQLErrors[0].message : 'There was an error.';
      const message = `Unable to create promo - ${msg}`;
      fail(title, message);
    }
  };

  const handleUpdatePromo = useCallback(() => {
    const fields = { code, price, show, max }; 
    
    //Only need to check these fields if we have chosen scheduled promos
    if (scheduled) {
      fields.start_date = startDate;
      fields.end_date = endDate;
    }

    fields.price *= 100;

    if (code === '') {
      const message = "Promo Code cannot be blank.";
      fail('Uh Oh!', message);
      return false;
    }

    const dirty = Object.keys(fields).filter(f => promo[(f === 'show') ? 'show_id' : f] !== fields[f]);

    if (dirty.length) {
      const map = {
        code: 'promo code',
        price: 'price',
        show: 'show',
        max: 'capacity',
        start_date: 'start date',
        end_date: 'end date'
      };

      for (let field of dirty) {
        (async() => {
          if (fields[field] === true || fields[field] === false) {
            fields[field] = fields[field].toString();
          }

          if (field === 'price' || field === 'max') {
            fields[field] = fields[field].toString();
          }

          const results = await modifyPromo({variables: {id: promo.id, field, value: fields[field]}});

          if (results.data.modifyPromo) {
            promo[field] = (field === 'price') ? +fields[field] / 100 : fields[field];
            success('Promo Code Updated', `Updated ${map[field]} successfully.`);
          } else {
            const { error } = results;
            const msg = error?.graphQLErrors.length ? error.graphQLErrors[0].message : 'There was an error.';
            const message = `Unable to update promo code - ${msg}`;
            fail('Uh Oh!', message);
          }
        })();
      }
    } else {
      notice('No Changes', 'The promo code has not changed - Nothing to update');
    }
  }, [code, price, show, max, promo, scheduled, startDate, endDate, modifyPromo]);

  return (
    <Card mb={20} withBorder shadow="sm">
      <Stack>
        <TextInput
          className={classes}
          value={code}
          onChange={(e) => setCode(e.target.value)}
          placeholder="SPECIAL10"
          size="md"
          label="Promo Code"
        />
        <Group grow>
          <NumberInput
            className={classes}
            label="Promo Price"
            placeholder="Cost with promo"
            value={price}
            onChange={setPrice}
          />
          <NumberInput
            className={classes}
            label="Max uses"
            placeholder="Max to sell"
            value={max}
            onChange={setMax}
          />
        </Group>
        <Select
          label="Select a show"
          placeholder="Select a show"
          value={show}
          data={[{value: 0, label: "All shows"}, ...shows.map(s => { return { value: s.id, label: s.subtitle ? s.subtitle : convertDate(s.startTime)}})]}
          onChange={setShow}
        />
        <Checkbox
          label="Scheduled Promo Code?"
          color="orange"
          size="md"
          description="Set start and end times for promo code being effective"
          checked={scheduled}
          onChange={(e) => setScheduled(e.currentTarget.checked)}
        />
        {scheduled && (
          <Box>
            <DateInput
              popoverProps={{ withinPortal: true }}
              mb={10}
              className={classes}
              clearable
              valueFormat="MMM DD, YYYY"
              label="Set date for when promo takes effect (optional)"
              placeholder="Pick date"
              maw={400}
              size="md"
              value={new Date(startDate)}
              onChange={setStartDate}
              mx="auto"
            />
            <DateInput
              className={classes}
              popoverProps={{ withinPortal: true }}
              clearable
              valueFormat="MMM DD, YYYY"
              label="Set date for when promo should stop (optional)"
              placeholder="Pick date"
              maw={400}
              size="md"
              value={endDate ? new Date(endDate) : undefined}
              onChange={setEndDate}
              mx="auto"
            />
        </Box>)}
        <Group position="apart">
          <Button color="orange" onClick={promo.id === 0 ? handleCreatePromo : handleUpdatePromo}>{promo.id === 0 ? 'Create' : 'Update'} Promo Code</Button>
          <Button color="red" onClick={() => delCode(promo.id) }>Delete Promo Code</Button>
        </Group>
      </Stack>
    </Card>
  )
}