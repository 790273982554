import { useEffect } from 'react';
import { MantineProvider } from '@mantine/core';
import { useLocation, Outlet } from 'react-router';
import { AuthProvider } from "./components/AuthProvider";
import LPHeader from './modules/lp/LPHeader';
import { brandColours } from './lib/helpers';
import { Notifications } from '@mantine/notifications';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { pickGoogle } from "./lib/uris";

export default function App() {
  const google = pickGoogle();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  
  return  (
    <MantineProvider 
      withGlobalStyles 
      withNormalizeCSS 
      theme={{
        fontFamily: 'Roboto, sans-serif',
        colors: brandColours, 
        primaryColor: 'pink', 
        primaryShade: 4
      }}
    >
      <Notifications />
      <GoogleOAuthProvider clientId={google.client_id}>
        <AuthProvider>
          <LPHeader />
          <Outlet />
        </AuthProvider>
      </GoogleOAuthProvider>
    </MantineProvider>
  );
}
