import { Header, Group } from "@mantine/core";
import Logo from '../../components/Logo';
import { Link } from "react-router-dom";

export default function PSHeader() {
    return (
        <Header height={70}>
          <Group sx={{ height: '100%' }} px={20} position="apart">
            <Link to="/">
              <Logo size="sm" />
            </Link>
          </Group>
        </Header>
    )
}