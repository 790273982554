// import { useState } from "react";
import { useQuery } from "graphql-hooks";
import { EVENT_QUERY } from "../network/queries";
import {
  Alert,
  Image,
  Container,
  Card,
  Center,
  Stack,
  Title,
  Button,
  Grid,
  Text,
  Divider,
  Box
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { useAuth } from '../components/AuthProvider';
import { IconAlertCircle, IconCalendarEvent, IconMapPin, IconUser, IconWorldWww } from "@tabler/icons-react";
import { Navigate, useParams } from "react-router";
import DonationModal from "@/modules/checkout/DonationModal";
import CheckoutModal from "@/modules/checkout/CheckoutModal";
import { IconTicket } from "@tabler/icons-react";
import { convertDate } from "@/lib/helpers";
import {Helmet} from "react-helmet";
import PSLoader from "@/components/PSLoader";

function ShowLine({show, idx}) {
  const leader = (idx === null || idx === undefined) ? '' : (idx === 0) ? 'Starts: ' : 'Ends: ';
    
  return (
    <Text mt={4}><Text span fw='bold'>{leader}</Text>{convertDate(show.startTime)}</Text>
  )
}
function ShowInfo({shows}) {
  if (shows && shows[0]) {
    return (
      <>
        <Grid.Col span={2}>
          <IconCalendarEvent color="#ff5a60" size="2rem" stroke="1.5" />
        </Grid.Col>
        <Grid.Col span={10}>
          {shows.length === 1 ? <ShowLine show={shows[0]} /> : shows.map((show, idx) => <ShowLine key={`showLine${idx}`} show={show} idx={idx} />)}
        </Grid.Col>
      </>
    );
  } else {
    return null;
  }
}

export default function EventPage() {
  const { slug } = useParams();
  const isMobile = useMediaQuery("(max-width: 50em)");
  const [ opened, { open, close }] = useDisclosure(false);
  const { loading, error, data } = useQuery(EVENT_QUERY, { variables: { slug }, });
  const { userId, admin } = useAuth();

  if (error) return <Navigate to="/" />;

  if (loading) return <PSLoader />;

  const { event: evt } = data;

  if (evt) {
    if (!evt.published && +evt.owner.id !== +userId && !admin) {
      return <Navigate to="/" />;
    } 

    const shows = [...evt.shows];
    const cap = shows.reduce((total, show) => total += show.cap, 0);
    const ticketCount = shows.reduce((total, show) => total += show.count, 0);

    const soldOut = ticketCount >= cap;

    const lowestPrice = shows.flatMap(show => show.tiers).sort((a, b) => a.price - b.price)[0];

    const contactInfo = evt.contactInfo || evt.owner.email;

    return (
      <Container>
        <Helmet>
          <title>Plainstage - {evt.title}</title>
          <meta property="og:title" content={`Plainstage - ${evt.title}`} />
          <meta property="og:site_name" content="Plainstage" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:description" content={`${evt.title} on Plainstage`} />
          <meta property="og:type" content="product" />
          <meta property="og:image" content={evt.image} />
        </Helmet>
        {!evt.published && <Alert mb={20} icon={<IconAlertCircle size="2rem" />} title="Draft" color="yellow" radius="xs">
          This is a draft page - It has not been published.
        </Alert>}
        <Image src={evt.image} imageProps={{style: { height: isMobile ? '100%' : null, objectPosition: '0 0', objectFit: isMobile ? 'contain' : 'cover'}}} />
        {(evt.fundraising && isMobile) && 
          <Center my={25}>
            <Button color="orange" size="xl" onClick={open} leftIcon={<IconTicket />}>{evt.fundraising ? "Donate Now" : "Get Tickets"}</Button>
            {evt.fundraising ? <DonationModal evt={evt} opened={opened} close={close} /> : <CheckoutModal evt={evt} opened={opened} close={close} />}
          </Center>}
        <Title mt={10} fz={isMobile ? "2em" : "3em"}>{evt.title}</Title>
        <Divider my={25} />
        <Grid grow>
          <Grid.Col span={6} orderSm={2} orderMd={1}>
            <Text mt="md" id="eventAbout">
              {" "}<div dangerouslySetInnerHTML={{ __html: evt.about || "" }} />{" "}
            </Text>
          </Grid.Col>
          <Grid.Col span={12} md={5} offset={isMobile ? 0 : 1} orderSm={1} orderMd={2}>
            <Card style={{color: '#687279'}} withBorder my={25}>
              <Grid gutter="xs">
                {!evt.fundraising && <><Grid.Col span={2}>
                  <IconTicket color="#ff5a60" size="2rem" stroke="1.5" />
                </Grid.Col>
                <Grid.Col span={10}>
                  <Text mt={4}><Text span fw="bold">Starting at</Text> {lowestPrice?.formattedPrice}</Text>
                </Grid.Col></>}
                {evt.isOnline ? 
                  <>
                    <Grid.Col span={2}>
                      <IconWorldWww color="#ff5a60" size="2rem" stroke="1.5" />
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <Text mt={4}>Event is online.</Text>
                    </Grid.Col>
                  </>
                  :
                  <>
                    <Grid.Col span={2}>
                      <IconMapPin color="#ff5a60" size="2rem" stroke="1.5" />
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <Text mt={4} fw="bold">{evt.venue?.name}</Text>
                      <Text>{evt.venue?.street_address}</Text>
                      <Text>{evt.venue?.city}, {evt.venue?.stprv}</Text> 
                    </Grid.Col>
                  </>
                }
                {evt.showContact && 
                <>
                  <Grid.Col span={2}>
                    <IconUser color="#ff5a60" size="2rem" stroke="1.5" />
                  </Grid.Col>
                  <Grid.Col span={10}>
                    <Text mt={4} fw="bold">{evt.fundraising ? "Organized" : "Hosted"} by {evt.hostedBy || evt.owner.name}</Text>
                    <Text fz={contactInfo.length > 32 ? "sm" : "md"}>Contact: {contactInfo}</Text>
                  </Grid.Col>
                </>
                }
                {!evt.fundraising && <ShowInfo shows={shows.length === 1 ? shows : [shows.shift(), shows.pop()]} />}
              </Grid>
            </Card>
            {soldOut ?  <Title color="#ff5a60" order={3}> We are Sold OUT! </Title>
            :
              <Center mb={25}>
                <Stack>
                  <Button color="orange" size="xl" onClick={open} leftIcon={<IconTicket />}>{evt.fundraising ? "Donate Now" : "Get Tickets"}</Button>
                  {(evt.owner.id === '1903') && 
                    <Box>
                      <Text>Pay What You Can</Text>
                      <Text>All proceeds directly support FIF programming.</Text>
                      <Image src="https://api.plainstage.com/images/fif.png" alt="Forward In Film" />
                    </Box>}
                </Stack>
                {evt.fundraising ? <DonationModal evt={evt} opened={opened} close={close} /> : <CheckoutModal evt={evt} opened={opened} close={close} />}
              </Center>
            }
          </Grid.Col>
        </Grid>

        <Center>
          <Text mt={10} color="dimmed">Have questions about your ticket experience? Contact support@plainstage.com.</Text>
        </Center>
      </Container>
    );
  } else {
    console.log("STB:", evt);
    return <Navigate to="/" />;
  }
};
