import { Fragment } from 'react';
import { 
    createStyles, 
    Group, 
    Container,
    ActionIcon,
    Space,
    Grid
} from '@mantine/core';
import { IconBrandInstagram } from '@tabler/icons';
import { Link } from 'react-router-dom';

const footerLinks = [
  {
    label: "Home",
    link: "/",
    external: false,
    mobile: false
  },
  {
    label: "Our Story",
    link:"/our-story",
    external: false,
    mobile: false
  },
  {
    label: "Create an Event",
    link: "/create-event",
    external: false,
    mobile: true
  },
  {
    label: "Contact Us",
    link: "/contact-us",
    external: false,
    mobile: true 
  },
  {
    label: 'Privacy Policy',
    link: '/privacy-policy',
    external: false,
    mobile: true,
  },
  {
    label: 'Terms of Service',
    link: '/tos',
    external: false,
    mobile: true
  },
  {
    label: 'FAQ',
    link: 'https://help.plainstage.com/docs/faq',
    external: true,
    mobile: true
  }
];

const useStyles = createStyles((theme) => ({
  footer: {
    height: '6em',
    backgroundColor: '#303846',
    borderTop: `1px solid ${theme.colors.gray[2]}`,
  },
  col: {
    height: '6em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  col2: {
    height: '6em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  inner: {
    padding: `${theme.spacing.md} ${theme.spacing.md}`,

    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',
    },
  },
  links: {
    textDecoration: 'none',
    fontWeight: 700,
    fontSize: '0.8em',
    color: 'white',
    [theme.fn.smallerThan('sm')]: {
      marginTop: theme.spacing.lg,
      marginBottom: theme.spacing.sm,
      fontSize: '0.7em'
    },
  },
}));

export default function LPFooter({ isMobile }) {
  const { classes } = useStyles();
  const list = isMobile ? footerLinks.filter(link => link.mobile) : footerLinks;
  const items = list.map((link) => (
    <Fragment key={link.label}>
      <Link
        className={classes.links}
        to={link.link}
        target={link.external ? "_blank" : "_self"}
      >
        {link.label}
      </Link>
      <Space w="sm" />
    </Fragment>
  ));

  return (
    <div className={classes.footer}>
      <Container>
        <Grid grow>
            <Grid.Col pl={20} className={classes.col} span={9}>
              {items}
            </Grid.Col>
            <Grid.Col pr={20} className={classes.col2} span={3}>
              <Group spacing="xs" position="right" noWrap>
                <Link to="https://instagram.com/plainstage_">
                  <ActionIcon size="lg" variant="transparent" radius="xl">
                      <IconBrandInstagram size="1.5rem"/>
                  </ActionIcon>
                </Link>
              </Group>
            </Grid.Col>
        </Grid>
      </Container>
    </div>
  );
}