import { 
  createStyles, 
  Container, 
  Title, 
  Grid,
} from "@mantine/core";
import { useQuery } from "graphql-hooks";
import { EVENT_SEARCH_QUERY } from "@/network/queries";
import Event from "@/components/Event";
// import LPCategory from "../../components/LPCategory";
import { lpStyles } from "../../lib/styles";
import PSLoader from "@/components/PSLoader";

const useStyles = createStyles(lpStyles);

export default function LPCategories({ categories }) {
  const { classes } = useStyles();
  const { loading, error, data } = useQuery(EVENT_SEARCH_QUERY, {variables: { past: false}});

  if (loading) return <PSLoader />;

  if (error) return 'There was an error loading events.';

  const { events } = data;

  return (
    <Container className={`${classes.container} ${classes.subcontainer}`}>
      <Title my={10}>Upcoming Events</Title>
      <Grid gutter={"xl"} grow>
        {events.slice(0, 3).map((event, idx) => (
          <Grid.Col
            key={`event${idx}`}
            span={6}
            md={4}
          >
            <Event evt={event} lp={true} />
          </Grid.Col>
        ))}
      </Grid>
    </Container>
  );
}
