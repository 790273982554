import { useEffect, useState } from "react";
import {
  createStyles,
  NumberInput,
  Slider,
  Text,
  Checkbox,
  Grid,
  Group,
  ActionIcon,
  Title,
  Center,
} from "@mantine/core";
import { formatCurrency } from "../lib/helpers";
import { IconMoodSad, IconMoodHappy } from "@tabler/icons-react";

const useStyles = createStyles(theme => ({
  wrapper: {
    position: "relative",
    width: "100%",
    padding: '0 1em'
  },
}));

export default function Calculator({ isMobile }) {
  const { classes } = useStyles();
  const [cost, setCost] = useState(20);
  const [passFees, setPassFees] = useState(false);
  const [passCC, setPassCC] = useState(false);
  const [ticketCost, setTicketCost] = useState(0);
  const [compTicketCost, setCompTicketCost] = useState(0);
  const [revenue, setRevenue] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [compCost, setCompCost] = useState(0);
  const [seats, setSeats] = useState(30);

  const manageSeats = (num) => (num > 43) ? num / 2 : num;

  useEffect(() => {
    const gross = cost * seats;
    //Stripe - 2.9% + $0.30/per
    //PS - 1% + $0.50 per
    const ps = {
      cc: cost * 0.029 + 0.3,
      price: cost * 0.01,
      perTicket: 0.5,
    };
    ps.ticketPrice =
      cost + (passCC ? ps.cc : 0) + (passFees ? ps.price + ps.perTicket : 0);
    ps.fees =
      (passCC ? 0 : (gross * 0.029) + (manageSeats(seats) * 0.3)) +
      (passFees ? 0 : gross * 0.01 + seats * ps.perTicket);

    //EBCC - 2.9%
    //EB - 12.99/33.99 + 3.5% + $1.29 per
    const competitor = {
      cc: cost * 0.029,
      price: cost * 0.035,
      eventFee: seats > 25 ? (seats > 100 ? 33.99 : 12.99) : 0,
      perTicket: 1.29,
    };
    competitor.ticketPrice =
      cost +
      (passCC ? competitor.cc : 0) +
      (passFees ? competitor.price + competitor.perTicket : 0);
    competitor.fees =
      (passCC ? 0 : gross * 0.029) +
      (passFees ? 0 : 0.035 * gross + competitor.perTicket * seats) +
      competitor.eventFee;

    setTicketCost(formatCurrency(ps.ticketPrice));
    setCompTicketCost(formatCurrency(competitor.ticketPrice));
    setTotalCost(formatCurrency(ps.fees));
    setCompCost(formatCurrency(competitor.fees));
    setRevenue(formatCurrency(gross));
  }, [
    cost,
    seats,
    passFees,
    passCC,
    setTotalCost,
    setTicketCost,
    setRevenue,
    setCompCost,
  ]);

  const normalize = (num) => num.toString().replace("$", "").replace(",", '');

  const formatSavings = () => {
    const comp = normalize(compCost);
    const ps = normalize(totalCost);

    return formatCurrency(parseFloat(comp) - parseFloat(ps));
  };

  return (
    <div className={classes.wrapper}>
      <Grid grow>
        <Grid.Col span={12} md={6}>
          <Checkbox
            mb={25}
            ml={isMobile ? 0 : "xl"}
            size="md"
            checked={passFees}
            disabled={cost === 0}
            onChange={event => setPassFees(event.currentTarget.checked)}
            label={`Pass ticket fees on to attendees`}
          />
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <Checkbox
            mb={25}
            size="md"
            checked={passCC}
            disabled={cost === 0}
            onChange={event => setPassCC(event.currentTarget.checked)}
            label={`Pass credit card fees on to attendees`}
          />
        </Grid.Col>
        <Grid.Col span={9} md={10}>
          <Slider
            my="xl"
            mr="xl"
            ml={isMobile ? 0 : "xl"}
            max={120}
            step={1}
            min={0}
            labelAlwaysOn
            label={`Average Ticket Price`}
            value={cost === "" ? 0 : cost}
            onChange={setCost}
            size={"lg"}
            radius={0}
            marks={[
              { value: 25, label: "$25" },
              { value: 50, label: "$50" },
              { value: 75, label: "$75" },
              { value: 100, label: "$100" },
            ]}
            styles={{
              dragging: {
                opacity: 1,
              },
            }}
          />
        </Grid.Col>
        <Grid.Col span={3} md={2}>
          <NumberInput
            mt="md"
            value={cost}
            precision={0}
            onChange={setCost}
            step={1}
            min={0}
            max={120}
            hideControls
            parser={value => value.replace(/\$\s?|(,*)/g, "")}
            formatter={value => `$${value}`}
            styles={{ input: { width: isMobile ? "4em" : "7.5em", textAlign: 'center' }}}
          />
        </Grid.Col>
        <Grid.Col span={9} md={10}>
          <Slider
            my="xl"
            mr="xl"
            ml={isMobile ? 0 : 'lg'}
            max={120}
            step={1}
            min={0}
            labelAlwaysOn
            label={`Expected attendance`}
            value={seats === "" ? 0 : seats}
            onChange={setSeats}
            size={"lg"}
            radius={0}
            marks={[
              { value: 25, label: "25" },
              { value: 50, label: "50" },
              { value: 75, label: "75" },
              { value: 100, label: "100" },
            ]}
            styles={{
              dragging: {
                opacity: 1,
              },
            }}
          />
        </Grid.Col>
        <Grid.Col span={3} md={2}>
          <NumberInput
            mt="md"
            value={seats}
            precision={0}
            onChange={setSeats}
            step={1}
            min={0}
            max={120}
            hideControls
            styles={{ input: { width: isMobile ? "4em" : "7.5em", textAlign: 'center' }}}
          />
        </Grid.Col>
        <Grid.Col span={12} md={4} align="center" justify="center">
          <div style={{paddingLeft: isMobile ? 0 :'70px'}}>
            <Title order={3} mt={10}> Plainstage Fees </Title>
            <Text fz="0.7em" color="dimmed" mb={10}>1% of sales, $0.50 / ticket</Text>
            <Text>Our Ticket Cost: {ticketCost}</Text>
            <Center>
              <Group spacing="2">
                <Text>Our Fees: {totalCost}</Text>
                <ActionIcon color="green" variant="transparent">
                  <IconMoodHappy />
                </ActionIcon>
              </Group>
            </Center>
          </div>
        </Grid.Col>
        <Grid.Col span={12} md={4} align="center">
          <Title order={1} style={{lineHeight: isMobile ? '1em': '3.8em'}}>VS</Title>
        </Grid.Col>
        <Grid.Col span={12} md={4} align="center" justify="center">
          <div style={{paddingRight: isMobile ? 0 :'70px'}}>
            <Title order={3} mt={10}> Competitor Fees </Title>
            <Text fz="0.7em" color="dimmed" mb={10}>3.5% of sales, $1.29 / ticket + setup fees</Text>
            <Text>Their Ticket Cost: {compTicketCost}</Text>
            <Center>
              <Group spacing="2">
                <Text>Their Fees: {compCost}</Text>
                <ActionIcon color="red" variant="transparent">
                  <IconMoodSad />
                </ActionIcon>
              </Group>
            </Center>
          </div>
        </Grid.Col>
        {/* {!isMobile && <Grid.Col span={2}>&nbsp;</Grid.Col>} */}
        <Grid.Col span={12} md={6} offset={isMobile ? 0 : 3} align="center">
          <Title order={3} mt={isMobile ? 0 : 20}>
            Expected Revenue: {revenue}
          </Title>
          <Title order={3} color="pink">
            You save:{" "}
            <strong>
              {formatSavings()}
            </strong>
          </Title>
          <Text color="dimmed" mt={isMobile ? 15 : 0} fz={isMobile ? "0.6em" : "0.8em"}>
            Standard credit card fees of 2.9% + $0.30 per transaction apply.
          </Text>
        </Grid.Col>
        <Grid.Col span={3} md={0}>&nbsp;</Grid.Col>
      </Grid>
    </div>
  );
}
