import {
    createStyles, 
    Container,
    Title,
    Text,
    Card,
    Stack
} from '@mantine/core';
import Calculator from '../../components/Calculator';

const useStyles = createStyles((theme) => ({
  container: {
    backgroundColor: '#fff',
    zIndex: 1,
    position: 'relative',
    padding: '3em',

    [theme.fn.smallerThan('sm')]: {
      paddingBottom: theme.spacing.xl * 3,
    },
  },
}));

export default function Pricing({ isMobile }) {
  const { classes } = useStyles();
    
  return (
    <>
    <div id="pricing" className={classes.container}>
      <Container>
        <Card withBorder shadow={"sm"} padding="lg">
          <Title order={1} mb={10} color="#000">Simply the Lowest Pricing</Title>
          <Stack align="center" spacing={0}>
            <Title order={1} mt="md" mb={0} color="orange">1% on Total Sales*</Title>
            <Title order={3} color="pink" mb="md">+ $0.50 per ticket*</Title>
            <Text color="black">* - No, seriously</Text>
          </Stack>
        </Card>
      </Container>
    </div>
    <Container my={20}>
      <Card withBorder shadow={"sm"} padding="lg">
        <Title order={1} mb={10}>Ticket Calculator</Title>
        <Calculator isMobile={isMobile} />
      </Card>
    </Container>
  </>
  )
}