import { useState } from 'react';
import { 
    createStyles, 
    Card, 
    Group, 
    Switch, 
    Text
} from '@mantine/core';

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },

  item: {
    '& + &': {
      paddingTop: theme.spacing.sm,
      marginTop: theme.spacing.sm,
      borderTop: `0.1rem solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
      }`,
    },
  },

  switch: {
    '& *': {
      cursor: 'pointer',
    },
  },

  title: {
    lineHeight: 1,
    fontWeight: 'bolder',
    marginBottom: '0.45rem'
  },
}));

const emailPrefs = {
  title: 'E-Mail Settings',
  description: 'Select what e-mails the user should receive from Plainstage',
  data: [
    {
      title: 'Marketing E-mails',
      description: 'Receive periodic marketing e-mails from Plainstage.com',
      field: 'marketing'
    },
    {
      title: 'Event Reminders',
      description: 'Receive reminders for upcoming events you are attending',
      field: 'reminders'
    }
  ]
};

function Preference({title, description, field, value, handler}) {
  const { classes } = useStyles();
  const [checked, setChecked] = useState(value);

  const handleSwitch = (e) => {
    const type = 'settings';
    setChecked(e.target.checked);
    const value = e.target.checked.toString();

    handler(field, value, type);
  };

  return (
    <Group position="apart" className={classes.item} noWrap spacing="xl">
      <div>
        <Text>{title}</Text>
        <Text size="xs" color="dimmed">
          {description}
        </Text>
      </div>
      <Switch 
        onLabel="ON" 
        offLabel="OFF" 
        value={field} 
        checked={checked} 
        className={classes.switch} 
        size="lg" 
        onChange={handleSwitch} 
      />
    </Group>
  );
}

export default function EmailPreferences({user, handler}) {
  const { classes } = useStyles();

  const items = emailPrefs.data.map((item) => <Preference key={`pref${item.field}`} handler={handler} value={user.emailPrefs[item.field]} {...item} /> );

  return (
    <Card withBorder radius="md" mb="xl" p="xl" className={classes.card}>
      <Text fz="lg" className={classes.title}>
        {emailPrefs.title}
      </Text>
      <Text fz="xs" c="dimmed" mt={13} mb="xl">
        {emailPrefs.description}
      </Text>
      {items}
    </Card>
  );
}