import { useState, useCallback } from "react";
import {
    createStyles, 
    Card,
    Title,
    Button,
    Group,
    Checkbox,
    SimpleGrid,
    Collapse,
    Burger,
    Text,
    rem,
    TextInput,
    NumberInput
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { DateTimePicker } from "@mantine/dates";
import { success, fail, notice } from '@/lib/notifications';
import { useMutation } from "graphql-hooks";
import { UPDATE_SHOW_MUTATION, DELETE_SHOW_MUTATION } from '@/network/mutations';
import TZSelect from "../createEvent/TZSelect";
import VenueSearch from "../createEvent/VenueSearch";
import dayjs from "dayjs";
import { errorMsg } from "@/lib/helpers";
import { IconArmchair } from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  root: {
    position: 'relative',
  },
  input: {
    height: rem(54),
    paddingTop: rem(18),
    marginBottom: rem(10)
  },
  label: {
    position: 'absolute',
    pointerEvents: 'none',
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: `calc(${theme.spacing.sm} / 2)`,
    zIndex: 1,
  },
}));

export default function Show({ show, refetch }) {
  const { classes } = useStyles;
  const [opened, { toggle }] = useDisclosure(false);
  const [subtitle, setSubtitle] = useState(show.subtitle || '');
  const [cap, setCap] = useState(show.cap || 0);
  const [startTime, setStartTime] = useState(show.startTime);
  const [endTime, setEndTime] = useState(show.endTime ? +show.endTime : null);
  const [venue, setVenue] = useState(show.venue || 'Online');
  const [tz, setTz] = useState(show.tz);
  const [closed, setClosed] = useState(show.closed);
  const [autoclose, setAutoclose] = useState(show.autoclose);
  const [closeInterval, setCloseInterval] = useState(show.closeInterval);
  const [updateShow] = useMutation(UPDATE_SHOW_MUTATION);
  const [deleteShow] = useMutation(DELETE_SHOW_MUTATION);
  
 const handleUpdate = useCallback(() => {
    const fields = { subtitle, cap, startTime, endTime, venue, tz, closed, autoclose, closeInterval };

    const msgMap = {
      'startTime': 'show start time',
      'endTime': 'show end time',
      'cap': 'capacity',
      'tz': 'time zone',
      'subtitle': 'show title',
      'venue': 'show venue',
      'closed': 'closed sales status',
      'closeInterval': 'interval to close sales',
      'autoclose': 'autoclose'
    };

    const fieldMap = {
      'startTime': 'start_time',
      'endTime' : 'end_time',
      'cap': 'cap',
      'tz': 'timezone_id',
      'subtitle': 'subtitle',
      'venue': 'venue_id',
      'closed': 'closed',
      'closeInterval': 'close_interval',
      'autoclose': 'autoclose'
    };

    const dirty = Object.keys(fields).filter(f => f === 'venue' ? show.venue.id !== fields.venue.id : show[f] !== fields[f]);

    if (dirty.length) {
      for (let field of dirty) {
        (async() => {
          const value = field === 'venue' ? fields.venue.id.toString() : fields[field].toString();

          const results = await updateShow({variables: {showId: show.id, field: fieldMap[field], value}});

          if (results.error) {
            errorMsg(results, 'show', 'update');
          } else {
            show[field] = fields[field];
            success('Show Updated', `Updated ${msgMap[field]} successfully.`);
          } 
        })();
      }
    } else {
      notice('Hmmm', 'Nothing to update');
    }
  }, [cap, endTime, show, subtitle, tz, closed, autoclose, closeInterval, updateShow, venue, startTime]);

  const handleTz = (payload) => {
    setTz(payload.tz);
  };

  const handleVenue = (payload) => {
    setVenue({id: payload.venue, name: payload.venueName});
  };

  const handleDelete = async() => {
    const results = await deleteShow({variables: { id: show.id }});
    if (results.data.deleteShow) {
      success('Deleted', 'Show was deleted.');
      refetch();
    } else {
      fail('Uh oh!', 'Unable to delete show.');
    }
  };

  const cardTitleDate = (time) => {
    const d = dayjs(time);
    return d.format('ddd MMM D, YYYY @ h:mm A');
  };

  return (
    <Card my={10} withBorder shadow="sm" padding="lg">
      <Group position="apart">
        <Title order={3} mb={10} onClick={toggle} style={{cursor: 'pointer'}}>{subtitle || cardTitleDate(+startTime)}</Title>
        <Burger opened={opened} mb={10} onClick={toggle} aria-label={opened ? 'Close show panel' : 'Open show panel'} />
      </Group>
      <Collapse in={opened}>
        <SimpleGrid cols={2} mb={15}>
          <Card withBorder padding="md" shadow="sm">
            <Title order={3} mb={10}>Title</Title>
            <Text mb={10}>Is there a specific title for this show? (optional)</Text>
            <TextInput
              value={subtitle}
              onChange={(e) => setSubtitle(e.target.value)}
            />
          </Card>        
          <Card withBorder padding="md" shadow="sm">
            <Title order={3} mb={10}>Venue</Title>
            <Text mb={10}>{venue.name}</Text>
            <VenueSearch handler={handleVenue} isWizard={false} />
          </Card>
        </SimpleGrid>
        <SimpleGrid cols={3}>
          <Card mb={20} withBorder padding="md" shadow="sm">
            <Title order={3} mb={10}>Show Time</Title>
            <DateTimePicker
              popoverProps={{ withinPortal: true }}
              mb={10}
              className={classes}
              clearable
              valueFormat="MMM DD, YYYY hh:mm A"
              label="Set date and time for start of show"
              placeholder="Pick date and time"
              maw={400}
              size="md"
              value={startTime ? new Date(+startTime) : undefined}
              onChange={setStartTime}
              mx="auto"
              timeInputProps={{ 'aria-label': 'Pick time', label: 'Time' }}
              submitButtonProps={{mt: '1.65rem'}}
            />
            <DateTimePicker
              className={classes}
              popoverProps={{ withinPortal: true }}
              clearable
              valueFormat="MMM DD, YYYY hh:mm A"
              label="Set date and time for end of show (optional)"
              placeholder="Pick date and time"
              maw={400}
              size="md"
              value={endTime ? new Date(endTime) : undefined}
              onChange={setEndTime}
              mx="auto"
              timeInputProps={{ 'aria-label': 'Pick time', label: 'Time' }}
              submitButtonProps={{mt: '1.65rem'}}
            />
          </Card>
          <Card mb={20} withBorder padding="md" shadow="sm" className={classes} >
            <Title order={3} mb={10}>Timezone</Title>
            <TZSelect id={tz} handler={handleTz} />
          </Card>
          <Card mb={20} withBorder padding="md" shadow="sm" className={classes} >
            <Title order={3} mb={10}>Capacity</Title>
            <Text>What is the maximum attendance for this show?</Text>
            <NumberInput
              hideControls
              size="lg"
              value={cap}
              icon={<IconArmchair />}
              onChange={setCap}
              min={1}
              placeholder="Seating capacity"
            />
         </Card>
        </SimpleGrid>
        <Card mb={20} withBorder padding="md" shadow="sm" className={classes}>
          <Title order={3}>Close Sales</Title>
          <Text mb={10} color="dimmed">Show sales auto-close when show starts.</Text>
          <Group position="apart">
            <Checkbox 
              mb={10}
              color={"orange"}
              size="lg" 
              label="Close show sales"
              checked={closed}
              onChange={(e) => setClosed(e.currentTarget.checked)}
            />
            <Checkbox
              mb={10}
              size="lg"
              color={"orange"}
              label="Auto-close before show starts?"
              checked={autoclose}
              onChange={(e) => setAutoclose(e.currentTarget.checked)}
            />
            <NumberInput
              mb={10} 
              disabled={!autoclose}
              label="Interval before show to auto-close (in hours)"
              value={closeInterval}
              onChange={setCloseInterval}
            />
          </Group>
        </Card>
        <Group position="right">
          <Button color="orange" mr={5} onClick={handleUpdate}>Update Show</Button>
          <Button color="red" onClick={handleDelete}>Delete Show</Button>
        </Group>
      </Collapse>
   </Card>
  )
}
