import { useState, useEffect } from 'react';
import { useWindowEvent, useMediaQuery } from '@mantine/hooks';
import { 
  createStyles, 
  Header, 
  Container, 
  Group, 
  Burger, 
  Transition, 
  Paper,
  rem 
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import Logo from '../../components/Logo';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../components/AuthProvider';
import { IconCalendar, IconClockEdit, IconHelp, IconHome, IconLayoutDashboard, IconUser, IconVocabulary } from '@tabler/icons-react';

const HEADER_HEIGHT = rem(60);

const useStyles = createStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
  },

  links: {
    [theme.fn.smallerThan('xs')]: {
      display: 'none',
    },
  },

  burger: {
    [theme.fn.largerThan('xs')]: {
      display: 'none',
    },
  },

  link: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: 2,
    padding: '8px 12px',
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },

  dropdown: {
    position: 'absolute',
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 2,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
    overflow: 'hidden',

    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },
  linkActive: {
    '&, &:hover': {
      backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
      color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
    },
  },
  icon: {
    marginRight: '10px'
  }
}));

export default function LPHeader() {
  const [opened, { toggle }] = useDisclosure(false);
  const [active, setActive] = useState(window.location.pathname);
  const { classes, cx } = useStyles();
  const [loc, setLoc] = useState(useLocation());
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width: 50em)");

  useEffect(() => {
    if (opened && loc !== location) {
      toggle();
      setLoc(location);
    }
  }, [loc, location, opened, toggle]);

  useWindowEvent('scroll', () => {
    if (isMobile && opened) toggle();
  })

  const links = [
    {
        label: "Home",
        link: "/",
        external: false,
        icon: <IconHome className={classes.icon} />
    },
    {
        label: "Our Story",
        link: "/our-story",
        external: false,
        icon: <IconVocabulary className={classes.icon} />
    },
    {
        label: "What's On",
        link: "/events",
        external: false,
        icon: <IconCalendar className={classes.icon} />
    },
    {
        label: "Create an Event",
        link: "/create-event",
        external: false,
        icon: <IconClockEdit className={classes.icon} />
    },
    { 
      label: 'FAQ',
      link: 'https://help.plainstage.com/docs/faq',
      external: true,
      icon: <IconHelp className={classes.icon} />
    },
    {
        label: "Sign In",
        link: "/login",
        external: false,
        icon: <IconUser className={classes.icon} />
    },
    {
      label: 'Dashboard',
      link: "/app/dashboard",
      external: false,
      icon: <IconLayoutDashboard className={classes.icon} />
    },
  ];

  const nav = links.map((link) => {
    if (link.label === 'Sign In' && isAuthenticated) { return null; }
    if (link.label === 'Dashboard' && !isAuthenticated) { return null; }
    const path = window.location.pathname.split('/').pop();
    if (link.link.replace('/', '') === path) { return null; }
    return (<Link
      key={link.label}
      to={link.link}
      target={link.external ? "_blank" : "_self"}
      className={cx(classes.link, { [classes.linkActive]: active === link.link })}
      onClick={() => setActive(link.link)}
    >
      {isMobile && link.icon} {link.label}
    </Link>
  )});
  
  return (
    <Header height={60} mb={10} mt={10}>
      <Container className={classes.header}>
        <Link to='/'>
          <Logo size="lg" />
        </Link>
        <Group spacing={5} className={classes.links}>
          {nav}
        </Group>

        <Burger opened={opened} onClick={toggle} className={classes.burger} size="md" />
        <Transition transition="pop-top-right" duration={200} mounted={opened}>
          {(styles) => (
            <Paper className={classes.dropdown} withBorder style={styles}>
              {nav}
            </Paper>
          )}
        </Transition>
      </Container>
    </Header>
  );
}