import { useReducer } from 'react';
import {
  Modal,
  Grid,
  Stack,
  Button,
  Image,
} from '@mantine/core';
import { BuyTicket } from "./BuyTicket";
import OrderSummary from './OrderSummary';
import CheckoutForm from './CheckoutForm';
import { useAuth } from '@/components/AuthProvider';

export default function DonationModal({ evt, opened, close }) {
  const { isMobile } = useAuth();
  const [displayOpts, setDisplayOpts] = useReducer((prev, next) => {
    const newStatus = {...prev, ...next};

    return newStatus;
  }, {stripe: false, checkout: true, checkoutBtn: false, final: false});
  const [ticketData, setTicketData] = useReducer((prev, next) => {
    const newStatus = {...prev, ...next};

    return newStatus;
  }, { name: '', email: '', donation: 0, show: 0, fees: 0, tickets: {}, subtotal: 0, total: 0});

  const storeData = () => {
    const ls = window.localStorage;
    const { name, email, donation } = ticketData;

    ls.setItem('name', name);
    ls.setItem('email', email);
    ls.setItem('donation', donation);
    setDisplayOpts({stripe: true});
  };

  return (
    <Modal opened={opened} onClose={close} title={<strong>Make a Donation to {evt.title}</strong>} size="75%" fullScreen={isMobile}>
      <Grid>
        <Grid.Col md={8} span={12}>
          {isMobile && <Button onClick={close} mb={20} size="sm" compact color="gray">&times; Close Window</Button>}
          {displayOpts.checkout ? 
            displayOpts.stripe ? 
              <BuyTicket evt={evt} ticketData={ticketData} display={setDisplayOpts} />
                :
                  <CheckoutForm evt={evt} ticketData={ticketData} setTicketData={setTicketData} setDisplayOpts={setDisplayOpts} storeData={storeData} isMobile={isMobile} />
                  : null
          }
      </Grid.Col>
      <Grid.Col md={4} span={12}>
        <Stack>
          {!isMobile && <Image mt={displayOpts.final ? '7.5em' : 0} src={evt.cardImage ? evt.cardImage : evt.image} />}
          {!displayOpts.final && <OrderSummary evt={evt} ticketData={ticketData} displayOpts={displayOpts} />}
        </Stack>
      </Grid.Col>
    </Grid>
  </Modal>
  )
}