import { useRouteError } from "react-router-dom";
import {
  Container,
  Text,
  List
} from '@mantine/core';

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <Container>
      <Text mb={10} fz='1.3em'>Oh dear.</Text>
      <Text mb={10}>It would appear that something unexpected has happened.</Text>
      <Text>Here's some things that might fix it:</Text> 
      <List>
        <List.Item>Hit the back button on your browser and try that action again.</List.Item>
        <List.Item>Click the refresh button on your browser and see if that helps.</List.Item>
        <List.Item>Email support@plainstage.com with the following information:<br />{error.statusText || error.message}</List.Item>
      </List>
    </Container>
  );
}