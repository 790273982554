import { Fragment, useState, useEffect, useCallback, useReducer } from 'react';
import { useMutation } from 'graphql-hooks';
import { EVENT_CREATE_MUTATION } from '@/network/mutations';
import { useDisclosure } from '@mantine/hooks';
import { DateTimePicker } from "@mantine/dates";
import {
  Title,
  Text,
  Center,
  Card,
  Grid,
  Group,
  Checkbox,
  TextInput,
  NumberInput,
  Button,
  Tooltip,
  Modal,
  Loader
} from '@mantine/core';
import VenueSearch from './VenueSearch';
import CategorySelect from './CategorySelect';
import TZSelect from './TZSelect';
import { IconArmchair, IconBallpen, IconCalendar, IconCoin, IconMinus, IconPlus } from '@tabler/icons-react';
import { success, fail } from '@/lib/notifications';
import { useAuth } from '@/components/AuthProvider';
import { Link } from 'react-router-dom';
import { errorMsg } from '@/lib/helpers';
import dayjs from 'dayjs';

export default function EventWizard() {
  const [opened, {open, close}] = useDisclosure(false);
  const [popOpened, {open: popOpen, close: popClose}] = useDisclosure(false);
  const [loading, setLoading] = useState(false);
  const { userId } = useAuth();
  const [createEvent] = useMutation(EVENT_CREATE_MUTATION);
  const linkStyle = {textDecoration: 'none', color: '#FF5a60', fontWeight: 'bold', fontSize: '1.2em'};
  const [evt, setEvt] = useReducer((prev, next) => {
    const newState = {...prev, ...next};
    if (next.position !== undefined) {
      newState.shows[next.position] = next.start_time;
    }
    
    if (next.venue && next.venue !== 0) {
      //Tidy up payload
      ['venueName', 'address', 'city'].forEach(f => delete newState[f]);
    }
    return newState;
  }, {title: '', start_time: '', shows: [null], category: [], price: 0, donation: false, capacity: 0, venue: 0, tz: 0});

  const doCreate = useCallback(async(data) => {
    const evnt = await createEvent({variables: data});

    if (evnt.error) {
      if (opened) close();
      setLoading(false);
      errorMsg(evnt, 'event', 'create');
    } else {
      success('Done!', 'Event created!');
      window.localStorage.removeItem('psNewEvent');
      window.localStorage.removeItem('psEvent');
      setTimeout(() => window.location = `/app/events/${evnt.data.createEvent.id}`, 2000);
    }
  }, [createEvent, opened, close, setLoading]);

 const handleCreate = async() => {
    if (evt.title === '') {
      fail('Missing data', 'Need an event title');
      return false;
    }

    if (evt.shows.length > 0 && evt.shows[0] !== null) {
      setLoading(true);
      const data = {
        title: evt.title, 
        donation: evt.donation,
        capacity: evt.capacity,
        tz: evt.tz,
        cost: evt.price,
        shows: evt.shows,
        category: evt.category,
        venueId: evt.venue
      };

      if (userId) {
        await doCreate(data);
      } else {
        window.localStorage.setItem('psNewEvent', JSON.stringify(data));
        popOpen();
      }
    } else {
      setLoading(false);
      fail('Missing data', 'You need to set a show time');
    }
  };
  
  useEffect(() => {
    const storage = window.localStorage.getItem('psNewEvent');
    
    if (storage && userId) {
      const data = JSON.parse(storage);
      open();
      doCreate(data);
    } else {
      if (storage) {
        const data = JSON.parse(storage);
        data.price = data.cost;
        data.venue = data.venueId;
        ['cost', 'venueId'].forEach(key => delete data[key]);
        data.shows = data.shows.map(show => new Date(dayjs(show).valueOf()));
        setEvt(data);
      } 
    }
  }, [doCreate, open, setEvt, userId]);

  return (
    <Card withBorder shadow="sm" padding="lg">
      <Title order={2} mb={10}>Create Your Event</Title>
      <Grid>
        <Grid.Col span={12} md={6}>
          <TextInput
            mb={5}
            size="lg"
            label="Event title"
            placeholder="Event title"
            value={evt.title}
            icon={<IconBallpen />}
            onChange={(e) => setEvt({title: e.target.value})}
          /> 
          <VenueSearch venueId={evt.venue} handler={setEvt} isWizard={true} />
          <Group mt={5} grow>
            <NumberInput
              hideControls
              size="lg"
              label="Seating capacity"
              value={evt.capacity}
              icon={<IconArmchair />}
              onChange={(e) => setEvt({capacity: e})}
              min={1}
              placeholder="Seating capacity"
            />
            <NumberInput
              hideControls
              size="lg"
              icon={<IconCoin />}
              label="Ticket cost"
              value={evt.price}
              onChange={(e) => setEvt({price: e})}
              min={0}
              disabled={evt.donation}
              placeholder="Enter ticket cost"
            />          
          </Group>
          <Group mt={10}>
            <Checkbox
              size="lg"
              color="#ff5a60"
              label="By donation"
              checked={evt.donation}
              onChange={(e) => setEvt({donation: e.currentTarget.checked})}
            />
            <Checkbox
              size="lg"
              color="#ff5a60"
              label="Free"
              checked={evt.price === 0}
              onChange={(e) => setEvt({price: 0})}
            />
          </Group>
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <CategorySelect ids={evt.category || null} handler={setEvt} />
          <TZSelect id={evt.tz || null} handler={setEvt} />
          <Grid>
          {evt.shows.map((show, p) => 
            <Fragment key={`showPicker${p}`}>
              <Grid.Col span={9}>
                <DateTimePicker
                  clearable
                  size="lg"
                  dropdownType="modal"
                  icon={<IconCalendar />}
                  valueFormat="MMM DD, YYYY hh:mm A"
                  label="Set date and time for a show"
                  placeholder="Pick date and time"
                  value={show}
                  timeInputProps={{ 'aria-label': 'Pick time', label: 'Time' }}
                  submitButtonProps={{mt: '1.65rem'}}
                  onChange={(e) => setEvt({position: p, start_time: e})}
                />
              </Grid.Col>
              <Grid.Col span={3}>
                  <Button.Group mt='2.3rem'>
                    <Tooltip label="Add show">
                      <Button 
                        size="xs" 
                        color="green" 
                        onClick={() => setEvt({shows: evt.shows.concat(null)})}><IconPlus /></Button>
                    </Tooltip>
                  {p > 0 ? 
                    <Tooltip label="Delete this show">
                      <Button 
                        size="xs" 
                        color="red" 
                        onClick={() =>  setEvt({shows: evt.shows.filter((s, i) => (i !== p) ? s : false )})}>
                          <IconMinus />
                        </Button>
                      </Tooltip> 
                    : null}
                </Button.Group>
              </Grid.Col>
            </Fragment>
          )}
          </Grid>
        </Grid.Col>
        <Grid.Col span={12} align="center">
          <Button loading={loading} size="lg" mt={20} color="#ff5a60" onClick={handleCreate}>Create Event</Button>
        </Grid.Col>
        <Grid.Col span={12} align="center">
          <Text size="sm">Want some help?</Text>
          <Text size="sm">Check out our <Link to='https://help.plainstage.com' target="_blank">Help Docs</Link>!</Text>
        </Grid.Col>
      </Grid>
      <Modal opened={popOpened} centered onClose={() => {setLoading(false); popClose();}} withCloseButton={false}>
        <Title order={3} color="#FF5a60" align='center'>You're almost there!</Title>
        <Text align="center" fw={700} my={10}>In order to create an event, you must be signed in to Plainstage. Click one of the links below.</Text>
        <Text fz="sm" align="center">(Don't worry, we saved your event info)</Text>
        <Group grow my={20}>
          <Text align="center"><Link style={linkStyle} to="/login">Login</Link></Text>
          <Text align="center"><Link style={linkStyle} to="/signup">Signup</Link></Text>
        </Group>
      </Modal>
      <Modal opened={opened} onClose={close} withCloseButton={false}>
        <Center>
          <Text fw={700} mb={15}>Creating your event....</Text>
          <Loader variant='bars' color='#EB4B51' size="xl" />
        </Center>
      </Modal>
    </Card>
  )
}
