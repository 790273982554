import { useState, useEffect } from "react";
import { useQuery } from "graphql-hooks";
import { TZ_QUERY } from "@/network/queries";
import { Select } from "@mantine/core";
import { IconWorld } from "@tabler/icons-react";
import PSLoader from "@/components/PSLoader";

export default function TZSelect({id, handler}) {
  const [value, setValue] = useState(null);
  const { loading, error, data } = useQuery(TZ_QUERY);

  useEffect(() => {
    if (id) {
      setValue(`${id}`);
    }
  }, [id, setValue]);

  if (loading) return <PSLoader />;

  if (error) return 'Error loading timezones';

  const { timezones } = data;

  return (
    <Select
      mb={5}
      size="lg"
      withinPortal
      icon={<IconWorld />}
      label="Select the timezone"
      value={value}
      placeholder="Pick one"
      data={timezones.map(tz => ({ value: tz.id, label: tz.label}))}
      onChange={(e) => {setValue(e); handler({tz: +e});}}
      searchable
      nothingFound="Nothing found"
      maxDropdownHeight={280}
    />
  )
}