import { 
    Tabs
} from "@mantine/core";
import { IconCalendarPlus, IconCalendarX } from '@tabler/icons-react';
import HomeEvents from '../dashboard/HomeEvents';
import PastEvents from "../dashboard/PastEvents";

export default function AuthedEventPage(params) {
    return (
    <Tabs color="orange" defaultValue="upcoming">
      <Tabs.List grow>
        <Tabs.Tab value="upcoming" icon={<IconCalendarPlus size="0.8rem" />}>Upcoming Events</Tabs.Tab>
        <Tabs.Tab value="past" icon={<IconCalendarX size="0.8rem" />}>Past Events</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="upcoming" pt="xs">
        <HomeEvents showTitle={false} />
      </Tabs.Panel>

      <Tabs.Panel value="past" pt="xs">
        <PastEvents showTitle={false} />
      </Tabs.Panel>

    </Tabs>
    )
}