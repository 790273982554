import { useState, useCallback, useEffect } from "react";
import { useMutation } from "graphql-hooks";
import { LOGIN_MUTATION } from "@/network/mutations";
import { isEmailValid } from "@/lib/helpers";
import Logo from "@/components/Logo";
import { useAuth } from "@/components/AuthProvider";
import { Link, Navigate } from 'react-router-dom';
import {
  Paper,
  createStyles,
  TextInput,
  PasswordInput,
  Checkbox,
  Button,
  Title,
  Center,
  Text,
  Group
} from '@mantine/core';
import { GoogleLogin } from '@react-oauth/google';
import { pickUri } from "@/lib/uris";

const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: 900,
    backgroundSize: 'cover',
    backgroundImage:
      `url(${pickUri().replace('gql', '')}images/ps-stock.jpg)`,
  },
  button: {
    backgroundColor: theme.pink, 
    color: theme.colors.white
  },
  text: {
    textDecoration: 'none',
    color: theme.pink
  },
  form: {
    borderRight: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    minHeight: 900,
    maxWidth: 450,
    paddingTop: 80,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: '100%',
    },
  },
}));

export default function Auth({ setAuth }) {
  const { classes } = useStyles();
  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ login, { error } ] = useMutation(LOGIN_MUTATION);
  const { setToken } = useAuth();
  const [ success, setSuccess ] = useState(false);

  const responseMessage = (response) => {
    const { credential } = response; 
    const token = JSON.parse(atob(credential.split('.')[1]));

    const { email } = token;
    
    login({variables: { email, password: credential, sml: true}})
      .then(handleLogin);
  };
  const errorMessage = (error) => {
      console.log("ERROR:", error);
  };

  useEffect(() => {
    if (error) {
      setPassword("");
    }
  }, [error]);
  
  const handleLogin = useCallback(({ data }) => {
    const { login } = data;
    if (login) {
      const token = login.jwt;
      localStorage.setItem("token", token);
      setToken(token);
      setSuccess(true);
    }  
  }, [setSuccess, setToken]);

  const doLogin = useCallback((e) => {
    e.preventDefault();
    if (email && password) {
      if (!isEmailValid.test(email)) {
        // do some error thingy with the text input
      }

      const loginParams = {variables: { email, password }};
      login(loginParams)
        .then(handleLogin)
        .catch((err) => {
          console.log("STB:", err);
        });
    }
  }, [email, password, login, handleLogin]);

  return success ? <Navigate to="/app/dashboard" /> : (
    <div className={classes.wrapper}>
      <Paper className={classes.form} radius={0} p={30}>
        <Title order={2} align="center" mt="md" mb={50}>
          <Link to="/">
            <Logo />
          </Link>
        </Title>

        <TextInput 
          value={email} 
          onChange={(event) => setEmail(event.currentTarget.value)} 
          label="Email address" 
          placeholder="hello@gmail.com" 
          size="md" 
          error={error ? "Invalid credentials" : null}
        />
        <PasswordInput 
          value={password} 
          onChange={(event) => setPassword(event.currentTarget.value)} 
          label="Password" 
          placeholder="Your password" 
          mt="md" 
          size="md" 
          error={error ? "Invalid credentials" : null}
        />
        <Group position="apart" mt="lg">
          <Checkbox label="Remember me" sx={{ lineHeight: 1 }} />
          <Link to="/forgot-password" style={{textDecoration: 'none', color: '#FF5A60'}}>
            Forgot password?
          </Link>
        </Group>
        <Button onClick={doLogin} fullWidth mt="xl" size="md" className={classes.button}>
          Login
        </Button>

        <Text align="center" my="md">
          Don&apos;t have an account?{' '}
          <Link to="/signup" style={{textDecoration: 'none', color: '#FF5A60'}}>
            Register
          </Link>
        </Text>
        <Center mt={15}>
          <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
        </Center>
      </Paper>
    </div>
  );
}
