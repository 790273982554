import { useState, useEffect } from "react";

export function decodedToken(jwt) {
    if (typeof jwt !== 'string') return null;

    const parts = jwt.split('.');
    if (parts.length !== 3) return null;

    return JSON.parse(window.atob(parts[1]));
}

export function isExpired(jwt) {
    const decoded = decodedToken(jwt);
    let result = false;

    if (decoded && decoded.exp) {
        const expDate = new Date(0);
        expDate.setUTCSeconds = decoded.exp;
        result = expDate.valueOf() < new Date().valueOf();
    }

    return result;
}

function _getCookieByName(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
};

function _getStorage(storage, name) {
    return window[storage].getItem(name);
}

function _getToken(storage, name) {
    return (storage === 'cookie') ? _getCookieByName(name) : _getStorage(storage, name);
}

//Valid values for storage are 'cookie', 'localStorage', and 'sessionStorage'
function _isValidStorage(storage) {
    const values = ["cookie", "localStorage", "sessionStorage"];
    return values.includes(storage);
}

const useJwt = (storage = "localStorage", name = "token") => {
    const _token = _getToken(storage, name);

    const [token, setToken] = useState(_token);

    useEffect(() => {
        setToken(_getToken(storage, name));
    }, [name, storage]);

    if (!_isValidStorage(storage)) {
        console.error("Invalid value for useJwt storage mechanism.\nValid values are cookie, localStorage, sessionStorage\nYou supplied:", storage);
        return null;
    }

    return {
        token,
        decodedToken: decodedToken(token),
        isExpired: isExpired(token)
    };
}

export default useJwt;