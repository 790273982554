import { 
  Paper, 
  Title,
  Button,
  createStyles
} from "@mantine/core";
import Show from "./Show";
import { useMutation } from "graphql-hooks";
import { CREATE_SHOW_MUTATION } from "../../network/mutations";

const useStyles = createStyles((theme) => ({
  sectionTitle: {
    color: "#EB4B51"
  }
}));

export default function Shows({evt, venues, shows, refetch}) {
  const { classes } = useStyles();
  const [createShow] = useMutation(CREATE_SHOW_MUTATION);

  const showShows = Boolean(shows && shows.length);

  const newShow = () => {
    createShow({variables: {eventId: evt.id, subtitle: 'New Show'}, onSuccess: refetch});
  }

  return (
    <Paper shadow="sm" p="md">
      <Button color="orange" onClick={newShow}>+ Add Show</Button>
      {showShows && shows.map(show => <Show key={`show-${show.id}`} show={show} venues={venues} refetch={refetch} />)}
      {!showShows && (<Title className={classes.sectionTitle} order={3}>You have no shows for this event.</Title>)}
    </Paper>
  )
}
