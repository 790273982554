import { PinInput, Group } from '@mantine/core';
import { useManualQuery } from 'graphql-hooks';
import { SIGNUP_CODE_QUERY } from '../network/queries';

export default function SignupCode({id, setSuccess}) {
  const [checkCodeQuery, { error }] = useManualQuery(SIGNUP_CODE_QUERY)

  const checkCode = async (code) => {
    const result = await checkCodeQuery({variables: { id, code }})
    setSuccess(result.data.signupCode); 
  };

  return (
    <Group position="center">
      <PinInput length={6} type="number" error={error} oneTimeCode onComplete={checkCode} />
    </Group>
  );
}