import { useState } from "react";
import Logo from "@/components/Logo";
import { Link, Navigate } from 'react-router-dom';
import {
  Paper,
  createStyles,
  Title,
  Text
} from '@mantine/core';
import { useAuth } from "@/components/AuthProvider";
import SignupCode from "@/components/SignupCode";
import { pickUri } from "@/lib/uris";


const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: 900,
    backgroundSize: 'cover',
    backgroundImage:
      `url(${pickUri().replace('gql', '')}images/ps-stock.jpg)`,
  },
  button: {
    backgroundColor: theme.pink, 
    color: theme.colors.white
  },
  text: {
    textDecoration: 'none',
    color: theme.pink
  },
  form: {
    borderRight: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    minHeight: 900,
    maxWidth: 450,
    paddingTop: 80,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: '100%',
    },
  },
}));

export default function VerifyCode() {
  const { userId } = useAuth();
  const { classes } = useStyles();
  const [ success, setSuccess ] = useState(false);
  const newEvent = window.localStorage.getItem('psNewEvent');

  return success ? <Navigate to={newEvent ? "/create-event" : "/app/dashboard"} /> : (
    <div className={classes.wrapper}>
      <Paper className={classes.form} radius={0} p={30}>
        <Title order={2} align="center" mt="md" mb={50}>
          <Link to="/">
            <Logo />
          </Link>
        </Title>

        <Title mb={25}>
          Verify your Account
        </Title>
        <Text>Please enter the code that was sent to your e-mail.</Text>
        <Text color="dimmed">Check your spam/junk folder if it doesn't arrive in your inbox.</Text>
        <SignupCode id={userId} setSuccess={setSuccess} />
      </Paper>
    </div>
  );
}
