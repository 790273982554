import {
    createStyles, 
    Title,
    Image,
    Container,
    Grid,
} from '@mantine/core';

const useStyles = createStyles((theme) => ({
  container: {
    backgroundColor: 'cornflowerblue',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    zIndex: 1,
    position: 'relative',
    padding: '3em 0',

    [theme.fn.smallerThan('sm')]: {
      paddingBottom: theme.spacing.xl * 3,
    },
  },
  col: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  img: {
    maxWidth: '90%'
  }
}));

export default function WorkWith({ isMobile }) {
  const { classes } = useStyles();
    
  return (
      <div className={classes.container}>
          <Container>
            <Title my={20} color="white">Partnered With</Title>
            <Grid mb={20} gutter="lg">
              <Grid.Col className={classes.col} span={4} md={2}>
                <Image className={classes.img} src="/img/lp/lilix.png" radius="md" alt="Lilix Media" />
              </Grid.Col>
              <Grid.Col className={classes.col} span={4} md={2}>
                <Image className={classes.img} src="/img/lp/nowadays.png" radius="md" alt="Nowadays Theatre" />
              </Grid.Col>
              <Grid.Col className={classes.col} span={4} md={2}>
                <Image className={classes.img} src="/img/lp/artsclub.png" radius="md" alt="Arts Club" />
              </Grid.Col>
              <Grid.Col className={classes.col} span={4} md={2}>
                <Image className={classes.img} src="/img/lp/waterworks.png" radius="md" alt="Waterworks" />
              </Grid.Col>
              <Grid.Col className={classes.col} span={4} md={2}>
                <Image className={classes.img} imageProps={{style: { filter: 'grayscale(1)'}}} src="/img/lp/promethean.png" radius="md" alt="Waterworks" />
              </Grid.Col>
              <Grid.Col className={classes.col} span={4} md={2}>
                <Image className={classes.img} src="/img/lp/offtherail.png" imageProps={{style: { width: '80%' }}} radius="md" alt="Off the Rail Brewing" />
              </Grid.Col>
            </Grid>
          </Container>
      </div>
  )
}