import { useCallback } from 'react';
import {
  Box,
  TextInput,
  NumberInput,
  Select,
  Button
} from '@mantine/core';
import { useForm } from "@mantine/form";
import { useManualQuery } from 'graphql-hooks';
import { TICKET_MUTATION } from '../../network/mutations';

export default function CompTicket({shows, tiers, close, worked}) {
  const [makeTicket] = useManualQuery(TICKET_MUTATION);
  const form = useForm({
    initialValues: {
      name: '',
      email: '',
      show: 0,
      num: 0,
      tier: 0
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  const genComp = useCallback(() => {
    const { name, email, show, num, tier } = form.values;

    if (name !== '' && email !== '' && show !== 0 && num > 0 && tier !== 0) {
      const tix = Array(num);
      tix.fill({tier: "", tierId: tier, donation: false, showId: show, fees: 0, price: 0});
      const tickets = JSON.stringify(tix);
      makeTicket({
        variables: { name, email, tickets, comp: true }, 
        onSuccess: () => {
          worked({ success: true, num });
          close();
        }
      });
    }
  }, [form.values, makeTicket, close, worked]);

  return (
    <Box>
      <TextInput 
        label="Attendee Name"
        placeholder="Sally Smith"
        {...form.getInputProps('name')}
      />
      <TextInput
        label="Attendee E-mail"
        type="email"
        placeholder="someone@somesite.com"
        {...form.getInputProps('email')}
      />
      <Select
        label="Show"
        data={shows}
        withinPortal
        placeholder="Select a show"
        {...form.getInputProps('show')}
      />
      <Select
        label="Ticket Tier"
        data={tiers}
        withinPortal
        placeholder="Select a ticket tier"
        {...form.getInputProps('tier')}
      />
      <NumberInput
        label="# of Tickets"
        placeholder="How many tickets?"
        {...form.getInputProps('num')}
      />
      <Button.Group mt={20} position="right">
        <Button size="xs" mr={5} onClick={close} color="red">Cancel</Button>
        <Button size="xs" onClick={genComp} color="orange">Create</Button>
      </Button.Group>
    </Box>
  );
}