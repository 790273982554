import { useQuery } from "graphql-hooks";
import { HOME_TICKET_QUERY } from "../../network/queries";
import { 
  Group, 
  Title, 
  createStyles 
} from "@mantine/core";
import Ticket from "../../components/Ticket";
import { useAuth } from "../../components/AuthProvider";
import PSLoader from "@/components/PSLoader";

const useStyles = createStyles(theme => ({
  sectionTitle: {
    color: "#EB4B51", //theme.colors.pink[4]
  },
}));

export default function PastTickets({ showTitle }) {
  const { userId } = useAuth();
  const { classes } = useStyles();
  const past = true;

  const { loading, data, error } = useQuery(HOME_TICKET_QUERY, {
    variables: { userId, past },
  });

  if (loading) return <PSLoader />;
  if (error) return "ERROR FETCHING TICKETS";

  const tickets = data?.tickets;
  const showTickets = Boolean(tickets && tickets.length);
  const collated = {};

  if (showTickets) {
    tickets.forEach(ticket => {
      if (collated[ticket.show.id]) {
        collated[ticket.show.id].push(ticket);
      } else {
        collated[ticket.show.id] = [ticket];
      }
    });
  }

  return (
    <>
      {showTitle && (
        <Title className={classes.sectionTitle} mb={10}>
          Tickets
        </Title>
      )}
      {showTickets && (
        <Group grow>
          {Object.keys(collated).map(showId => (<Ticket key={`ticket${showId}`} tickets={collated[showId]} />))}
        </Group>
      )}
      {!showTickets && (
        <Title order={3}>You have never purchased tickets.</Title>
      )}
    </>
  );
}
