import { useState, useEffect } from "react";
import { useQuery } from "graphql-hooks";
import { CATEGORY_QUERY } from "@/network/queries";
import { MultiSelect } from "@mantine/core";
import { IconCategory } from "@tabler/icons-react";
import PSLoader from "@/components/PSLoader";

export default function CategorySelect({ids, handler}) {
  const [value, setValue] = useState(null);
  const { loading, error, data } = useQuery(CATEGORY_QUERY);

  useEffect(() => {
    if (ids) {
      setValue(ids);
    }
  }, [ids, setValue]);

  if (loading) return <PSLoader />;

  if (error) return 'Error loading categories';

  const { categories } = data;

  return (
    <MultiSelect
      mb={5}
      size="lg"
      withinPortal
      icon={<IconCategory />}
      label="Select the category for your event"
      placeholder="Pick up to three"
      maxSelectedValues={3}
      value={value}
      data={categories.map(c => ({ value: c.id, label: c.name}))}
      onChange={(e) => {setValue(e); handler({category: e});}}
      searchable
      nothingFound="Nothing found"
      maxDropdownHeight={280}
    />
  )
}