import { useState, useEffect } from "react";
import { SearchBox } from '@mapbox/search-js-react';
import { pickMapbox } from "@/lib/uris";
import { useManualQuery, useMutation } from 'graphql-hooks';
import { CREATE_VENUE_MUTATION } from '@/network/mutations';
import { VENUE_SEARCH_QUERY } from "@/network/queries";
import { success } from "@/lib/notifications";
import { errorMsg } from "@/lib/helpers";

export default function VenueSearch({venueId, handler, isWizard}) {
  const [value, setValue] = useState('');
  const [createVenue] = useMutation(CREATE_VENUE_MUTATION);
  const [venueSearch] = useManualQuery(VENUE_SEARCH_QUERY);

  useEffect(() => {
    const box = document.querySelector('input[role="combobox"]');
    box.style.height = '3.125rem';
  }, []);

  useEffect(() => {
    if (+venueId) {
      (async() => {
        const results = await venueSearch({variables: {id: +venueId}});
        
        if (results.data) {
          const { venueSearch } = results.data;

          setValue(venueSearch?.name || null);
        }
      })();
    }
  }, [venueId, venueSearch]);
  
  const addVenue = async (data) => {
    const results = await createVenue({ variables: data });
    if (results.error) {
      errorMsg(results, 'venue', 'add');
    } else {
      handler({venue: results.data.createVenue.id, venueName: data.name, address: data.address, city: data.city});
      if (!isWizard) {
        success('Set!', 'Venue set successfully.');
      }
    } 
  };

  const handlePick = async(res) => {
    const place = res.features[0].properties;
    let street_address, secondary, stprv;

    const { mapbox_id, coordinates, name } = place;
    const { address, country, district, place: city, postcode, region } = place.context;

    if (/-/.test(address.address_number)) {
      [secondary, street_address] = address.name.split('-');
    } else {
      street_address = address.name;
    }

    if (region) {
      stprv = region.region_code;
    }

    //First see if we already have it in the db
    const existing = await venueSearch({variables: { id: mapbox_id }});

    if (existing.data.venueSearch?.id) {
      handler({venue: existing.data.venueSearch.id, venueName: name, address: street_address});
      if (!isWizard) {
        success('Set!', 'Venue set successfully.');
      }
    } else {
      const data = {
        country: country.country_code,
        stprv,
        name,
        secondary,
        city: city.name,
        mapbox_id,
        address: street_address,
        postal: postcode.name,
        lat: coordinates.latitude,
        lng: coordinates.longitude,
      }

      if (district) {
        data.district = district.name;
      }

      addVenue(data);
    }
    
  };

  const labelStyles = {
    display: 'inline-block',
    fontSize: '1.125rem',
    fontWeight: 500,
    color: 'rgb(33, 37, 41)',
    wordBreak: 'break-word',
    cursor: 'default',
    marginBottom: '2px'
  };

  return (
    <>
      <label className="mantine-InputWrapper-label mantine-TextInput-label" style={labelStyles}>Venue (leave blank if your event is online)</label>
      <SearchBox 
          accessToken={pickMapbox()} 
          placeholder="Search for your venue"
          value={value}
          onChange={setValue}
          onRetrieve={handlePick}
          theme={{variables: { 
            unit: '1.125rem'
          }}}
        />
      </>
  )
}