import { MantineProvider } from '@mantine/core';
import { AuthProvider } from "../components/AuthProvider";
import { brandColours } from '../lib/helpers';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { pickGoogle } from "../lib/uris";

export default function OuterApp({children}) {
  const google = pickGoogle();

  return ( 
    (<MantineProvider
      withGlobalStyles 
      withNormalizeCSS 
      theme={{
        fontFamily: 'Roboto, sans-serif',
        colors: brandColours, 
        primaryColor: 'pink', 
        primaryShade: 4
      }}
    >
      <GoogleOAuthProvider clientId={google.client_id}>
        <AuthProvider>
          {children}
        </AuthProvider>
      </GoogleOAuthProvider>
    </MantineProvider>
  ));
}
