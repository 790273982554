import { useState } from "react";
import { Modal, TextInput, Text, Button } from "@mantine/core";
import { IconMail } from "@tabler/icons-react";
import { useMutation } from "graphql-hooks";
import { success, fail } from "../lib/notifications";
import { SEND_TICKETS_MUTATION } from "../network/mutations";

export default function SendTicketModal({ opened, e, id, showId, close }) {
  const [email, setEmail] = useState(e);
  const [sendTickets] = useMutation(SEND_TICKETS_MUTATION);
  const doSend = (e) => { 
    e.stopPropagation();
    const handleSend = (results) => {
      close();
      if (results.data) {
        const { sendTickets } = results.data;

        if (sendTickets) {
          success('Sent!', `Tickets were sent to ${email}`);
        } else {
          fail('Hmm...', 'There was a problem sending the tickets. Please try again in a few minutes.');
        }
      } else {
        fail('Hmm...', 'There was a problem sending the tickets. Please try again in a few minutes.');
      }
    };

    sendTickets({variables: { userId: id, showId: showId, email }, onSuccess: handleSend});
  };

  return (
    <Modal opened={opened} onClose={close} title="Send Tickets">
      <Text mb={20}>Please enter the e-mail address to which you would like the tickets sent.</Text>
      <TextInput
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter an e-mail address"
        mb={20}
      />
      <Button color="orange" onClick={doSend} leftIcon={<IconMail />}>Send Tickets</Button>
    </Modal>
  );
}