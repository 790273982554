import { useQuery } from "graphql-hooks";
import { HOME_EVENT_QUERY } from "../../network/queries";
import { 
  Group, 
  Paper, 
  Title,
  createStyles
} from "@mantine/core";
import { useAuth } from "../../components/AuthProvider";
import Event from "../../components/Event";

const useStyles = createStyles((theme) => ({
  sectionTitle: {
    color: "#EB4B51"
  }
}));

export default function PastEvents({showTitle}) {
  const { userId } = useAuth();
  const { classes } = useStyles();

  const { data, error } = useQuery(HOME_EVENT_QUERY, {variables: { userId: userId, past: true }});

  if (error) { return "ERROR LOADING EVENTS"; }

  const events = data?.events;

  const showEvents = Boolean(events && events.length);

  return (
    <Paper shadow="sm" p="md">
      {showTitle && <Title className={classes.sectionTitle} mb={10}>Past Events</Title>}
      {showEvents && (
        <Group>
          {events.map(evt => <Event evt={evt} lp={false} />)}
        </Group>
      )}
      {!showEvents && (<Title order={3}>You have not created any events.</Title>)}
    </Paper>
  )
}
