import { IconLogout, IconHome, IconMasksTheater, IconTicket, IconSettings2, IconClockEdit, IconTool } from '@tabler/icons';
import { ThemeIcon, UnstyledButton, Group, Text } from '@mantine/core';
import { Link } from 'react-router-dom';
import { useAuth } from '@/components/AuthProvider';
import { IconHelp } from '@tabler/icons-react';

function MainLink({ icon, color, label, to, external }) {
    return (
      <Link style={{textDecoration: 'none'}} to={to} target={external ? "_blank" : "_self"}>
        <UnstyledButton
          sx={(theme) => ({
            display: 'block',
            width: '100%',
            padding: theme.spacing.xs,
            borderRadius: theme.radius.sm,
            color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
    
            '&:hover': {
              backgroundColor:
                theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
              fontWeight: 700
            },
          })}
        >
          <Group>
            <ThemeIcon color={color} variant="light">
              {icon}
            </ThemeIcon>
    
            <Text size="sm">{label}</Text>
          </Group>
        </UnstyledButton>
      </Link>
    );
  }
  
  const data = [
    { icon: <IconHome size={16} />, color: 'blue', label: 'Dashboard', to: '/app/dashboard', external: false},
    { icon: <IconMasksTheater size={16} />, color: 'teal', label: 'Events', to: '/app/events', external: false },
    { icon: <IconClockEdit size={16} />, color: 'pink', label: 'Create Event', to: '/app/create-event', external: false },
    { icon: <IconTicket size={16} />, color: 'violet', label: 'Tickets', to: '/app/tickets', external: false },
    { icon: <IconSettings2 size={16} />, color: 'grape', label: 'Settings', to: '/app/settings', external: false },
    { icon: <IconHelp size={16} />, color: 'blue', label: 'Help Pages', to: 'https://help.plainstage.com', external: true},
    { icon: <IconLogout size={16} />, color: 'gray', label: 'Logout', to: '/logout', external: false }
  ];
  
  export function MainLinks() {
    const { admin } = useAuth();

    if (admin) {
      //Put it in right before the last one
      data.splice(data.length - 1, 0, { icon: <IconTool size={16} />, color: 'orange', label: 'Admin', to: '/app/admin', external: false});
    }

    const links = data.map((link) => <MainLink {...link} key={link.label} />);
    return <div>{links}</div>;
  }
  