import {
  Table,
  Popover,
  Text,
  Button
} from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';
import { convertDate } from '@/lib/helpers';

export default function Logins({ data }) {

  const rows = data.map(login => (
    <tr>
      <td>{convertDate(login.at)}</td>
      <td>{login.sso ? "SSO" : "Email/Pw"}</td>
      <td>{login.success ? <IconCheck color="green" /> : <IconX color="red" />}</td>
      <td>
        <Popover width={300} position="bottom" withArrow shadow="md">
          <Popover.Target>
            <Button color="orange">More Info</Button>
          </Popover.Target>
          <Popover.Dropdown>
            <Text size="sm" mb="md"><strong>Client: </strong> {login.client}</Text>
            <Text size="sm" mb="md"><strong>IP: </strong>{login.ip}</Text>
            {!login.sso && <Text size="sm"><strong>PW Version: </strong>{login.pwv}</Text>}
          </Popover.Dropdown>
        </Popover>
      </td>
    </tr>
  ));

  return (
    <Table>
      <thead>
        <tr>
          <th>Timestamp</th>
          <th>Type</th>
          <th>Success</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </Table>
  )
}