import { useState } from 'react';
import { 
    createStyles, 
    TextInput, 
    rem 
} from '@mantine/core';

const useStyles = createStyles((theme) => ({
    root: {
      position: 'relative',
    },
    input: {
      height: rem(54),
      paddingTop: rem(18),
      marginBottom: rem(10)
    },
    label: {
      position: 'absolute',
      pointerEvents: 'none',
      fontSize: theme.fontSizes.xs,
      paddingLeft: theme.spacing.sm,
      paddingTop: `calc(${theme.spacing.sm} / 2)`,
      zIndex: 1,
    },
}));

export default function UserInput({label, placeholder, value, field, handler}) {
    const { classes } = useStyles();
    const [contents, setContents] = useState(value);
    const type = 'user';

    const handleUpdate = (e) => {
        if (e.target.value === value) return false;
        if (e.target.value !== '') {
            handler(field, e.target.value, type);
        }    
    };

    return (
        <>
            <TextInput 
                label={label} 
                placeholder={placeholder} 
                value={contents} 
                onChange={(e) => setContents(e.target.value)} 
                onBlur={handleUpdate} 
                classNames={classes} 
            />
        </>
    );
}