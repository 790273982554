import {
  Title,
  Text,
  Center,
  Card
} from '@mantine/core';

export default function Paused() {
  return (
    <Center>
      <Card withBorder shadow="sm" padding="lg">
        <Title>Stay tuned!</Title>
        <Text>At this time, Plainstage is pausing the creation of new events, as we about about to launch a new and exciting set of features. Please stay tuned. If you have any questions, please contact support@plainstage.com.</Text>
      </Card> 
    </Center>
  )
}