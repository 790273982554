import { useEffect, useReducer } from 'react';
import { 
  Table,
  Card,
} from '@mantine/core';
import { formatCurrency } from '@/lib/helpers';

export default function Reports({ events, users }) {
  const [ data, setData ] = useReducer((prev, next) => {
    const newState = {...prev, ...next};

    return newState;
  }, {events: 0, users: 0, tickets: 0, revenue: 0, payouts: 0, profit: 0});

  useEffect(() => {
    const published = events.filter(e => e.published).length;
    const u = users.length;
    const tickets = users.reduce((t, u) => t + u.tickets.filter(tx => !tx.refunded).length, 0);
    const revenue = users.reduce((t, u) => t + u.tickets.reduce((tot, tik) => tot + (tik.tier?.price || 0), 0), 0);
    const payouts = users.reduce((t, u) => t + u.payments.reduce((tot, p) => tot + (p?.amount || 0), 0), 0);
    const profit = revenue - payouts;
    setData({events: published, users: u, tickets, revenue, payouts, profit});
  }, [ events, users ]);

  return (
    <Card mb={20} padding="lg" shadow="sm" withBorder>  
      <Table striped highlightOnHover>
        <thead>
          <tr>
            <th>Total Events</th>
            <th>Total Users</th>
            <th>Total Tickets Sold</th>
            <th>Total Revenue</th>
            <th>Total Payouts</th>
            <th>Total Profit</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{data.events}</td>
            <td>{data.users}</td>
            <td>{data.tickets}</td>
            <td>{formatCurrency(data.revenue, 'CAD')}</td>
            <td>{formatCurrency(data.payouts, 'CAD')}</td>
            <td>{formatCurrency(data.profit, 'CAD')}</td>
          </tr>
        </tbody>
      </Table>
    </Card>
  )
}