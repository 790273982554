export const LOGIN_MUTATION = ` 
  mutation login($email: String!, $password: String!, $sml: Boolean) {
    login(email: $email, password: $password, sml: $sml) {
      id
      jwt
      admin
    }
  }
`;

export const TICKET_MUTATION = `
  mutation makeTicket($name: String!, $email: String!, $tickets: String!, $donation: Float, $comp: Boolean, $stripe: String, $promo: String) {
    makeTicket(name: $name, email: $email, tickets: $tickets, donation: $donation, comp: $comp, stripe: $stripe, promo: $promo) {
      id
      purchaseDate
      event { 
        title
        slug
      }
      show {
        id
        subtitle
        startTime
      }
    }
  }
`;

export const DONATION_MUTATION = `
  mutation makeDonation($name: String!, $email: String!, $donation: Float, $stripe: String, $eventId: ID) {
    makeDonation(name: $name, email: $email, donation: $donation, stripe: $stripe, eventId: $eventId) 
  }
`;

export const SIGNUP_MUTATION = ` 
  mutation userSignup($email: String!, $password: String!) {
    signup(email: $email, password: $password) {
      id
      jwt
    }
  }
`;

export const CONTACT_MUTATION = `
  mutation contact($name: String!, $email: String!, $subject: String, $message: String!) {
    contact(name: $name, email: $email, subject: $subject, message: $message) 
  }
`;

export const EVENT_CREATE_MUTATION = `
  mutation CreateEvent($title: String!, $category: [ID], $cost: Float, $donation: Boolean, $tz: ID, $capacity: Int!, $venueId: ID, $shows: [String]) {
    createEvent(title: $title, category: $category, cost: $cost, donation: $donation, tz: $tz, capacity: $capacity, venueId: $venueId, shows: $shows) {
      id
    }
  }
`;

export const EVENT_WIZARD_MUTATION = `
  mutation UpdateEvent($id: ID!, $field: String!, $value: String!) {
    updateEvent(id: $id, field: $field, value: $value) 
  }
`;

export const FORGOT_PW_MUTATION = `
  mutation ForgotPw($email: String!) {
    forgotPw(email: $email)
  }
`;

export const HANDLE_FORGOTPW_MUTATION = `
  mutation HandleForgotPw($code: String!, $email: String!, $newPw: String!) {
    handleForgotPw(code: $code, email: $email, newPw: $newPw) {
      jwt
    }
  }
`;

export const UPDATE_PROFILE_MUTATION = `
  mutation UpdateProfile($id: ID!, $field: String!, $value: String!, $type: String!) {
    updateProfile(id: $id, field: $field, value: $value, type: $type)
  }
`;

export const UPLOAD_AVATAR_MUTATION = `
  mutation uploadAvatar($id: ID!, $image: Upload!) {
    uploadAvatar(id: $id, image: $image) {
      avatar
    }
  }
`;

export const CREATE_SHOW_MUTATION = `
  mutation createShow($eventId: ID!, $subtitle: String) {
    createShow(eventId: $eventId, subtitle: $subtitle) {
      id
    }
  }
`;

export const UPDATE_SHOW_MUTATION = `
  mutation modifyShow($showId: ID!, $field: String!, $value: String!) {
    modifyShow(showId: $showId, field: $field, value: $value) {
      id
    } 
  }
`;

export const UPDATE_VENUE_MUTATION = `
  mutation updateVenue($venueId: ID!, $field: String!, $value: String!) {
    updateVenue(venueId: $venueId, field: $field, value: $value) 
  }
`;

export const RESET_PW_MUTATION = `
  mutation resetPw($old: String!, $new: String!) {
    resetPw(old: $old, new: $new) {
      jwt
    }
  }
`;

export const UPDATE_TIER_MUTATION = `
  mutation modifyTier($id: ID!, $field: String!, $value: String!) {
    modifyTier(id: $id, field: $field, value: $value) {
      id
      formattedPrice
    }
  }
`;

export const CREATE_TIER_MUTATION = `
  mutation createTier($name: String!, $price: Int!, $currency: String!, $showId: ID!, $eventId: ID!, $cap: Int!, $byDonation: Boolean!, $start_time: String, $end_time: String, $minimum: Float) {
    createTier(name: $name, price: $price, currency: $currency, showId: $showId, eventId: $eventId, cap: $cap, byDonation: $byDonation, start_time: $start_time, end_time: $end_time, minimum: $minimum) {
      id 
    }
  }
`;

export const DELETE_TIER_MUTATION = `
  mutation deleteTier($id: ID!) {
    deleteTier(id: $id) 
  }
`;

export const CREATE_PROMO_MUTATION = `
  mutation createPromo($code: String!, $price: Int!, $showId: ID!, $eventId: ID!, $cap: Int!, $start_date: String, $end_date: String) {
    createPromo(code: $code, price: $price, showId: $showId, eventId: $eventId, cap: $cap, start_date: $start_date, end_date: $end_date) {
      id 
    }
  }
`;

export const UPDATE_PROMO_MUTATION = `
  mutation modifyPromo($id: ID!, $field: String!, $value: String!) {
    modifyPromo(id: $id, field: $field, value: $value) {
      id
    }
  }
`;

export const DELETE_PROMO_MUTATION = `
  mutation deletePromo($id: ID!) {
    deletePromo(id: $id)
  }
`;

export const DELETE_EVENT_MUTATION = `
  mutation deleteEvent($id: ID!) {
    deleteEvent(id: $id)
  }
`;

export const CREATE_VENUE_MUTATION = `
  mutation createVenue($name: String!, $address: String!, $secondary: String, $city: String!, $stprv: String, $mapbox_id: String, $lat: Float, $lng: Float, $district: String) {
    createVenue(name: $name, address: $address, secondary: $secondary, city: $city, stprv: $stprv, mapbox_id: $mapbox_id, lat: $lat, lng: $lng, district: $district) {
      id
      name
      street_address
      city
    }
  }
`;

export const DELETE_SHOW_MUTATION = `
  mutation deleteShow($id: ID!) {
    deleteShow(id: $id)
  }
`;

export const SEND_TICKETS_MUTATION = `
  mutation sendTickets($userId: ID!, $showId: ID!, $email: String!) {
    sendTickets(userId: $userId, showId: $showId, email: $email) 
  }
`;

export const PAYOUT_MUTATION = `
  mutation userPayment($userId: ID!, $eventId: ID!, $amount: Float!) {
    userPayment(userId: $userId, eventId: $eventId, amount: $amount) {
      id
    }
  }
`;

export const REFUND_MUTATION = `
  mutation ticketRefund($id: ID!, $reason: String!) {
    ticketRefund(id: $id, reason: $reason)
  }
`;

export const DELETE_TICKET_MUTATION = `
  mutation deleteTicket($id: ID!) {
    deleteTicket(id: $id)
  }
`;