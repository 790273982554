import { useReducer, useEffect } from 'react';
import {
  TextInput,
  Text,
  Button
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { DataTable } from 'mantine-datatable';
import { IconSearch } from '@tabler/icons';
import { Link } from 'react-router-dom';
import { useQuery } from 'graphql-hooks';
import { ADMIN_USERS_QUERY } from '@/network/queries';

const PAGE_SIZE = 10;
export default function Users() {
  const [queryStatus, setQueryStatus] = useReducer((prev, next) => {
    const newStatus = {...prev, ...next};

    return newStatus;
  }, { loading: false, error: false})
  const [records, setRecords] = useReducer((prev, next) => {
    const newRecords = { ...prev, ...next };

    const sortBy = (data, field, dir) => {
      if (data && data.length) {
        const results = data.sort((a, b) => {
          if(a[field] < b[field]) {
            return -1;
          }

          if (a[field] > b[field]) {
            return 1;
          }

          return 0;
        });

        return dir === 'desc' ? results.reverse() : results;
      } else {
        return [];
      }
    };

    const from = (newRecords.page - 1) * PAGE_SIZE;
    const to = from + PAGE_SIZE;

    const sorted = newRecords.rows ? sortBy(newRecords.users, newRecords.sortStatus.columnAccessor, newRecords.sortStatus.direction) : []; 

    newRecords.rows = sorted.length ? sorted.slice(from, to) : [];

    return newRecords;
  }, { 
    rows: [], 
    users: [], 
    nameQuery: '', 
    emailQuery: '', 
    enabled: true, 
    admin: false, 
    page: 1, 
    sortStatus: { 
      columnAccessor: 'name', 
      direction: 'asc' 
    } 
  });
  const [debouncedNameQuery] = useDebouncedValue(records.nameQuery, 300);
  const [debouncedEmailQuery] = useDebouncedValue(records.emailQuery, 300);
  const { loading, error, data } = useQuery(ADMIN_USERS_QUERY);

  useEffect(() => {
    if (loading) setQueryStatus({loading: true});

    if (error) setQueryStatus({error: true});

    if (data) {
      setQueryStatus({loading: false, error: false});
      setRecords({ users: data.users });
      setRecords({ rows: data.users });
    }
  }, [loading, error, data]);

  useEffect(() => {
    if (records.users && debouncedNameQuery !== '') {
      const val = debouncedNameQuery.trim().toLowerCase();
      const filtered = records.users.filter(({ name }) => name.toLowerCase().includes(val));
      setRecords({rows: filtered});
    } 

    if (records.users && debouncedEmailQuery !== '') {
      const val = debouncedEmailQuery.trim().toLowerCase();
      const filtered = records.users.filter(({ email }) => email.toLowerCase().includes(val));
      setRecords({rows: filtered});
    }

    if (debouncedNameQuery === '' && debouncedEmailQuery === '') {
      setRecords({rows: records.users});
    }
  }, [setRecords, debouncedNameQuery, debouncedEmailQuery, records.users]);

  useEffect(() => {
    if (records.users && (records.enabled !== null || records.admin !== null)) {
      setRecords({rows: records.users.filter(({ enabled: ue, admin: ua }) => ue === records.enabled || ua === records.admin)});
    }

    if (!records.enabled && !records.admin) {
      setRecords({rows: records.users});
    }
  }, [records.enabled, records.users, records.admin]);

  if (queryStatus.error) return (<Text color="red">There was a problem loading users.</Text>);

  if (!records && data) setRecords(data.users);

  return (
    <DataTable
      withBorder
      minHeight={200}
      noRecordsText="No users matching your query"
      borderRadius="sm"
      withColumnBorders
      striped
      highlightOnHover
      fetching={queryStatus.loading}
      records={records.rows}
      sortStatus={records.sortStatus}
      totalRecords={records.users?.length}
      onPageChange={(page) => setRecords({page})}
      page={records.page}
      recordsPerPage={PAGE_SIZE}
      onSortStatusChange={(status) => setRecords({sortStatus: status })}
      columns={[
        { 
          accessor: 'name',
          filter: (
            <>
              <TextInput
                label="Users by Name"
                description="Show users whose names include the specified text"
                placeholder="Search users..."
                icon={<IconSearch size={16} />}
                value={records.nameQuery}
                onChange={(e) => setRecords({nameQuery: e.currentTarget.value })}
                mb={10}
              />
              <Button color="orange" onClick={() => setRecords({nameQuery: ''})}>Clear</Button>
            </>
          ),
          sortable: true,
          filtering: records.nameQuery !== '',
          render: ( { name } ) => <Text>{name === 'null null' ? ' ' : name}</Text>
        },
        { 
          accessor: 'email',
          filter: (
            <>
              <TextInput
                label="Users by Email"
                description="Show users whose emails include the specified text"
                placeholder="Search users..."
                icon={<IconSearch size={16} />}
                value={records.emailQuery}
                onChange={(e) => setRecords({ emailQuery: e.currentTarget.value })}
                mb={10}
              />
              <Button color="orange" onClick={() => setRecords({ emailQuery: '' })}>Clear</Button>
            </>
          ),
          sortable: true,
          filtering: records.emailQuery !== '',
        },
        {
          accessor: 'enabled',
          title: 'Status',
          sortable: false,
          // this column has custom cell data rendering
          render: ({ enabled, admin }) => (
            <Text weight={700} color={enabled ? 'green' : 'red'}>
              {enabled ? 'Active ' : 'Inactive '}{admin ? 'Admin' : 'User'}
            </Text>
          )
        },
        { 
          accessor: 'actions',
          title: 'Actions',
          render: ({ id }) => {
            const editLink = `/app/admin/users/${id}`;
            return (
              <Link to={editLink}>
                <Button color="orange">Edit</Button>
              </Link>
            ) 
          }
        },
      ]}
    /> 
  )
}