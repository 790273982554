import { notifications } from "@mantine/notifications";
import { IconThumbUp, IconMoodCheck, IconX } from '@tabler/icons-react';

export const success = (title, message) => {
  notifications.show({
    autoClose: 5000,
    icon: <IconMoodCheck />,
    message,
    title,
    withBorder: true,
    color: 'green' 
  });
};

export const fail = (title = 'Uh Oh!', message) => {
  notifications.show({
    autoClose: 5000,
    icon: <IconX />,
    message,
    title,
    withBorder: true,
    color: 'red' 
  });
};

export const notice = (title, message) => {
  notifications.show({
    autoClose: 5000,
    icon: <IconThumbUp />,
    message,
    title,
    withBorder: true,
    color: 'orange' 
  });
  
};  