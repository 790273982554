import { 
  Paper, 
  Title,
  Table,
} from "@mantine/core";
import { convertDate, formatCurrency } from "@/lib/helpers";

const collateDonations = (donations) => {
  return (donations && donations.length) ? donations.map((donation, idx) => {
    return (
      <tr key={`donation${idx}`}>
        <td>{donation.user?.name}</td>
        <td>{donation.user?.email}</td>
        <td>{formatCurrency(donation?.amount)}</td>
        <td>{convertDate(donation?.date)}</td>
      </tr>
    ); 
  }) : (<tr><td span="4">No donations yet.</td></tr>); 
};

export default function Donations({ donations }) {
  const collatedDonations = collateDonations(donations);

  return (
    <Paper p="md" shadow="sm" withBorder>
      <Title color="orange" order={2} mb={20}>Donations for this Event</Title>
      {donations.length === 0 && <Title order={3} mb={20}>No donations yet!</Title>}
      {donations.length > 0 && (
      <Table striped highlightOnHover>
        <caption></caption>
        <thead>
          <tr>
            <th>Donor Name</th>
            <th>Donor Email</th>
            <th>Amount</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>{collatedDonations}</tbody>
      </Table>)}
    </Paper>
  )
}