import { useQuery } from "graphql-hooks";
import { HOME_TICKET_QUERY } from "../../network/queries";
import { 
    SimpleGrid, 
    Title,
    createStyles 
} from "@mantine/core";
import Ticket from "../../components/Ticket";
import { useAuth } from "../../components/AuthProvider";
import PSLoader from "@/components/PSLoader";

const useStyles = createStyles((theme) => ({
  sectionTitle: {
    color: "#EB4B51"//theme.colors.pink[4]
  }
}));

export default function HomeTickets({showTitle}) {
    const { userId } = useAuth();
    const { classes } = useStyles();

    const { loading, data, error } = useQuery(HOME_TICKET_QUERY, { variables: { userId: userId, past: false}});

    if (loading) return <PSLoader />;
    if (error) return "ERROR FETCHING TICKETS";

    const tickets = data?.tickets;
    const showTickets = Boolean(tickets && tickets.length);
  
    const collated = {};

    if (showTickets) {
      tickets.forEach(ticket => {
        if (collated[ticket.show.id]) {
          collated[ticket.show.id].push(ticket);
        } else {
          collated[ticket.show.id] = [ticket];
        }
      });
    }

    return (
    <>
        {showTitle && <Title className={classes.sectionTitle} mb={20}>Tickets</Title>}
        {showTickets && (
        <SimpleGrid cols={2}>
            {Object.keys(collated).map(showId => <Ticket key={`ticket${showId}`} tickets={collated[showId]} />)}
        </SimpleGrid>)}
        {!showTickets && (<Title order={3}>You have no upcoming tickets.</Title>)}
    </>
    )
}
