import UserSelect from '../../components/UserSelect';
import { 
    createStyles, 
    Card, 
    Text,
} from '@mantine/core';
import { useQuery } from 'graphql-hooks';
// import { STRIPE_CONNECT_QUERY } from '@/network/queries';
import UserInput from '../../components/UserInput';
import currencyData from 'currency-codes/data';
// import { Link } from 'react-router-dom';
// import PSLoader from '@/components/PSLoader';

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },

  item: {
    '& + &': {
      paddingTop: theme.spacing.sm,
      marginTop: theme.spacing.sm,
      borderTop: `0.1rem solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
      }`,
    },
  },

  switch: {
    '& *': {
      cursor: 'pointer',
    },
  },

  title: {
    lineHeight: 1,
    fontWeight: 'bolder',
    marginBottom: '0.45rem'
  },
}));

export default function BillingPreferences({user, handler}) {
  const { classes } = useStyles();
  const currencies = currencyData.map(curr => ({label: `${curr.currency} (${curr.code})`, value: curr.code}))
  // const { loading, error, data } = useQuery(STRIPE_CONNECT_QUERY, { variables: { id: +user.id }});

  // if (loading) return <PSLoader />;
  
  // if (error) return 'Error loading billing data...';

  // const { getStripeLink: stripeUrl } = data;

  return (
    <Card withBorder radius="md" p="xl" className={classes.card}>
      <Text fz="lg" className={classes.title}>
        Billing Settings
      </Text>

      <UserSelect 
        label="Pick your currency" 
        placeholder="Select currency" 
        value={user.currency} 
        handler={handler} 
        field="currency" 
        data={currencies} 
      />          
      <UserInput label="E-Transfer address" placeholder="E-transfer email address" value={user.etransfer || user.email} field="etransfer" handler={handler} />
      <Text mt={10} color="dimmed" fz="0.8em">Payouts are done by E-Transfer within 5-7 business days after your event is concluded. If you require interim payouts, please reach out to support@plainstage.com. Please note that there is a $1.50 CAD e-transfer fee.</Text>
      {/* <Text mt={10}>To set up your Stripe Connect account, click <Link to={stripeUrl}>here</Link>.</Text> */}
    </Card>
  );
}