import { useState } from 'react';
import { 
    Tabs,
    Text
} from "@mantine/core";
import { IconUser, IconTicket, IconReport } from '@tabler/icons-react';
import { useAuth } from '@/components/AuthProvider';
import { useQuery } from 'graphql-hooks';
import { ADMIN_USERS_QUERY, ADMIN_EVENTS_QUERY } from '@/network/queries';
import Users from './Users';
import Events from './Events';
import Reports from './Reports';

export default function AdminDashboard() {
  const { admin } = useAuth();
  const [activeTab, setActiveTab] = useState('events');

  if (!admin) window.location = `/app/dashboard`;

  const { loading: userLoading, error: userError, data: userData } = useQuery(ADMIN_USERS_QUERY);
  const { loading: eventLoading, error: eventError, data: eventData } = useQuery(ADMIN_EVENTS_QUERY);
  
  if (userLoading || eventLoading) return 'Loading....';

  if (userError || eventError) return (<Text>There was an error loading data.</Text>);

  const { users } = userData;
  const { allEvents } = eventData;

  const prepUsers = () => {
    return users.map(user => {
      user.status = `${user.enabled ? 'Active ' : 'Inactive '}${user.admin ? 'Admin' : 'User'}`;
      user.events = `${user.events.length}`;
      delete user.enabled;
      delete user.admin;
      return user;
    });
  }

  return (
    <Tabs color="orange" defaultValue="users" value={activeTab} onTabChange={setActiveTab}>
      <Tabs.List grow>
        <Tabs.Tab value="users" icon={<IconUser size="0.8rem" />}>Users</Tabs.Tab>
        <Tabs.Tab value="events" icon={<IconTicket size="0.8rem" />}>Events</Tabs.Tab>
        <Tabs.Tab value="reports" icon={<IconReport size="0.8rem" />}>Reports</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="users" pt="xs">
        <Users users={prepUsers}/>
      </Tabs.Panel>

      <Tabs.Panel value="events" pt="xs">
        <Events events={allEvents} />
      </Tabs.Panel>

      <Tabs.Panel value="reports" pt="xs">
        <Reports events={allEvents} users={prepUsers()} />
      </Tabs.Panel>
    </Tabs>
  )
}