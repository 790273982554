import { useState, useEffect } from "react";
import { useMutation } from "graphql-hooks";
import { SIGNUP_MUTATION } from "../network/mutations";
import { isEmailValid } from "../lib/helpers";
import Logo from "../components/Logo";
import { useAuth } from "../components/AuthProvider";
import { Link } from 'react-router-dom';
import {
  Paper,
  createStyles,
  TextInput,
  Center,
  Button,
  Title,
  Text,
} from '@mantine/core';
import { PasswordStrength } from "../components/PwStrength";
import { GoogleLogin } from '@react-oauth/google';
import { outerStyles } from "../lib/styles";

const useStyles = createStyles(outerStyles);

export default function Signup({ setAuth }) {
  const { classes } = useStyles();
  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ success, setSuccess ] = useState(false);
  const [ signup, { error } ] = useMutation(SIGNUP_MUTATION);
  const { setToken } = useAuth();
  const [ single, setSingle ] = useState(false);
  // const init = useRef(false);

  const responseMessage = (response) => {
    const { credential } = response; 
    const token = atob(credential.split('.')[1]);

    const { email } = token;
    
    signup({variables: { email, password: credential, sml: true}})
      .then(handleSignup);
  };

  const errorMessage = (error) => {
      console.log("ERROR:", error);
  };

  const handleSignup = ({ data }) => {
    const { signup } = data;
    if (signup) {
      const token = signup.jwt;
      localStorage.setItem("token", token);
      setToken(token);
      setSingle(false);
      setSuccess(true);
      window.location = '/verify';
    }  
  };

  useEffect(() => {
    if (error) setPassword("");
  }, [error]);
  
  const doSignup = async (succ) => {
    setSingle(true);
    if (email && password) {
      if (!isEmailValid.test(email)) {
        console.log("EMAIL NOT VALID");
        // do some error thingy with the text input
        return false;
      }

      if (!succ) {
        const signupParams = {variables: { email, password }};
        const results = await signup(signupParams);
        handleSignup(results);
      }
    } 
  };

  return (
    <div className={classes.wrapper}>
      <Paper className={classes.form} radius={0} p={30}>
        <Title order={2} align="center" mt="md" mb={50}>
          <Link to="/">
            <Logo />
          </Link>
        </Title>

        <Title mb={25}>
          Create an Account
        </Title>

        <TextInput 
          value={email} 
          onChange={(event) => setEmail(event.currentTarget.value)} 
          label="Email address" 
          placeholder="hello@gmail.com" 
          size="md" 
          error={error ? error.message : null}
          required
        />
        <PasswordStrength setPassword={setPassword} />
        <Button disabled={single} onClick={() => doSignup(success)} fullWidth mt="xl" size="md" className={classes.button}>
          Create Account 
        </Button>

        <Text align="center" mt="md">
          Already have an account?{' '}
          <Link to="/login" style={{textDecoration: 'none', color: '#FF5A60'}}>
            Login 
          </Link>
        </Text>
        <Center mt={15}>
          <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
        </Center>
      </Paper>
    </div>
  );
}
