import { 
  Autocomplete, 
  Group,
  Text,
  Avatar,
  Stack,
} from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import { useQuery } from 'graphql-hooks';
import { EVENT_SEARCH_QUERY } from '../network/queries';
import PSLoader from './PSLoader';

const AutoCompleteItem = ({slug, title, city, image}) => {
  return (
    <Group nowrap onClick={() => window.location = `/events/${slug}`} style={{cursor: 'pointer'}}>
      <Avatar src={image} alt={title} />
      <Stack>
        <Text fw={700}>{title}</Text>
        <Text color="dimmed">{city}</Text>
      </Stack>
    </Group>
  )
};

export default function EventSearch(props) {
  const {loading, error, data} = useQuery(EVENT_SEARCH_QUERY, { variables: { past: false }});

  if (loading) return <PSLoader />;

  if (error) return '';

  const eventData = data.events.map(evt => {
    evt.category = evt.category?.name || 'Uncategorized';
    evt.type = evt.type?.name || 'None';
    evt.city = evt.venue?.city;
    return {
      ...evt,
      value: evt.title
    }
  });

  return (
    <Autocomplete
      icon={<IconSearch size={18} stroke={1.5} />}
      radius="xl"
      error={error && "Could not load events"}
      disabled={loading}
      size="md"
      limit={4}
      withinPortal
      placeholder="Search by name, category, or city"
      data={eventData}
      itemComponent={AutoCompleteItem}
      filter={(value, item) =>
        item.value.toLowerCase().includes(value.toLowerCase().trim()) ||
        item.city.toLowerCase().includes(value.toLowerCase().trim()) ||
        item.category.toLowerCase().includes(value.toLowerCase().trim()) ||
        item.type.toLowerCase().includes(value.toLowerCase().trim())
      }
    />
  );
}