import { useState, forwardRef } from "react";
import {
  TextInput,
  Textarea,
  SimpleGrid,
  Group,
  Title,
  Button,
  Container,
  Text,
} from "@mantine/core";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "@mantine/form";
import { useMutation } from "graphql-hooks";
import { CONTACT_MUTATION } from "../../network/mutations";

export const ContactUs = forwardRef((props, ref) => {
  const [response, setResponse] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  const [contact] = useMutation(CONTACT_MUTATION);
  const form = useForm({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      message: "",
    },
    validate: {
      name: value => value.trim().length < 2,
      email: value => !/^\S+@\S+$/.test(value),
      subject: value => value.trim().length === 0,
    },
  });

  const processForm = result => {
    setResponse(result.data.contact);
  };

  const getCaptcha = result => {
    setCaptcha(true);
  };

  return (
    <Container ref={ref}>
      {response && (
        <div>
          <Title order={2} my={20}>
            Thanks for reaching out!
          </Title>
          <Text>We'll be in touch.</Text>
        </div>
      )}
      {!response && (
        <>
          <Title order={1} align="center" my={20}>
            Hi, I'm the Contact Form
          </Title>

          <Group position="center">
            <Text color="dimmed" mb={10}>
              Have questions? Want to work with us? Fill out the form below or
              reach out to support@plainstage.com. Our office is located in
              Vancouver, BC, but you can reach out anytime. We're artists, we're
              awake at weird hours.
            </Text>
          </Group>

          <SimpleGrid
            cols={2}
            mt="xl"
            breakpoints={[{ maxWidth: "sm", cols: 1 }]}
          >
            <TextInput
              label="Name"
              placeholder="Your name"
              name="name"
              variant="filled"
              {...form.getInputProps("name")}
            />
            <TextInput
              label="Email"
              placeholder="Your email"
              name="email"
              variant="filled"
              {...form.getInputProps("email")}
            />
          </SimpleGrid>

          <TextInput
            label="Subject"
            placeholder="Subject"
            mt="md"
            name="subject"
            variant="filled"
            {...form.getInputProps("subject")}
          />
          <Textarea
            mt="md"
            label="Message"
            placeholder="Your message"
            maxRows={10}
            minRows={5}
            autosize
            name="message"
            variant="filled"
            {...form.getInputProps("message")}
          />
          <Group position="center" mt="xl">
            <ReCAPTCHA
              sitekey="6Lfv2fMkAAAAAA3jDzyY2oE1EycbQAD4YaIS_Z1H"
              onChange={getCaptcha}
            />
          </Group>
          <Group position="center" my="xl">
            <Button
              type="button"
              size="md"
              onClick={() =>
                captcha &&
                contact({ variables: form.values, onSuccess: processForm })
              }
            >
              Send message
            </Button>
          </Group>
        </>
      )}
    </Container>
  );
});
