import { 
  createStyles, 
  Card, 
  Image, 
  Title,
  Text, 
  Button, 
  Center
} from '@mantine/core';
import { Link } from 'react-router-dom';
import { convertDate } from '@/lib/helpers';
import dayjs from 'dayjs';

const useStyles = createStyles((theme) => ({
    card: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
      },
      footer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: `${theme.spacing.sm}px ${theme.spacing.lg}px`,
        borderTop: `1px solid ${
          theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
        }`,
      },
      title: {
        lineHeight: 1,
      },
      control: {
        marginTop: theme.spacing.xl * 1.5,

        [theme.fn.smallerThan('sm')]: {
          width: '100%',
        },
      },
}));

const findNextShow = (shows) => {
  if (shows && shows.length) {
    const now = dayjs().valueOf();
    const show = shows.find(s => +s.startTime > now);
    if (show) {
      return convertDate(show.startTime);
    } else {
      return '';
    }
  } else {
    return '';
  }
};

export default function Event({ evt, lp }) {
    const { classes } = useStyles();

    const startTime = findNextShow(evt.shows);

    return (
        <Card withBorder shadow="sm" p="xl" mb={20} className={classes.card} sx={{height: '95%'}}>
          <Card.Section sx={{minHeight: '155px', maxHeight: '200px'}}>
            <Image src={evt.cardImage || evt.image} alt={evt.title} imageProps={{style: {objectPosition: 'top center', objectFit: 'cover', width: '100%', height: '100%', minHeight: '199px', maxHeight: '199px'}}} />
          </Card.Section> 
          <Card.Section p="lg">
            <div style={{height: '4em', overflow: 'hidden', lineClamp: 2, textOverflow: '...'}}>
              <Title order={3} mb={10}>
                {evt.title}
              </Title>
            </div>
            <Text color="#ff5a60" fw="bold" mt={10}>
              {startTime}
            </Text>
            <div style={{height: '2em'}}>
              <Text mt={20}>
                <Text weight={700}>{evt.venue?.name}</Text>
                {evt.venue?.city}, {evt.venue?.stprv}
              </Text>
            </div>
          </Card.Section>
          <Card.Section>
            {/* <Text mt="sm" mb="md" color="dimmed" size="xs" lineClamp={4}>
              <div dangerouslySetInnerHTML={{__html: about.split(' ').slice(0, 30).join(' ') || "<p>No description provided.</p>"}} />
            </Text> */}
            <Center mt={20} mb={10}>
              <Link to={lp ? `/events/${evt.slug}` : `/app/events/${evt.id}`}>
                <Button variant="filled" color="orange" size="md" className={classes.control}>
                  {lp ? 'View' : 'Manage'} Event
                </Button>
              </Link>
            </Center>
          </Card.Section>
        </Card>
      );
}
