import {
    createStyles, 
    Container,
    Title,
    Grid,
} from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import { Testimonial } from './Testimonial';

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingBottom: theme.spacing.xl * 2,
    paddingTop: theme.spacing.xl * 2,
    marginTop: theme.spacing.xl * 2,
    zIndex: 1,
    position: 'relative',

    [theme.fn.smallerThan('sm')]: {
      paddingBottom: theme.spacing.xl * 3,
    },
  },
}));

const tests = [
  { 
    text: 'This will be an invaluable tool for independent creators and performers who are financing their own work.',
    source: 'Victoria Laberge, Arts Producer and Publicist'
  },
  {
    text: "You'd be hard-pressed to find another ticketing program that provides such an intuitive and user-friendly experience.",
    source: "Vishesh Abeyratne, Playwright"
  },
  {
    text: 'Plainstage offers an intuitive, low cost and hassle free ticketing system that makes getting butts in seats easier.',
    source: 'Jonathan Shaboo, Creative Producer - Bramble Theatre Company'
  }
];

export default function Testimonials({ isMobile }) {
  const { classes } = useStyles();
    
  return (
      <Container my={65} className={classes.container}>
        {isMobile ? 
          <Carousel slideSize="90%" height={270} slideGap="xl" loop dragFree>
            {tests.map((t, idx) => <Carousel.Slide key={`test${idx}`}><Testimonial text={t.text} source={t.source} /></Carousel.Slide>)}
          </Carousel>
         : 
         <Grid grow>
          <Grid.Col span={12}>
            <Title>Testimonials</Title>
          </Grid.Col>
          {tests.map((t, idx) => <Grid.Col key={`test${idx}`} span={4}><Testimonial text={t.text} source={t.source} /></Grid.Col>)}
        </Grid>}
      </Container>
  )
}