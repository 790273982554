import { 
  Container, 
  Title, 
  Grid,
  Image,
  Text,
  Button,
  Stack,
  Group
} from '@mantine/core';
import { useScrollIntoView } from '@mantine/hooks';
import LPFooter from '../modules/lp/LPFooter';
import { ContactUs } from '../modules/lp/ContactUs';
import { pickUrl } from '../lib/uris';
import { Link } from 'react-router-dom';
import { IconBrandInstagram, IconBrandLinkedin } from '@tabler/icons-react';

const url = pickUrl();

export default function OurStory() {
  const { scrollIntoView, targetRef } = useScrollIntoView({
    offset: 60,
  });

  return (
    <div>
      <Container my={50}>
        <Grid grow>
          <Grid.Col span={12} md={6}>
            <Title mb="lg">Our Story</Title>
            <Text mb="lg">
              Plainstage was born, as all great ideas are, with margaritas and an extra shot of frustration. Artists-producers grow disheartened by how much money the bigger players in event ticketing charge. So, we asked ourselves, &quot;How hard can it be to build a ticketing platform designed with the independent producer in mind?&quot; Famous last words, right?
            </Text>
            <Text mb="lg">
              We decided to create a platform designed for smaller events, simplifying the process and allowing artists to keep more of their hard-earned sales. In a nutshell, we built the ticketing platform we kept hearing that people wished existed.
            </Text>
            <Button onClick={() => scrollIntoView({alignment: 'center'})}>Contact Us</Button>
          </Grid.Col>
        </Grid>
        <Title order={1} mt={40} mb={10}>Our Goal</Title>
        <Text mb={20}>It's simple, really. We love art and we want to make it more accessible. We value your feedback and always welcome suggestions as we continue to build this platform with you in mind. Let's create together.</Text>
      </Container>
      <ContactUs ref={targetRef} />
      <LPFooter />
    </div>
  );
}