import { useState } from 'react';
import {
  TextInput,
  Box,
  Center,
  NumberInput,
  Group,
  Space,
  Text,
  Button,
  Stack
} from '@mantine/core';
import { IconChevronLeft, IconCreditCard } from '@tabler/icons-react';
import { fail } from '@/lib/notifications';

export default function CheckoutForm({ evt, ticketData, setTicketData, storeData, validateCode, setDisplayOpts, isMobile }) {
  const [donation, setDonation] = useState(ticketData.donation);
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);

  const validateEmail = (val) => {
    if (val !== ticketData.email) {
      setError(true);
      return false;
    } else {
      setError(false);
      return true;
    }
  };

  const validateForm = () => {
    if (ticketData.email === '') {
      fail("Whoops!", "You must supply your e-mail address.");
      return false;
    }

    const emailValid = validateEmail(email);
    
    if (emailValid) {
      storeData();
    }
  };

  const boxes = (<>
    <TextInput
      mt="md"
      label="Name"
      placeholder="Name"
      value={ticketData.name}
      onChange={(val) => setTicketData({name: val.target.value})}
    />
    <TextInput
      mt={isMobile ? "sm" : "md"}
      label="Email"
      placeholder="Email"
      type="email"
      value={ticketData.email}
      onChange={(val) => { validateEmail(val.target.value); setTicketData({email: val.target.value});}}
    />
  </>);

  return (
    <>
      <Box style={{cursor: 'pointer'}} onClick={() => setDisplayOpts({checkout: false})}>
        <Group>
          <IconChevronLeft />
          <Text>Back to {evt.fundraising ? "event" : "tickets"}</Text>
        </Group>
      </Box>
      {isMobile ? <Stack>{boxes}</Stack> : <Group grow>{boxes}</Group>}
      <Group grow>
        {!isMobile && <Space />}
        <TextInput
          mt="md"
          label="Please re-enter your email"
          placeholder="Re-enter email"
          type="email"
          error={error ? "Emails don't match." : false}
          value={email}
          onChange={(val) => { setEmail(val.target.value); validateEmail(val.target.value); }}
        />
      </Group>
      <Group>
        {!evt.fundraising && <><TextInput
          mt="md"
          label="Have a Promo Code? Enter it here:"
          placeholder="Enter code"
          value={ticketData.promo}
          onChange={(val) => setTicketData({promo: val.target.value})}
        />
        <Button mt="2.5em" size="md" color='orange' compact onClick={validateCode} disabled={!ticketData.promo.length}>Validate Code</Button></>}
      {evt.fundraising && (
        <NumberInput
          mt="md"
          placeholder="Donation amount"
          name="donation"
          min={0}
          label={`What would you like to donate in support of ${evt.title}?`}
          value={donation}
          onChange={(val) => { setDonation(val); setTicketData({donation: val});}}
        />
      )}
      {evt.donation && (
          <NumberInput
            mt="md"
            placeholder="Donation amount"
            name="donation"
            min={0}
            label={`Would you like to make a donation to support ${evt.owner.name}?`}
            value={donation}
            onChange={(val) => { setDonation(val); setTicketData({donation: val});}}
          />
        )}
      </Group>
      <Center>
        <Button
          mt={20}
          size="xl"
          color='#ff5a60'
          disabled={!ticketData.name.length && !ticketData.email.length} 
          leftIcon={<IconCreditCard />}
          onClick={validateForm}
        >
          Checkout
        </Button>
      </Center>
    </>
  )
}