import { 
  Card, 
  Grid,
  Text, 
  Button
} from '@mantine/core';
import { IconTicket } from '@tabler/icons';
import { convertDate } from '@/lib/helpers';
import dayjs from 'dayjs';

const brandOrange = "#ed7966";

const calcShowClosed = (show) => {
  if (Number.isInteger(parseInt(show.startTime, 10))) {
    const threshold = dayjs(+show.startTime).subtract(show.closeInterval, 'hours');
    const now = dayjs();
    return threshold.isBefore(now);
  }

  return false;
};

const calcPastST = (show) => {
  if (Number.isInteger(parseInt(show.startTime, 10))) {
    const threshold = dayjs(+show.startTime);
    const now = dayjs();
    return threshold.isBefore(now);
  }

  return false;
};

export default function Show({ evt, show, showTiers, isMobile }) {
  const closed = show.closed || (show.autoclose && calcShowClosed(show)) || calcPastST(show);
  const parts = convertDate(show.startTime).split(' ');
  const soldOut = show.count >= show.cap;
  
  return (
    <Card shadow="md" padding="lg" withBorder>
      <Grid>
        <Grid.Col span={2}>
          <Text fz="lg" align="center" color={brandOrange}>{parts[1].toUpperCase()}</Text>
          <Text fz="lg" align="center">{parts[2].replace(',','')}</Text>
        </Grid.Col>
        <Grid.Col md={7} span={6}>
          {show.subtitle && <Text fz="lg" fw="500">{show.subtitle}</Text>}
          {!show.subtitle && <Text fz="lg" fw="500" mb="md">{parts[0]} {parts[5]} {parts[6]}</Text>}
          {show.subtitle && <Text mb="md">{parts[0]} {parts[5]} {parts[6]}</Text>}
          <Text>{evt.venue.name}</Text>
        </Grid.Col>
        <Grid.Col md={3} span={4} justify="center" align="center">
          {closed ? <Text color={brandOrange}>Sales Closed!</Text> :
            soldOut ? <Text color={brandOrange}>Sold Out!</Text> :
            <Button color={brandOrange} size={isMobile ? "sm" : "lg"} mr="sm" leftIcon={<IconTicket />} onClick={() => showTiers(show.id)}>Tickets</Button>}
        </Grid.Col>
      </Grid>
    </Card>
  );
}
