import {useState, useEffect} from 'react';
import { useManualQuery } from 'graphql-hooks';
import { TICKET_MUTATION } from '@/network/mutations';
import Logo from "@/components/Logo";
import { Link } from 'react-router-dom';
import { pickUri } from '@/lib/uris';
import { 
  createStyles,
  Paper,
  Text,
  Title,
} from '@mantine/core';
import PSLoader from '@/components/PSLoader';

const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: 900,
    backgroundSize: 'cover',
    backgroundImage:
      `url(${pickUri().replace('gql', '')}images/ps-stock.jpg)`,
  },
  button: {
    backgroundColor: theme.pink, 
    color: theme.colors.white
  },
  text: {
    textDecoration: 'none',
    color: theme.pink
  },
  form: {
    borderRight: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    minHeight: 900,
    maxWidth: 450,
    paddingTop: 80,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: '100%',
    },
  },
}));

export default function FreeTickets() {
  const [message, setMessage] = useState(null);
  const [tickets, setTickets] = useState(null);
  const [loading, setLoading] = useState(true);
  const { classes } = useStyles();

  const [makeTicket] = useManualQuery(TICKET_MUTATION);

  useEffect(() => {
    const ls = window.localStorage;
    const name = ls.getItem('name');
    const email = ls.getItem('email');
    const donation = +ls.getItem('donation');
    const promo = ls.getItem('promo');
    const tickets = ls.getItem('tickets');

    const successHandler = ({data}) => {
      const tickets = data.makeTicket;

      //Successfully made ticket, clear LS so we don't duplicate if user refreshes
      ['name', 'email', 'tickets', 'promo', 'donation'].forEach(field => ls.removeItem(field));

      setMessage('Success! Tickets reserved.');
      setTickets(tickets);
      setLoading(false);
    };

      makeTicket({
      variables: { name, email, tickets, donation, promo }, 
      onSuccess: successHandler
    });
  }, [makeTicket]);

  if (loading) return <PSLoader />;

  return (
    <div className={classes.wrapper}>
      <Paper className={classes.form} radius={0} p={30}>
        <Title order={2} align="center" mt="md" mb={50}>
          <Link to="/">
            <Logo />
          </Link>
        </Title>

        {tickets && <div>
          <Title order={3}>Please check your e-mail for your tickets.</Title>
          <Text>{message}</Text>
          </div>}
        {!tickets && <Title order={2}>There was a problem generating your tickets.</Title>}
  
        <Text>Please contact support@plainstage.com or the event organizer if you have any questions.</Text>
      </Paper>
    </div>
  );
};
