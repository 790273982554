import { useEffect } from 'react';
import { 
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { pickEnv } from '../lib/uris';

export default function Test() {
  const [opened, {open, close}] = useDisclosure(false);

  const env = pickEnv();

  if (env === 'production') window.location = '/';

  return (
    <>

    </>
  );
}
