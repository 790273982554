const uris = {
  development: "http://localhost:1337/gql",
  staging: "https://devapi.plainstage.com/gql",
  production: "https://api.plainstage.com/gql"
}

const urls = {
  development: 'http://localhost:3000',
  staging: "https://dev.plainstage.com",
  production: 'https://plainstage.com'
};

const stripe = {
  development: 'pk_test_51MUPzCEwJHpUB0rmjxTnvdkDDikXTWX0HXWiItl6b3TPyb1qYmHOcOnxqwyokTFUU5B7iKkXO9HRugD0o7tFW3cw00tv24o0ZI',
  staging: 'pk_test_51MUPzCEwJHpUB0rmjxTnvdkDDikXTWX0HXWiItl6b3TPyb1qYmHOcOnxqwyokTFUU5B7iKkXO9HRugD0o7tFW3cw00tv24o0ZI',
  production: 'pk_live_51MUPzCEwJHpUB0rm1CKpHSC9V3y37xbq8vjAsPPFp3KWRD1nHT9mxP5GefAZTgOPUcNeh3VzuqsmLKZ3oywujyIe00arS8846v'
};

const google = {
  production: {
    client_id: '1006212117840-2m06bf5lakdufobc9e28qbfvtp9vtq93.apps.googleusercontent.com',
    client_secret: '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'
  },
  staging: {
    client_id: '1006212117840-q3oaq3g97rcpvh9ejrqie88csdhjqsr6.apps.googleusercontent.com',
    client_secret: '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'
  },
  development: {
    client_id: '1006212117840-q3oaq3g97rcpvh9ejrqie88csdhjqsr6.apps.googleusercontent.com',
    client_secret: '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'
  }
}

const mapbox = {
  development: 'pk.eyJ1IjoicGxhaW5zdGFnZSIsImEiOiJjbGw5eWR0N3kxaDIzM2Vtem9lYWQyODdjIn0.eBClMUNDzvXoFe3T_CE6ow',
  staging: 'pk.eyJ1IjoicGxhaW5zdGFnZSIsImEiOiJjbGw5eWR0N3kxaDIzM2Vtem9lYWQyODdjIn0.eBClMUNDzvXoFe3T_CE6ow',
  production: 'pk.eyJ1IjoicGxhaW5zdGFnZSIsImEiOiJjbGw5eWR0N3kxaDIzM2Vtem9lYWQyODdjIn0.eBClMUNDzvXoFe3T_CE6ow'
};

const pickEnv = () => {
  let env;
  switch(window.location.hostname) {
    case 'plainstagelocal.com':
    case 'localhost':
      env = 'development';
      break;
    case `dev.plainstage.com`:
      env = 'staging';
      break;
    default :
      env = 'production';
      break;
  }

  return env;
};

const env = pickEnv();

module.exports = {
  pickUri: () => {
    const custom = localStorage.getItem('customUrl');
    return custom || uris[env];
  },
  pickStripe: () => stripe[env],
  pickGoogle: () => google[env],
  pickUrl: () => urls[env],
  pickMapbox: () => mapbox[env],
  pickEnv
};