import { 
  Paper, 
  Title,
  Badge,
  Tooltip,
  Table,
} from "@mantine/core";
import { convertDate, ticketDeliveryStatus } from "@/lib/helpers";

const collateTickets = (tickets) => {
  const results = {};

  tickets.forEach(ticket => {
    const key = btoa(`${ticket.user.id}${ticket.tier?.name.replace(/[\W_]+/gmi, "")}`);
    if (!results[key]) {
      results[key] = {
        count: 0,
        name: `${ticket.user?.name} / ${ticket.user.email}`,
        tier: ticket.tier?.name,
        show: ticket.show?.subtitle ? ticket.show.subtitle : convertDate(ticket.show.startTime),
        purchased: +ticket.purchaseDate,
        delivery: (ticket.delivery && ticket.delivery.length) ? ticket.delivery[0] : { status: 'undelivered', on: ''}
      } 
    } 

    results[key].count++;
  });

  const res = Object.values(results);

  return res ? res.map((ticket, idx) => {
    return (
      <tr key={`ticket${idx}`}>
        <td>{ticket.name}</td>
        <td>{ticket.count}</td>
        <td>{ticket.tier}</td>
        <td>{ticket.show}</td>
        <td>{ticketDeliveryStatus(ticket.delivery)}</td>
        <td>{convertDate(ticket.purchased)}</td>
      </tr>
    ); 
  }) : (<tr><td span="5">No tickets sold yet.</td></tr>); 
};

export default function Tickets({ tickets }) {
  const collatedTickets = collateTickets(tickets);

  return (
    <Paper p="md" shadow="sm" withBorder>
      <Title color="orange" order={2} mb={20}>Ticket Sales for this Event</Title>
      {tickets.length === 0 && <Title order={3} mb={20}>No sales yet!</Title>}
      {tickets.length > 0 && (
      <Table striped highlightOnHover>
        <caption></caption>
        <thead>
          <tr>
            <th>Name / Email</th>
            <th># of Tickets</th>
            <th>Tier</th>
            <th>Show</th>
            <th>Delivery</th>
            <th>Date Purchased</th>
          </tr>
        </thead>
        <tbody>{collatedTickets}</tbody>
      </Table>)}
    </Paper>
  )
}