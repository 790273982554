import { useState, useEffect } from 'react';
import {
  Group,
  Paper,
  Image,
  Title,
  Text,
  Button,
  ActionIcon,
  Center,
} from '@mantine/core';
import { useManualQuery } from 'graphql-hooks';
import { TICKET_MUTATION } from '@/network/mutations';
import PSLoader from '@/components/PSLoader';
import { IconCheck } from '@tabler/icons-react';
import { convertDate } from '@/lib/helpers';

export default function TicketResults({stripe, free, display, image}) {
  const [makeTicket] = useManualQuery(TICKET_MUTATION);
  const [tickets, setTickets] = useState(null);
  const [email, setEmail] = useState(null);
  const [shows, setShows] = useState([]);
  const [loading, setLoading] = useState(true);
  const ls = window.localStorage;

  useEffect(() => {
    const name = ls.getItem('name');
    const email = ls.getItem('email');
    const donation = +ls.getItem('donation');
    const promo = ls.getItem('promo');
    const tickets = ls.getItem('tickets');
    const showData = {};

    const successHandler = ({data}) => {
      const ticketData = data.makeTicket;

      //Successfully made ticket, clear LS so we don't duplicate if user refreshes
      ['name', 'email', 'tickets', 'promo', 'donation'].forEach(field => ls.removeItem(field));

      ticketData.forEach(t => {
        const { show } = t;

        if (!showData[show.id]) {
          showData[show.id] = show.startTime;
        }
      });

      setShows(Object.values(showData));
      setEmail(email);
      setTickets(ticketData);
      setLoading(false);
      display({final: true});
    };

    if (name && email && tickets) {
      makeTicket({
        variables: { name, email, tickets, donation, promo, stripe: stripe?.id}, 
        onSuccess: successHandler
      });
    } else {
      setLoading(false);
    }
  }, [makeTicket, ls, stripe, display]);

  if (loading) return <PSLoader />;

  return (
    <Paper radius={0} p={30}>
      {tickets ? <>
        <Group grow>
          <Group>
            <ActionIcon radius="xl" variant="filled" color="green" size="lg">
              <IconCheck size="1.25rem"/>
            </ActionIcon>
            <Title order={2} my={10}>Success, {free ? "tickets reserved" : "payment received"}!</Title>
          </Group>
          {image && <Image src={image} alt={tickets[0].event.title} />}
        </Group>
        <Title order={3} mb={2}>You're going to</Title>
        <Title order={1} color="#FF5a60" mb={20}>{tickets[0].event.title}</Title>
        <Title order={3} mb={2}>{tickets.length} Tickets Sent To:</Title>
        <Text mb={20}>{email}</Text>
        <Title order={3} mb={2}>DATE</Title>
        {shows.map(s => <Text key={`show${s}`}>{convertDate(+s)}</Text>)}
        <Text color="dimmed" mt={20} mb={10}>For questions regarding your tickets, please contact the event organizer or support@plainstage.com.</Text>
        <Center>
          <Button size="xl" color="#FF5a60" onClick={() => window.location.reload()}>
            Back to Event
          </Button>
        </Center>
      </> :
      <Title order={2}>There was a problem generating your tickets. Please contact support@plainstage.com.</Title>}
    </Paper>
  );
}
