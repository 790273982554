import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import PaymentForm from "./PaymentForm";
import { useQuery } from 'graphql-hooks';
import { PI_QUERY } from '../../network/queries';
import { pickStripe } from '@/lib/uris';
import PSLoader from '@/components/PSLoader';
  
const stripePromise = loadStripe(pickStripe());

export function BuyTicket({ evt, ticketData, display }) {
    const { total: num, email } = ticketData;
    const { loading, error, data } = useQuery(PI_QUERY, { variables: { num, email, tickets: JSON.stringify(ticketData) }});

    if (error) return (<div>ERROR: {JSON.stringify(error)}</div>);

    if (loading) return <PSLoader />;

    const options = {
        clientSecret: data.getPI,
    };

    return (
        <Elements stripe={stripePromise} options={options}>
            <PaymentForm display={display} evt={evt} />
        </Elements>
    )
}