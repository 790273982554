import { createContext, useState, useContext, useMemo } from "react";
import { useMediaQuery } from '@mantine/hooks';
import { ClientContext, GraphQLClient } from "graphql-hooks";
import { pickUri } from "../lib/uris";
import useJwt from "../lib/useJwt";

const url = pickUri();

const AuthContext = createContext(undefined);

export function AuthProvider({ children }) {
  const { token: fullToken, decodedToken, isExpired } = useJwt("localStorage", "token");
  const [token, setToken] = useState(isExpired ? null : fullToken);
  const [isAuthenticated, setAuthenticated] = useState(Boolean(token));
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery("(max-width: 50em)");

  const client = useMemo(() => new GraphQLClient({ url, headers: {'Authorization': `Bearer ${token}`}}), [token]);

  const value = useMemo(() => ({ userId: decodedToken?.id, admin: decodedToken?.w, isMobile, isAuthenticated, token, loading, setAuthenticated, setLoading, setToken}), [token, isAuthenticated, loading, decodedToken?.id, decodedToken?.w, isMobile]);

  return (
    <ClientContext.Provider value={client}>
      <AuthContext.Provider value={value} >
          {children}
      </AuthContext.Provider>
    </ClientContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};