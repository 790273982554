import {
  Card,
  Title,
  Table
} from '@mantine/core';
import { convertDate, formatCurrency } from "@/lib/helpers";

export default function Payouts({ payments, currency }) {
  const rows = payments.map(payout => 
    <tr key={`payout${payout.id}`}>
      <td>{formatCurrency(payout.amount, currency)}</td>
      <td>{convertDate(payout.on)}</td>
    </tr>
  );

  return (
    <Card mb={10} padding="lg" shadow="sm" withBorder>
      <Title order={2} mb={10}>Payouts</Title>
      {rows.length > 0 ?
        <Table striped highlightOnHover withBorder>
          <thead>
            <tr>
              <th>Amount</th>
              <th>On</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </Table>
        : <Title order={3} mt={20}>There are no payouts yet for this event.</Title>}
    </Card>
  )
}