import { useState, useRef } from 'react';
import { 
  NumberInput, 
  Group, 
  ActionIcon, 
  rem 
} from '@mantine/core';
import { IconMinus, IconPlus } from '@tabler/icons-react';

const brandOrange = "#ed7966";

export default function Qty({tier, setQty}) {
  const [value, setValue] = useState(0);
  const handler = useRef(value);
  const handlers = { 
    increment: () => {
    let newValue = handler.current;

    if (newValue + tier.count + 1 <= tier.max) {
      newValue += 1;
      handler.current = newValue;
      setValue(newValue);
      setQty(newValue);
    } 
  }, 
  decrement: () => {
    let newValue = handler.current;

    if (newValue > 1) {
      newValue -= 1;
    } else {
      newValue = 0;
    }

    handler.current = newValue;
    setValue(newValue);
    setQty(newValue);
  }};

  const checkQty = (val) => {
    if (val + tier.count > tier.max) {
      setValue(tier.max - tier.count);
      setQty(tier.max - tier.count);
    } else {
      setValue(val);
      setQty(val);
    }
  };

  return (
    <Group spacing={5}>
      <ActionIcon color={brandOrange} size={34} variant="filled" onClick={handlers.decrement}>
        <IconMinus /> 
      </ActionIcon>

      <NumberInput
        hideControls
        value={value}
        onChange={checkQty}
        handlersRef={handlers}
        max={tier.max}
        min={0}
        step={1}
        styles={{ input: { width: rem(54), textAlign: 'center' } }}
      />

      <ActionIcon color={brandOrange} size={34} variant="filled" onClick={handlers.increment}>
        <IconPlus />
      </ActionIcon>
    </Group>
  );
}