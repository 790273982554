import { createStyles, Card, Image, Text, Title } from "@mantine/core";

const useStyles = createStyles(theme => ({
  card: {
    backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    cursor: 'pointer'
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    padding: `${theme.spacing.sm}px ${theme.spacing.lg}px`,
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },
  title: {
    lineHeight: 1,
  },
}));

export default function Ticket({ tickets }) {
  const { classes } = useStyles();
  const num = tickets?.length || 0;
  const { event, show } = tickets[0];
  show.startTime = show.startTime
    .split(" ")
    .slice(0, 7)
    .join(" ")
    .replace(" at ", " @ ");

  return (
    <Card withBorder padding="lg" shadow="sm" className={classes.card} onClick={() => window.location = `/app/tickets/${show.id}`}>
      <Card.Section>
        <Image src={event.image} alt={event.title} height={100} />
      </Card.Section>

      <Card.Section inheritPadding mt={10}>
        <Title order={3} mb={10} className={classes.title}>
          {event.title}
        </Title>
        <Text size="xs" color="dimmed">
          {show.startTime}
        </Text>
      </Card.Section>

      <Card.Section inheritPadding>
        <Text mt="sm" mb="md" color="dimmed" size="xs">
          {show.venue?.name}
        </Text>
        <Text color="dimmed" mb={10}>Number of tickets: {num}</Text>
      </Card.Section>
    </Card>
  );
}
