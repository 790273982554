import { useState, useReducer } from 'react';
import { useMutation } from 'graphql-hooks';
import { SEND_TICKETS_MUTATION, REFUND_MUTATION, DELETE_TICKET_MUTATION } from '@/network/mutations';
import {
  Table,
  Text,
  Button,
  Modal,
  Select,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { convertDate, ticketDeliveryStatus, errorMsg } from '@/lib/helpers';
import { success } from '@/lib/notifications';

export default function Tickets({email, userId, tickets, refetch}) {
  const [id, setId] = useState(0);
  const [refund, setRefund] = useReducer((prev, next) => {
    const newState = {...prev, ...next};
    
    if (next.reason && next.reason !== '') {
      next.error = false;
    }

    return newState;
  }, { id: 0, price: 0, reason: '', error: false});
  const [opened, {open, close}] = useDisclosure(false);
  const [delOpened, {open: delOpen, close: delClose}] = useDisclosure(false);
  const [sendTickets] = useMutation(SEND_TICKETS_MUTATION);
  const [ticketRefund] = useMutation(REFUND_MUTATION);
  const [deleteTicket] = useMutation(DELETE_TICKET_MUTATION);

  const doSend = async(showId) => {
    const results = await sendTickets({variables: { userId, showId, email }})

    if (results.error) {
      errorMsg(results, 'ticket', 'send');
    } else {
      success('Done!', `Tickets re-sent to ${email}.`)
    }
  };

  const doRefund = async() => {
    if (refund.reason === '') {
      setRefund({error: true});
      return false;
    }

    const results = await ticketRefund({variables: { id: refund.id, reason: refund.reason }});

    if (results.error) {
      errorMsg(results, 'ticket', 'refund');
      close();
    } else {
      success('Done!', 'The ticket purchase has been refunded.');
      close();
      refetch();
    }

  };

  const doDelete = async () => {
    const results = await deleteTicket({variables: { id }});

    if (results.error) {
      errorMsg(results, 'ticket', 'delete');
    } else {
      success('Done!', 'Ticket deleted.');
      refetch();
    }

    delClose();
  };

  const defaultDelivery = { status: 'undelivered', on: 0};

  const rows = tickets.map(t => {
    return (
      <tr key={`ticket${t.id}`}>
        <td>{t.event.title}</td>
        <td>{t.show.subtitle || convertDate(t.show.startTime)}</td>
        <td>{ticketDeliveryStatus(t.delivery[0] || defaultDelivery)}</td>
        <td>{convertDate(t.purchaseDate)}</td>
        <td>
          <Button.Group>
            <Button color="green" onClick={() => doSend(t.show.id)}>Resend</Button>
            {(!t.refunded && t.tier.price > 0) && <Button color="teal" onClick={() => {setRefund({id: t.id, price: t.tier.formattedPrice}); open();}}>Refund</Button>}
            <Button color="red" onClick={() => { setId(t.id); delOpen();}}>Delete</Button>
          </Button.Group>
        </td>
      </tr>
    )
  });

  return (rows.length === 0) ? 
    <Text fz="1.25rem" fw={700}>There are no tickets for this user.</Text>
    :
    <>
      <Table>
        <thead>
          <tr>
            <th>Event</th>
            <th>Show</th>
            <th>Delivery status</th>
            <th>Purchased On</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody> 
      </Table>
      <Modal opened={opened} onClose={close} title="Confirm Refund">
        <Text>Are you sure you want to refund {refund.price}?</Text>
        <Select
          mb={10}
          label="Select reason for refund"
          placeholder="Pick reason"
          error={refund.error ? "Required" : false}
          withinPortal
          data={[
            {value: 'duplicate', label: 'Duplicate'},
            {value: 'fraudulent', label: 'Fraudulent'},
            {value: 'requested_by_customer', label: 'Customer Request'}
          ]}
          onChange={(e) => setRefund({reason: e})}
          /> 
        <Button.Group>
          <Button color="red" onClick={close}>Cancel</Button>
          <Button color="blue" onClick={doRefund}>Confirm Refund</Button>
        </Button.Group>
      </Modal>
      <Modal opened={delOpened} onClose={delClose} title="Confirm Delete">
        <Text>Are you sure you want to delete this ticket?</Text>
        <Text mb={10} fz="sm" color="dimmed">(This is irreversible)</Text>
        <Button.Group>
          <Button color="red" onClick={delClose}>Cancel</Button>
          <Button color="blue" onClick={doDelete}>Confirm Delete</Button>
        </Button.Group>
      </Modal>
    </>
}