import { useState } from "react";
import {
    Card,
    TextInput,
    Title,
    Button,
    SimpleGrid,
    FileInput,
    Anchor,
    Group,
    Modal,
    Text
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useAuth } from '@/components/AuthProvider';
import { useMutation } from 'graphql-hooks';
import { DELETE_EVENT_MUTATION } from '@/network/mutations';
import { pickUri } from '@/lib/uris';
import CategorySelect from "../createEvent/CategorySelect";
import FollowOnEmail from "./components/FollowOnEmail";
import { success, fail } from '@/lib/notifications';
import ShowNotificationEmail from "./components/ShowNotification";

export default function GeneralSettings({event, handler}) {
  const [title, setTitle] = useState(event.title);
  const [program, setProgram] = useState(null);
  const [opened, { open, close }] = useDisclosure(false);
  const [deleteEvent] = useMutation(DELETE_EVENT_MUTATION);
  const url = pickUri().replace('/gql', '');
  const { token } = useAuth();

  const handleCategory = (payload) => {
    handler('category_id', JSON.stringify(payload.category));    
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${token}`);
    const form = e.currentTarget;
    const url = new URL(form.action);
    const formData = new FormData(form);
    formData.append('file', program);

    const fetchOptions = {
      method: form.method,
      body: formData,
      headers
    };

    const results = await fetch(url, fetchOptions);

    if (results.ok) {
      event.program = program.name;
      setProgram(null);
      success('Uploaded', 'Event program uploaded');
    } else {
      fail('Hmmm...', 'Failed to upload program.');
    }
  };

  const handleDelete = async() => {
    const result = await deleteEvent({variables: {id: event.id}});

    if (result.data.deleteEvent) {
      success("Done!", "Your event has been deleted. Redirecting....");
      setTimeout(() => {
        window.location = '/app/dashboard'; 
      }, 2500);
    } else {
      fail("Uh oh!", "Failed to delete event.");
    }
  };

  return (
    <>
      <SimpleGrid cols={2}>
        <Card mb={10} padding="lg" shadow="sm" withBorder>
            <Title order={2} mb={10}>Event Name</Title>
            <TextInput mt={40} value={title} placeholder="Enter the name of your event" onChange={(e) => { handler('title', e.target.value); setTitle(e.target.value); }} />
        </Card>
        <Card mb={10} padding="lg" shadow="sm" withBorder>
          <Title order={2} mb={10}>Event Program</Title>
          <Text>Programs are sent out by e-mail to all ticket purchasers 24 hours before an event.</Text>
          <form action={`${url}/upload/program/${event.id}`} method="POST" onSubmit={handleUpload}>
          {event.program && <Anchor href={event.program} target="_blank">Current Program</Anchor>}
          {program && (
                <>
                  <Text>New file: {program.name}</Text>
                  <Button type="submit" color="blue">Upload Program</Button>
                </>
              )}
              {!program && <FileInput placeholder="Select file" label="Upload program" name="program" onChange={(e) => setProgram(e)} accept="application/pdf,image/png,image/jpeg" />} 
            </form>
        </Card>
        <FollowOnEmail evt={event} handler={handler} />
        <ShowNotificationEmail evt={event} handler={handler} />
        <Card mb={20} padding="lg" shadow="sm" withBorder>
          <Title order={2} mb={10}>Event Category</Title>
          <CategorySelect ids={event.category.map(c => c.id)} handler={handleCategory} />
        </Card>
        <Card mb={20} padding="lg" shadow="sm" withBorder>
          <Title mb={10} order={2}>Delete Event</Title>
          <Text mb={10}>This will delete the event and all associated shows and tiers.</Text>
          <Button color="red" onClick={open}>Delete Event</Button>
        </Card>
      </SimpleGrid>
      <Modal opened={opened} onClose={close} title="Are you Sure?">
        <Text size="sm">
          This action is irreversible. Are you sure you want to do this?
        </Text>
        <Group position="right">
          <Button color="blue" onClick={close}>Cancel</Button>
          <Button color="red" onClick={handleDelete}>Confirm</Button>
        </Group>
      </Modal>
    </>
  )
}