import {
  Box,
  Title,
  Divider,
  Text,
  Group
} from '@mantine/core';
import { formatCurrency } from '@/lib/helpers';

export default function OrderSummary({ ticketData, displayOpts, evt }) {

  return (
    <Box style={{backgroundColor: '#f3f4f6'}} p="md">
      <Title order={4}>Order Summary</Title>
      {Object.values(ticketData.tickets).map((entry, idx) => {
        const total = entry.tickets.reduce((sum, ticket) => {
          const cost = (ticketData.promoPrice > 0 ? ticketData.promoPrice : ticket.price);
          return sum + cost;
        }, 0);
        return entry.tickets.length ? (
        <Group key={`subtotal${idx}`} position="apart" mb={5}>
          <Text>{entry.tickets.length} &times; {entry.tier}</Text>
          <Text>{total === 0 ? "Free" : formatCurrency(total)}</Text>
        </Group>
        ) : null;
      })}
      <Divider size="xs" color="black" />
      {!evt.fundraising && <Group position="apart">
        <Text fw={700}>Subtotal:</Text> 
        <Text>{formatCurrency(ticketData.subtotal)}</Text>
      </Group>}
      {displayOpts.promo && <Text color="green" fw={700}>Promo Applied!</Text>}
      {ticketData.fees > 0 && <Group position="apart">
        <Text fw={700}>Fees: </Text>
        <Text>{formatCurrency(ticketData.fees)}</Text>   
      </Group>}
      {ticketData.donation > 0 && <Group position="apart">
          <Text fw={700}>Donation: </Text>
          <Text>{formatCurrency(ticketData.donation)}</Text>
        </Group>}
      <Divider size="sm" color="black" />
      <Group position="apart" mt="md">
        <Text fz="lg" fw={700}>Total: </Text>
        <Text fz="lg" fw={700}>{formatCurrency(ticketData.total || ticketData.donation)}</Text>
      </Group>
    </Box>
  )
}