import EventWizard from '@/modules/createEvent/EventWizard';
import Paused from './Paused';
import {
  Container
} from '@mantine/core';

const createPaused = true;

export default function CreateEvent() {
  return (
    <Container>
      {createPaused ? <Paused /> : <EventWizard />}
    </Container>
  )

}