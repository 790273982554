import { Tabs } from "@mantine/core";
import { IconTicket, IconTicketOff } from "@tabler/icons-react";
import HomeTickets from "../dashboard/HomeTickets";
import PastTickets from "../dashboard/PastTickets";

export default function AuthedTicketPage() {
  return (
    <Tabs color="orange" defaultValue="upcoming">
      <Tabs.List grow>
        <Tabs.Tab value="upcoming" icon={<IconTicket size="0.8rem" />}>
          Upcoming Tickets
        </Tabs.Tab>
        <Tabs.Tab value="past" icon={<IconTicketOff size="0.8rem" />}>
          Past Tickets
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="upcoming" pt="xs">
        <HomeTickets showTitle={false} />
      </Tabs.Panel>

      <Tabs.Panel value="past" pt="xs">
        <PastTickets showTitle={false} />
      </Tabs.Panel>
    </Tabs>
  );
}
